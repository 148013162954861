import { defineStore } from 'pinia';
import { getCancelBet } from '@/api';
import { cancelBet } from '@/api';

export const useCancelStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: 'cancelStore',
  state: () => {
    return {
      response: null,
    };
  },
  actions: {
    getCancelData(param) {
      let result = getCancelBet(param);
      this.response = result;
    },
    submitCancelBet(param) {
        let result = cancelBet(param);
        this.response = result;
      },
  },
});
