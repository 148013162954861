<template>
  <div class="row aligns-items-center justify-content-center">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12 center-holder-box pe-4">
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("sidebar.RemittanceHistory") }}</div>
          </div>
          <div class="tab-content">
            <div class="row">
              <div class="col-4 hide-elem">
                <div class="row">
                  <div class="col-12">
                    <label class="form-label">{{ $t("label.CollectorsName") }}:</label>
                    <Select2
                      v-model="AccountId"
                      :options="CollectorOptions"
                      :settings="{ minimumResultsForSearch: -1 }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-4 hide-elem">
                <div class="row">
                  <div class="col-12">
                    <label class="form-label">{{$t("date")}}:</label>
                    <input type="date" class="form-control agent-text" />
                  </div>
                </div>
              </div>
              <div class="col-4 hide-elem">
                <div class="row">
                  <div class="col-12">
                    <label class="form-label">{{$t("status")}}:</label>
                    <Select2
                      v-model="myValue"
                      :options="statusOptions"
                      :settings="{ minimumResultsForSearch: -1 }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 mt-4 mb-4 hide-elem">
                <div class="row">
                  <div class="col-6"></div>
                  <div class="col-6 px-4">
                    <div class="row">
                      <div class="col-4 px-1">
                        <button type="button" class="rs-btn rs-gen active">
                          {{ $t("button.generatereport") }}
                        </button>
                      </div>
                      <div class="col-4 px-1">
                        <button
                          type="button"
                          class="rs-btn rs-dl active"
                          @click="generatePDF"
                        >
                          {{ $t("button.download") }}
                        </button>
                      </div>
                      <div class="col-4 px-1">
                        <button
                          type="button"
                          class="rs-btn rs-print active"
                          @click="printPreview"
                        >
                          {{ $t("button.reprintreport") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <vue3-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="1400"
                filename="Summary Reports"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="landscape"
                pdf-content-width="100%"
                @progress="onProgress($event)"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
                ref="html2Pdf"
              >
                <template v-slot:pdf-content>
                  <ContentToDownloadRemittance :dataList="dataList" />
                </template>
              </vue3-html2pdf>

              <div class="col-12 col-sm-12 mb-3" id="printTable">
                <table
                  class="table table-striped table-hover dtable-border"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>{{ $t("siteNames.COLLECTOR") }}</th>
                      <th>{{ $t("label.DateTime") }}</th>
                      <th>{{ $t("label.TransactionNo") }}</th>
                      <th>{{ $t("label.PreviousBalance") }}</th>
                      <th>{{ $t("label.TotalBetAmount") }}</th>
                      <th class="text-right">{{ $t("payouts") }}</th>
                      <th class="text-right">{{ $t("label.Commission") }}</th>
                      <th class="text-center">{{ $t("label.AmountToEndorse") }}</th>
                      <th>{{ $t("label.EndorsedAmount") }}</th>
                      <th>{{ $t("label.Status") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="index" v-for="(item, index) in dataList">
                      <td>{{ item.collectorsName }}</td>
                      <td>{{ item.datetime }}</td>
                      <td>{{ item.transactionNo }}</td>
                      <td>{{ item.prevBalance }}</td>
                      <td>{{ item.totalBets }}</td>
                      <td class="text-right">{{ item.payouts }}</td>
                      <td class="text-right">{{ item.commissions }}</td>
                      <td class="text-center">{{ item.forCollection }}</td>
                      <td>{{ item.collectedAmount }}</td>
                      <td>{{ item.status }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Pagination />
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import Pagination from "../components/Pagination";
import Vue3Html2pdf from "vue3-html2pdf";
import ContentToDownloadRemittance from "../components/ContentToDownloadRemittance";
import { getCollectorList, getRemittanceHistory } from "../api"
export default {
  name: "RemittanceHistory",
  components: {
    Pagination,
    Select2,
    Vue3Html2pdf,
    ContentToDownloadRemittance,
  },
  data() {
    return {
      myValue: "",
      AccountId: "",
      CollectorOptions: [], // or [{id: key, text: value}, {id: key, text: value}]
      cashierOptions: ["Cashier 1", "Cashier 2", "Cashier 3"], // or [{id: key, text: value}, {id: key, text: value}]
      statusOptions: [
        { id: 0, text: "All" },
        { id: 1, text: "Fully Paid" },
        { id: 2, text: "Partial Paid" },
        { id: 3, text: "Paid" },
        { id: 4, text: "Unpaid" },
      ], // or [{id: key, text: value}, {id: key, text: value}]
      filterByOption: [
        { id: 1, text: "Date" },
        { id: 2, text: "Event" },
      ],
      filterVal: 1,
      dataList: [
        {
          collectorsName: "Collector 1",
          datetime: "11/02/2022 3:00:00 PM",
          transactionNo: "12345678901",
          prevBalance: "--",
          totalBets: "16,375.32",
          payouts: "10,500.00",
          commissions: "491.26",
          forCollection: "5,384.06",
          collectedAmount: "5,000.00",
          status: "Partial Paid",
        },
        {
          collectorsName: "Collector 1",
          datetime: "11/03/2022 3:00:00 PM",
          transactionNo: "12345678902",
          prevBalance: "384.06",
          totalBets: "45,442.06",
          payouts: "43,000.00",
          commissions: "1,363.26",
          forCollection: "1,078.8",
          collectedAmount: "--",
          status: "Unpaid",
        },
        {
          collectorsName: "Collector 1",
          datetime: "11/04/2022 3:00:00 PM",
          transactionNo: "12345678903",
          prevBalance: "1,078.8",
          totalBets: "12,474.59",
          payouts: "10,000.00",
          commissions: "374.24",
          forCollection: "2,100.35",
          collectedAmount: "2,100.35",
          status: "Fully Paid",
        },
        {
          collectorsName: "Collector 1",
          datetime: "11/05/2022 3:00:00 PM",
          transactionNo: "12345678904",
          prevBalance: "--",
          totalBets: "26,635.78",
          payouts: "15,000.00",
          commissions: "799.07",
          forCollection: "10,836.71",
          collectedAmount: "10,836.71",
          status: "Fully Paid",
        },
        {
          collectorsName: "Collector 1",
          datetime: "11/06/2022 3:00:00 PM",
          transactionNo: "12345678905",
          prevBalance: "--",
          totalBets: "17.40",
          payouts: "20.00",
          commissions: "0.52",
          forCollection: "3.12",
          collectedAmount: "--",
          status: "Unpaid",
        },
        {
          collectorsName: "Collector 1",
          datetime: "11/07/2022 3:00:00 PM",
          transactionNo: "12345678906",
          prevBalance: "3.12",
          totalBets: "8,429.81",
          payouts: "5,000.00",
          commissions: "252.89",
          forCollection: "3,176.92",
          collectedAmount: "3,000.00",
          status: "Partial Paid",
        },
        {
          collectorsName: "Collector 1",
          datetime: "11/08/2022 3:00:00 PM",
          transactionNo: "12345678907",
          prevBalance: "176.92",
          totalBets: "12,843.23",
          payouts: "10,000.00",
          commissions: "385.3",
          forCollection: "2,457.93",
          collectedAmount: "2000",
          status: "Partial Paid",
        },
        {
          collectorsName: "Collector 1",
          datetime: "11/09/2022 3:00:00 PM",
          transactionNo: "12345678908",
          prevBalance: "457.93",
          totalBets: "9,558.90",
          payouts: "8,500.00",
          commissions: "286.77",
          forCollection: "772.13",
          collectedAmount: "500.00",
          status: "Partial Paid",
        },
        {
          collectorsName: "Collector 1",
          datetime: "11/10/2022 3:00:00 PM",
          transactionNo: "12345678909",
          prevBalance: "272.13",
          totalBets: "7,971.22",
          payouts: "5,000.00",
          commissions: "239.14",
          forCollection: "2,732.08",
          collectedAmount: "2,000.00",
          status: "Partial Paid",
        },
        {
          collectorsName: "Collector 1",
          datetime: "11/11/2022 3:00:00 PM",
          transactionNo: "12345678910",
          prevBalance: "732.08",
          totalBets: "1,787.8",
          payouts: "1,000.00",
          commissions: "53.63",
          forCollection: "734.17",
          collectedAmount: "734.17",
          status: "Fully Paid",
        },
      ],
    };
  },
  methods: {
    hasGenerated() {
      this.successMessage = "PDF generated successfully!";
      this.$refs.modalSuccess.open();
      setTimeout(() => this.$refs.modalSuccess.close(), 5000);
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    printPreview() {
      document.getElementById("printTable").innerHTML;
      window.focus();
      window.print();
    },
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },
    async fetchCollectorListOptions() {
      let res = await getCollectorList();
      console.log("reg="+res);
      if (res.status === "success") {
        let collector = res.data.map( (d) => {
          return {id: d.AccountId, text: d.Name}
        });
        this.CollectorOptions = collector;
      }
    },
    async fetchRemittanceHistory() {
      let res = await getRemittanceHistory({
          intCollectorAccountId: this.intCollectorAccountId,
          dateTime: this.dateTime,
          intStatus: this.intStatus,
          intLimitPerPage: this.intLimitPerPage,
          intStart: this.intStart,
        });
      if (res.status === "success") {
        this.dataList = res.data.map( (d) => {
          var date = new Date(d.DateandTime.slice(0, -1));
          return {
            collectorname: d.CollectorsName,
            date: date.toLocaleDateString() + " " + date.toLocaleTimeString(),
            transno: d.TransactionNo,
            name: d.CashierName, 
            collector: d.CollectorName,
            prevBalance: d.PreviousBalance,
            totalBet: d.TotalBets,
            payouts: d.Payouts,
            commission: d.Commisions,
            collection: d.ForCollection,
            collectedAmount: d.CollectedAmount,
            status: d.Status,
          }
        })
      }
    },
  },
  mounted() {
    this.auth = JSON.parse(localStorage.getItem("auth"));
    this.fetchCollectorListOptions();
    //this.fetchRemittanceHistory();
  }
};
</script>

<style>
@import "bootstrap";
@import "datatables.net-bs5";
@media print {
  .hide-elem,
  .sidebar-holder-outer {
    display: none;
  }
  .tabs-holder-outer {
    display: block;
    width: 1160px;
    margin-left: 30px;
  }
  #printTable {
    display: block;
    width: 100%;
    margin: 0 auto;
    float: none;
  }

}
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after {
  display: none !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  display: flex;
  margin: 2px 0 !important;
  margin-top: 15px !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination li {
  padding: 0;
  text-decoration: none;
  background: none;
  border-radius: 0;
  font-weight: normal;
}
div.dataTables_wrapper div.row div.col-md-6:first-child {
  display: none;
}
.dataTables_filter label {
  display: block;
}

.dtable-input {
  width: 89px;
  height: 35px;
  border: 1px solid #c9c9c9;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
}

.dtable-input2 {
  width: 89px;
  height: 35px;
  border: 1px solid #707070;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
  display: inline-block;
}
.dtable-btn {
  width: 68px;
  height: 35px;
  background: #23ad0e;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: -1px;
}
.dtable-btn2 {
  width: 116px;
  height: 35px;
  background: #35469a;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
}
.top-input {
  width: 122px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
  text-align: right;
  display: inline-block;
}
.top-apply-btn {
  width: 107px;
  height: 40px;
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2559;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
.ds-block {
  display: block;
}
.update-share-holder {
  display: inline-block;
  text-align: left;
}
.top-search {
  width: 241px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
}
table.dataTable td {
  vertical-align: middle;
}
table.dataTable th {
  vertical-align: top;
}
.dtable-text:focus {
  box-shadow: none;
  border: 1px solid #707070;
}
.tab-content {
  padding-bottom: 2% !important;
  padding-top: 2% !important;
}
.page-link.active,
.active > .page-link {
  background-color: var(--violet);
  border-color: var(--violet);
}
.rs-btn {
  width: 100%;
  font-size: 16px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  color: #656565;
  background: #efefef;
  box-shadow: none;
  border: none;
}
.rs-gen.active {
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2570;
  color: #fff;
}
.rs-dl.active {
  background: #0081ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0081ff59;
  color: #fff;
}
.rs-print.active {
  background: #2cb742 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2cb74259;
  color: #fff;
}
.dtable-border {
  border-top: 1px solid #efefef;
}
.table th,
.table td {
  vertical-align: middle;
}
</style>