<template v-html="html">
  <div class="receipt-holder" id="targetDiv">
    <img
      @load="onImgLoad"
      src="../assets/img/receipt-logo.svg"
      class="receipt-logo"
    />
    <div class="receipt-title">
      {{
        module === "PlaceBet"
          ? $t("rec-bet")
          : module === "PayOut"
          ? $t("rec-payout")
          : module === "TransactionReceipt"
          ? $t("rec-transaction")
          : module === "SummaryReports"
          ? $t("rec-summary")
          : module === "CashEndorsement"
          ? $t("rec-cash-endorsement")
          : $t("rec-invalid")
      }}
      {{ $t("rec-reciept") }}
    </div>
    <div class="receipt-content-holder" v-if="module === 'PlaceBet'">
      <table>
        <tr v-bind:key="index" v-for="(data, index) in dataReceipt">
          <td>
            <p class="text-left reciept-text">{{ data.title }}:</p>
          </td>
          <td v-if="data.title === 'Date:'">
            <p class="text-right reciept-text">
              {{ new Date(data.value.slice(0, -1)).toLocaleDateString() }}
              {{ new Date(data.value.slice(0, -1)).toLocaleTimeString() }}
            </p>
          </td>
          <td v-else>
            <p class="text-right reciept-text">{{ data.value }}</p>
          </td>
        </tr>
      </table>
    </div>

    <div class="receipt-content-holder" v-if="module === 'PayOut'">
      <table>
        <tr v-bind:key="index" v-for="(data, index) in payOutReceipt">
          <td>
            <p class="text-left reciept-text">{{ data.title }}:</p>
          </td>
          <td v-if="data.title === 'Date:'">
            <p class="text-right reciept-text">
              {{ new Date(data.value.slice(0, -1)).toLocaleDateString() }}
              {{ new Date(data.value.slice(0, -1)).toLocaleTimeString() }}
            </p>
          </td>
          <td v-else>
            <p class="text-right reciept-text">{{ data.value }}</p>
          </td>
        </tr>
      </table>
    </div>

    <div class="receipt-content-holder" v-if="module === 'SummaryReports'">
      <table>
        <tr v-bind:key="index" v-for="(data, index) in summaryReceipt">
          <td>
            <p class="text-left reciept-text">{{ data.title }}:</p>
          </td>
          <td v-if="data.title === 'Date:'">
            <p class="text-right reciept-text">
              {{ new Date(data.value.slice(0, -1)).toLocaleDateString() }}
              {{ new Date(data.value.slice(0, -1)).toLocaleTimeString() }}
            </p>
          </td>
          <td v-else>
            <p class="text-right reciept-text">{{ data.value }}</p>
          </td>
        </tr>
      </table>
    </div>

    <div class="receipt-content-holder" v-if="module === 'CashEndorsement'">
      <div class="receipt-label-copy">{{ cashEndorsementCopy }} Copy</div>
      <div v-bind:key="index" v-for="(data, index) in cashEndorsement">
        <table>
          <tr>
            <td>
              <p class="text-left reciept-text">{{ $t("date") }}:</p>
            </td>
            <td>
              <p class="text-right reciept-text">
                {{
                  new Date(data.DateTime.slice(0, -1)).toLocaleDateString(
                    "en-US",
                    { year: "numeric", month: "long", day: "numeric" }
                  )
                }}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-left reciept-text">{{ $t("time") }}:</p>
            </td>
            <td>
              <p class="text-right reciept-text">
                {{ new Date(data.DateTime.slice(0, -1)).toLocaleTimeString() }}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-left reciept-text">{{ $t("transactionno") }}:</p>
            </td>
            <td>
              <p class="text-right reciept-text">{{ data.TransactionNo }}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-left reciept-text">{{ $t("shopname") }}:</p>
            </td>
            <td>
              <p class="text-right reciept-text">{{ data.ShopName }}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="text-left reciept-text">Shop Address:</p>
            </td>
            <td>
              <p class="text-right reciept-text">{{ data.ShopAdd }}</p>
            </td>
          </tr>
        </table>
        <div class="text-left reciept-text receipt-endorsed-label">Amount</div>
        <div class="receipt-endorsed-amount mb-2">
          {{ data.AmountEndorsed }}
        </div>
        <div class="receipt-endorsed-sign">
          Endorsed By: {{ data.CashierName }}
        </div>
        <div class="receipt-endorsed-sign">
          Received By: {{ data.CollectorName }}
        </div>
      </div>
    </div>

    <div class="receipt-content-holder" v-if="module === 'TransactionReceipt'">
      <table v-bind:key="index" v-for="(data, index) in transactionReceipt">
        <tr>
          <td>
            <p class="text-left reciept-text">{{ $t("transactionno") }}:</p>
          </td>
          <td>
            <p class="text-right reciept-text">{{ data.TransactionNo }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="text-left reciept-text">{{ $t("date-and-time") }}:</p>
          </td>
          <td>
            <p class="text-right reciept-text">
              {{
                new Date(
                  data.TransactionDateTime.slice(0, -1)
                ).toLocaleDateString()
              }}
              {{
                new Date(
                  data.TransactionDateTime.slice(0, -1)
                ).toLocaleTimeString()
              }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="text-left reciept-text">{{ $t("fightno") }}:</p>
          </td>
          <td>
            <p class="text-right reciept-text">{{ data.FightNumber }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="text-left reciept-text">{{ $t("betoption") }}:</p>
          </td>
          <td>
            <p class="text-right reciept-text">{{ data.BetOption }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="text-left reciept-text">{{ $t("betamount") }}:</p>
          </td>
          <td>
            <p class="text-right reciept-text">
              {{
                Number(data.betAmount)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
              }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="text-left reciept-text">{{ $t("sidebar.Payout") }}:</p>
          </td>
          <td>
            <p class="text-right reciept-text">
              {{
                Number(data.Payout)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
              }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="text-left reciept-text">{{ $t("odds") }}:</p>
          </td>
          <td>
            <p class="text-right reciept-text">
              {{
                Number(data.Odds)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
              }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="text-left reciept-text">{{ $t("cashier") }}:</p>
          </td>
          <td>
            <p class="text-right reciept-text">{{ data.Cashier }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="text-left reciept-text">{{ $t("place-bet-status") }}:</p>
          </td>
          <td>
            <p class="text-right reciept-text">{{ data.PlaceBetStatus }}</p>
          </td>
        </tr>
      </table>
    </div>

    <div v-if="module !== 'CashEndorsement'" class="receipt-footer">
      {{ $t("rec-thankyou") }}
    </div>

    <vue3-barcode
      :value="code"
      :height="53"
      class="barcode-holder"
      v-if="code"
    />
  </div>
</template>

<script>
import Vue3Barcode from "vue3-barcode";
import { getPrintingMarginTop } from "@/helpers/printing";
import router from "../router";
export default {
  name: "ReCeipt",
  components: {
    Vue3Barcode,
  },
  data() {
    return {
      printingMarginTop: getPrintingMarginTop(),
      module: JSON.parse(localStorage.getItem("module")),
      code: JSON.parse(localStorage.getItem("barcode")),
      dataReceipt: [
        {
          id: 1,
          title: this.$t("transactionno"),
          value: JSON.parse(localStorage.getItem("transactionno")),
        },
        {
          id: 2,
          title: this.$t("date"),
          value: JSON.parse(localStorage.getItem("datetime")),
        },
        {
          id: 3,
          title: this.$t("fightno"),
          value: JSON.parse(localStorage.getItem("fightno")),
        },
        {
          id: 4,
          title: this.$t("betoption"),
          value: JSON.parse(localStorage.getItem("betoption")),
        },
        {
          id: 5,
          title: this.$t("betamount"),
          value: JSON.parse(localStorage.getItem("betamount")),
        },
        {
          id: 6,
          title: this.$t("cashierno"),
          value: JSON.parse(localStorage.getItem("cashierno")),
        },
      ],
      payOutReceipt: [
        {
          id: 1,
          title: this.$t("transactionno"),
          value: JSON.parse(localStorage.getItem("transactionno")),
        },
        {
          id: 2,
          title: this.$t("date"),
          value: JSON.parse(localStorage.getItem("datetime")),
        },
        {
          id: 3,
          title: this.$t("fightno"),
          value: JSON.parse(localStorage.getItem("fightno")),
        },
        {
          id: 4,
          title: this.$t("betoption"),
          value: JSON.parse(localStorage.getItem("betoption")),
        },
        {
          id: 5,
          title: this.$t("betamount"),
          value: JSON.parse(localStorage.getItem("betamount")),
        },
        {
          id: 6,
          title: this.$t("rec-payout"),
          value: JSON.parse(localStorage.getItem("payout")),
        },
        {
          id: 7,
          title: this.$t("odds"),
          value: JSON.parse(localStorage.getItem("odds")),
        },
        {
          id: 8,
          title: this.$t("cashierno"),
          value: JSON.parse(localStorage.getItem("cashierno")),
        },
        {
          id: 8,
          title: this.$t("status"),
          value: JSON.parse(localStorage.getItem("statuss")),
        },
      ],
      summaryReceipt: [
        {
          id: 1,
          title: this.$t("stats.availableCredits"),
          value: JSON.parse(localStorage.getItem("availablecredits")),
        },
        {
          id: 2,
          title: this.$t("cashin"),
          value: JSON.parse(localStorage.getItem("cashin")),
        },
        {
          id: 3,
          title: this.$t("cash-on-hand"),
          value: JSON.parse(localStorage.getItem("cashonhand")),
        },
        {
          id: 4,
          title: this.$t("payouts"),
          value: JSON.parse(localStorage.getItem("payouts")),
        },
        {
          id: 5,
          title: this.$t("approved-credit-requests"),
          value: JSON.parse(localStorage.getItem("approvedcredit")),
        },
        {
          id: 6,
          title: this.$t("cashiername"),
          value: JSON.parse(localStorage.getItem("cashiername")),
        },
        {
          id: 7,
          title: this.$t("Timestamp"),
          value: JSON.parse(localStorage.getItem("timestamp")),
        },
      ],
      transactionReceipt: JSON.parse(
        localStorage.getItem("TransactionReceipt")
      ),
      cashEndorsement: JSON.parse(
        localStorage.getItem("CashEndorsementReceipt")
      ),
      cashEndorsementCopy: JSON.parse(
        localStorage.getItem("CashEndorsementCopy")
      ),
    };
  },

  mounted() {
    //
    console.log(
      this.module === "CashEndorsement" &&
        this.cashEndorsementCopy == "Collector's",
      "yords"
    );
    console.log(this.module, this.cashEndorsementCopy, "yords");
    if (this.module === "PlaceBet") {
      console.log("awews");
      window.onafterprint = () => {
        router.push({ name: "PlaceBet" });
      };
    } else if (
      this.module === "CashEndorsement" &&
      this.cashEndorsementCopy == "Collector's"
    ) {
      window.onafterprint = () => {
        localStorage.setItem(
          "CashEndorsementCopy",
          JSON.stringify("Cashier's")
        );
        location.reload();
      };
    } else if (
      this.module === "CashEndorsement" &&
      this.cashEndorsementCopy == "Cashier's"
    ) {
      window.onafterprint = () => {
        router.push({ name: "CashEndorsement" });
      };
    }
  },
  methods: {
    onImgLoad() {
      window.print();
    },
  },
};
</script>
<style scoped>
@page {
  margin-left: 0;
  margin-right: 0;
  margin-top: v-bind(printingMarginTop);
  size: 80mm 150mm;
}
.receipt-holder {
  width: 301px;
  background: var(--white);
  padding: 20px 15px;
  margin-left: 0px;
  margin-top: 20px;
  border: none;
  padding-bottom: 10px;
  color: #000000 !important;
}
.receipt-title {
  color: #000000 !important;
  font-size: 20px;
  border-top: 2px dashed;
  border-bottom: 2px dashed;
  text-align: center;
  padding: 5px 0;
  margin: 10px 0;
}
.receipt-label-copy {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  padding-top: 2px;
  text-decoration: underline;
}
.receipt-endorsed-label {
  padding-top: 30px;
}
.receipt-endorsed-amount {
  color: #000000 !important;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid;
  text-align: center;
  padding: 5px 0;
  margin: 10px 0;
}
.receipt-endorsed-sign {
  color: #000000 !important;
  border-top: 1px solid;
  text-align: center;
  font-size: 14px;
  margin: 5rem 0;
}
.reciept-text {
  color: #000000;
  font-size: 14px;
  margin: 0;
}
.receipt-logo {
  color: #000000;
  display: block;
  margin: 0 auto;
}
.receipt-footer {
  border-top: 2px dashed;
  border-bottom: 2px dashed;
  text-align: center;
  padding: 5px 0;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #000000;
  text-transform: uppercase;
}
.barcode-holder {
  color: #000000;
  position: relative;
  top: -20px;
}
</style>