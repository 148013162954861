
<template>
  <section class="awesome-table">
    <table>
      <thead>
        <tr class="pb-2">
          <th
            v-for="(obj, ind) in cols"
            :key="ind"
            :class="{
              'width-adj': obj.type === 'date',
              'width-adj2': obj.type === 'odds',
              'text-right': obj.type === 'amount',
              'text-center': obj.type === 'button',
            }"
          >
            {{ $t(obj.title) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="theData.length === 0">
          <td colspan="13" class="text-center">{{ rowloading }}</td>
        </tr>
        <tr v-for="(row, index) in theData" :key="index" v-else>
          <td
            v-for="(obj, ind) in cols"
            :key="ind"
            :class="{
              'text-right': obj.type === 'amount',
              'width-adj2': obj.type === 'button',
              'text-center': obj.type === 'odds',
              odd: index % 2 === 0,
              even: index % 2 !== 0,
            }"
          >
            <span v-if="obj.type === 'text'">{{ row[obj.key] }}</span>
            <span v-if="obj.type === 'date'">
              {{ new Date(row[obj.key].slice(0, -1)).toLocaleDateString() }}
              {{ new Date(row[obj.key].slice(0, -1)).toLocaleTimeString() }}
            </span>
            <span v-if="obj.key === 'Odds'">{{
              row[obj.key] == null ? "0.00" : row[obj.key].toFixed(2)
            }}</span>
            <span
              v-if="obj.type === 'amount'"
              :class="{ pr: obj.type === 'amount' }"
              >{{
                row[obj.key].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </span>
            <span v-if="obj.type == 'text' && row[obj.key] == undefined"
              >--</span
            >
            <input
              class="btn action-button"
              v-if="obj.key === 'TransactionNo' && obj.type === 'button'"
              type="button"
              @click="
                showReceipt(
                  row[obj.key],
                  showViewButton.includes(row.PlaceBetStatus)
                )
              "
              v-bind:class="
                showViewButton.includes(row.PlaceBetStatus)
                  ? ''
                  : 'btn-disabled'
              "
              :value="$t('view')"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
export default {
  name: "TableItems",
  props: ["theData", "cols", "rowloading"],
  methods: {
    showReceipt(val, stat) {
      if (stat === true) {
        this.$emit("customPrint", val);
      }
    },
  },
  data() {
    return {
      showViewButton: ["Paid", "Completed"],
      disabled: true,
    };
  },
};
</script>

<style scoped>
.awesome-table {
  border-top: 1px solid var(--border);
  padding: 25px 0px;
}
table {
  border-collapse: collapse;
  width: 100.1%;
}
th {
  position: sticky;
  top: 0;
  font-size: 14px;
  padding-bottom: 15px;
  padding-right: 5px;
  text-align: left;
  border-bottom: 1px solid var(--border);
}
td {
  padding: 5px 5px;
  text-align: left;
  border-bottom: 1px solid var(--border);
}
.even {
  background-color: #f2f2f3;
}
.action-button {
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  background: var(--white) 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 4px;
}
.action-button:hover {
  color: var(--white) !important;
  background-color: var(--orange) !important;
  border: 1px solid var(--orange) !important;
}
.sp-center {
  text-align: center !important;
}
</style>