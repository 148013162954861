import { defineStore } from 'pinia';
import { checkLicenseRemainingDays } from '@/api';
import router from '../router';

export const useLicenseStatus = defineStore({
  id: 'licenseStore',
  state: () => {
    return {
      response: null,
    };
  },
  actions: {
    async getLicense() {
      let result = await checkLicenseRemainingDays();
      if (result.data.RemainingDays <= 0) {
        router.push({ name: 'license' });
      }
      if (localStorage.getItem('lang') == undefined) {
        localStorage.setItem('lang', result.data.Locale);
      }
      if (localStorage.getItem('icon') == undefined || localStorage.getItem('icon') != result.data.Logos.browserTabLogo) {
        localStorage.setItem('icon', result.data.Logos.browserTabLogo);
      }

      if (localStorage.getItem('logo') == undefined || localStorage.getItem('logo') != result.data.Logos.horizontalLogo.dark) {
        localStorage.setItem('logo', result.data.Logos.horizontalLogo.dark);
      }
    },
    async getLogo() {
      let result = await checkLicenseRemainingDays();
      return result.data.Logos.horizontalLogo.dark;
    },
    async getTabIcon() {
      let result = await checkLicenseRemainingDays();
      return result.data.Logos.browserTabLogo;
    },
  },
});
