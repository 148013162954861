<template>
  <div class="bet-btn-holder remove-border mob-mt" v-if="status === 'PayOut'">
    <button
      type="button"
      class="bet-btn bet-btn-orange"
      :disabled="disabled"
      @click="payOut"
    >
      <div class="loading-icon" v-if="showLoadingIcon === true"></div>
      <span v-else>{{ $t("button.payout") }}</span>
    </button>
  </div>
  <div class="bet-btn-holder remove-border mob-mt" v-if="status === 'CashEndorsement'">
    <button
      type="button"
      class="bet-btn bet-btn-orange"
      :disabled="disabled"
      @click="showModal(2)"
    >
      <div class="loading-icon" v-if="showLoadingIcon === true"></div>
      <span v-else>{{ $t("button.proceed") }}</span>
    </button>
  </div>
  <div class="bet-btn-holder mob-mt" v-if="status === 'CreditRequest'">
    <button type="button" class="bet-btn bet-btn-orange" @click="actionModal">
      <div class="loading-icon" v-if="showLoadingIcon === true"></div>
      <span v-else>{{ $t("button.sendrequest") }}</span>
    </button>
  </div>
  <div
    class="bet-btn-holder remove-border mob-mt"
    v-if="status === 'SummaryReports'"
  >
    <button
      type="button"
      class="bet-btn bet-btn-blue dl-report"
      @click="dlReport"
    >
      <div class="loading-icon" v-if="showLoadingIcon === true"></div>
      <span v-else>{{ $t("button.downloadreport") }}</span>
    </button>
    <button type="button" class="bet-btn bet-btn-orange" @click="actionModal">
      <div class="loading-icon" v-if="showLoadingIcon === true"></div>
      <span v-else>{{ $t("button.reprintreport") }}</span>
    </button>
  </div>
  <div class="bet-btn-holder" v-if="status === 'PlaceBet'">
    <button
      type="button"
      class="bet-btn bet-btn-red"
      @click="showModal(1)"
      :disabled="disabled"
    >
      <div
        class="loading-icon"
        v-if="showLoadingIcon === true && bettingOption === 1"
      ></div>
      <span v-else>{{ $t("button.betred") }}</span>
    </button>
    <button
      type="button"
      class="bet-btn bet-btn-blue"
      @click="showModal(2)"
      :disabled="disabled"
    >
      <div
        class="loading-icon"
        v-if="showLoadingIcon === true && bettingOption === 2"
      ></div>
      <span v-else>{{ $t("button.betblue") }}</span>
    </button>
  </div>
  <div
    class="bet-btn-holder remove-border mob-mt"
    v-if="status === 'CancelBet'"
  >
    <button
      type="button"
      class="bet-btn bet-btn-orange"
      :disabled="disabled"
      @click="showModal(2)"
    >
      <div class="loading-icon" v-if="showLoadingIcon === true"></div>
      <span v-else>{{ $t("button.cancel") }}</span>
    </button>
  </div>
  <div
    class="bet-btn-holder remove-border mob-mt"
    v-if="status === 'ReprintReceipt'"
  >
    <button type="button" class="bet-btn bet-btn-orange" @click="actionModal">
      <div class="loading-icon" v-if="showLoadingIcon === true"></div>
      <span v-else>{{ $t("button.printreport") }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "BetFooter",
  props: {
    status: {
      type: String,
      required: false,
    },
    showLoadingIcon: {
      fast: Boolean,
      required: false,
    },
    bettingOption: {
      type: Number,
      required: false,
    },
    setThreshold: {
      type: Number,
      required: false,
    },
    btnStatus: {
      type: Number,
      required: false,
    },
    disabled: {
      fast: Boolean,
      required: false,
    },
  },
  emits: [
    "clickModal",
    "handleAction",
    "handleTriggerModal",
    "handleActionPayOut",
    "handleActionCancelBet",
  ],
  methods: {
    showModal($event) {
      this.$emit("clickModal", $event);
    },
    dlReport() {
      this.$emit("handleAction");
    },
    actionModal() {
      this.$emit("handleTriggerModal");
    },
    payOut() {
      this.$emit("handleActionPayOut");
    },
    proceed() {
      this.$emit("handleActionProceed");
    },
    cancelBet() {
      this.$emit("handleActionCancelBet");
    },
  },
};
</script>