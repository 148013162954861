<template>
  <div class="row aligns-items-center justify-content-center">
    <div class="col-12 col-md-12 col-lg-9 col-xl-9 center-holder-box">
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("sidebar.CashEndorsement") }}</div>
          </div>
          <div class="tab-content">
            <div class="row mt-3" :key="index" v-for="(item, index) in details">
              <div class="col-5 cashendorsement-staff">
                <div class="col-12 col-sm-4 mt-2">
                  <img class="item-avatar" :src="item.avatar" />
                </div>
                <div class="row align-items-center mt-4">
                  <span class="col-md-4 text-left">Type:</span>
                  <div class="col-md-8">
                    <select
                      class="cash-text card-text mr-0 w-auto"
                      v-model="selectedCollector"
                      @change="changeCollector()"
                    >
                      <option
                        :key="collector.id"
                        v-for="collector in collectors"
                        :value="collector.id"
                      >
                        {{ collector.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row align-items-center mt-4">
                  <span class="col-md-4 text-left"
                    >{{ $t("label.EmployeeNo") }}.:</span
                  >
                  <div class="col-md-8">
                    <span class="col-md-4 text-left">{{
                      item.EmployeeNo
                    }}</span>
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <span class="col-md-4 text-left"
                    >{{ $t("label.CollectorName") }}:</span
                  >
                  <div class="col-md-8">
                    <span class="col-md-4 text-left">{{
                      item.CollectorName
                    }}</span>
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <span class="col-md-4 text-left"
                    >{{ $t("label.Status") }}:</span
                  >
                  <div class="col-md-8">
                    <span class="col-md-4 text-left">{{ item.Status }}</span>
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <span class="col-md-4 text-left"
                    >{{ $t("label.MobileNo") }}:</span
                  >
                  <div class="col-md-8">
                    <span class="col-md-4 text-left">{{ item.MobileNo }}</span>
                  </div>
                </div>
              </div>
              <div
                class="col-7 cashendorsement-form"
                :key="index"
                v-for="(item, index) in dataList"
              >
                <div class="row align-items-center">
                  <label class="col-md-4 text-left"
                    >{{ $t("label.CollectorName") }}:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="cash-text card-text mr-0 w-100"
                      :value="item.CollectorName"
                      disabled
                    />
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <label class="col-md-4 text-left"
                    >{{ $t("label.CashierName") }}:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="cash-text card-text mr-0 w-100"
                      :value="item.CashierName"
                      disabled
                    />
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <label class="col-md-4 text-left"
                    >{{ $t("label.ShopName") }}:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="cash-text card-text mr-0 w-100"
                      :value="item.ShopName"
                      disabled
                    />
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <label class="col-md-4 text-left"
                    >{{ $t("label.DateTime") }}:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="cash-text card-text mr-0 w-100"
                      :value="item.DateTime"
                      disabled
                    />
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <label class="col-md-4 text-left"
                    >{{ $t("label.TransactionNo") }}:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="cash-text card-text mr-0 w-100"
                      :value="item.TransactionNo"
                      disabled
                    />
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <label class="col-md-4 text-left"
                    >{{ $t("label.PreviousBalance") }}:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="cash-text card-text mr-0 w-100 text-right"
                      :value="item.PrevBalance"
                      disabled
                    />
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <label class="col-md-4 text-left"
                    >{{ $t("label.CurrentRevenue") }}:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="cash-text card-text mr-0 w-100 text-right"
                      :value="item.CurrentRevenue"
                      disabled
                    />
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <label class="col-md-4 text-left"
                    >{{ $t("label.Commission") }}:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="cash-text card-text mr-0 w-100 text-right"
                      :value="item.Commission"
                      disabled
                    />
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <label class="col-md-4 text-left"
                    >{{ $t("label.AmountToEndorse") }}:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="cash-text card-text mr-0 w-100 text-right"
                      v-model="AmountToEndorse"
                      @keyup="inputTrans"
                      @keypress="onKeypress"
                    />
                  </div>
                </div>
                <div class="row align-items-center mt-2">
                  <label class="col-md-4 text-left"
                    >{{ $t("label.Note") }}:</label
                  >
                  <div class="col-md-8">
                    <!-- <input type="text" class="cash-text card-text mr-0 w-100" /> -->
                    <textarea
                      @input="updateTheVariable($event.target.value)"
                      rows="3"
                      class="cash-text card-text mr-0 w-100 h-auto"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <BetFooter
              :status="status"
              @clickModal="callModal"
              :disabled="disabled"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    :status="status"
    :status2="status2"
    v-if="isShowModal"
    @close="close"
    @clickSubmit="handleSubmitAction"
  >
  </Modal>
  <sweet-modal ref="modalError" icon="error">
    {{ errorMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
  <sweet-modal ref="modalSuccess" icon="success">
    {{ successMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
</template>

<script>
import BetFooter from "../components/tabs/BetFooter";
import Modal from "../components/modal/InputModal";
import router from "../router";
import {
  getAmountForEndorsementDetails,
  getAmountForEndorsementDetailsCollector,
  PostEndorsedAmount,
} from "../api";
export default {
  name: "CashEndorsement",
  components: { BetFooter, Modal },
  data() {
    return {
      dateTime: "",
      time: "",
      note: "",
      status: "CashEndorsement",
      status2: false,
      isShowModal: false,
      disabled: false,
      arr: [],
      selectedCollector: 1,
      collectors: [
        { id: 1, text: "Main Collector" },
        { id: 2, text: "Backup Collector" },
      ],
      AmountToEndorse: "5,384.06",
      dataList: [
        {
          CollectorName: "Francis M. Cruz",
          CashierName: "Maria Fundana",
          ShopName: "Aquestatotal",
          DateTime: "12/05/2022 09:55:00",
          TransactionNo: "123456780",
          PrevBalance: "123,000.00",
          CurrentRevenue: "148,000.00",
          Commission: "2,500.00",
          ShopAdd: "Calle, 343 Peru",
          AmountEndorsed: "",
          TotalBets: "",
          TotalPayout: "",
        },
      ],
      details: [
        {
          avatar:
            "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1669898314~exp=1669898914~hmac=64fa02c1355bd51f4ee3b3cf225deaaf28788a7dd4e88444be26879e067cf375",
          EmployeeNo: "AT-12645456465",
          CollectorName: "Francis M. Cruz",
          Status: "Active",
          MobileNo: "+51 2915 182 3124",
        },
      ],
      detailsA: [],
      detailsB: [],
      postAccountId: "",
      intStatus: 1,
    };
  },
  methods: {
    updateTheVariable(value) {
      this.note = value;
    },
    close() {
      this.isShowModal = false;
      this.status2 = false;
    },
    closeModal() {
      this.$refs.modalSuccess.close();
      this.$refs.modalError.close();
    },
    callModal() {
      this.isShowModal = true;
      this.status2 = false;
    },
    showModal() {
      this.isShowModal = true;
      this.status2 = true;
      this.threshold = null;
    },
    inputTrans($event) {
      this.AmountToEndorse = $event.target.value;
      if (this.AmountToEndorse.length > 0) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
      this.dataList[0].AmountEndorsed =
        Number(this.AmountToEndorse)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
      let equal =
        localStorage.getItem("defAmountToEndorse") - $event.target.value;
      this.compute(equal);
    },
    onKeypress($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (((keyCode < 48 || keyCode > 57) && keyCode !== 46) || keyCode == 45) {
        // 46 is dot
        $event.preventDefault();
      }
      if (keyCode === 46) {
        if (this.AmountToEndorse.includes(".")) {
          $event.preventDefault();
        }
      }
    },
    compute(equal) {
      if (equal > 0 && equal != localStorage.getItem("defAmountToEndorse")) {
        this.intStatus = 2;
      } else if (equal == localStorage.getItem("defAmountToEndorse")) {
        this.intStatus = 4;
      } else if (equal == 0 && this.dataList[0].PrevBalance > 0) {
        this.intStatus = 3;
      } else {
        this.intStatus = 1;
      }
    },
    getDateTime() {
      const today = new Date();
      const date =
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + today.getDate()).slice(-2) +
        "/" +
        today.getFullYear();
      const time =
        String(today.getHours()).padStart(2, "0") +
        ":" +
        String(today.getMinutes()).padStart(2, "0") +
        ":" +
        String(today.getSeconds()).padStart(2, "0");
      const dateTime = date + " " + time;
      this.dateTime = dateTime;

      this.date = date;
    },
    formatAMPM() {
      const date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      let strTime =
        String(hours).padStart(2, "0") +
        ":" +
        String(minutes).padStart(2, "0") +
        " " +
        ampm;
      this.time = strTime;
    },
    async handleSubmitAction() {
      // this.dataList.map((item) => {
      //   item.AmountEndorsed =
      //     Number(this.AmountToEndorse)
      //       .toFixed(2)
      //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
      // });

      let res = await PostEndorsedAmount({
        intCollectorAccountId: Number(this.postAccountId),
        dblTotalBets: this.dataList[0].TotalBets,
        dblPayouts: this.dataList[0].TotalPayout,
        dblCommisions: this.dataList[0].Commission,
        dblForCashEndorsement: Number(
          localStorage.getItem("defAmountToEndorse")
        ),
        dblEndorsedAmount: this.AmountToEndorse,
        dblBalance: this.dataList[0].PrevBalance,
        strNotes: this.note,
        intStatus: this.intStatus,
      });
      if (res.status == "success") {
        this.successMessage = "Submitted Successfully!";
        this.$refs.modalSuccess.open();
        setTimeout(() => this.$refs.modalSuccess.close(), 5000);
        localStorage.setItem("module", JSON.stringify("CashEndorsement"));
        localStorage.removeItem("CashEndorsementReceipt");
        localStorage.setItem(
          "CashEndorsementReceipt",
          JSON.stringify(this.dataList)
        );
        localStorage.setItem(
          "CashEndorsementCopy",
          JSON.stringify("Collector's")
        );
        router.push({ name: "ReCeipt" });
      } else {
        this.errorMessage = "Submitted Failed!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
      this.close();
    },
    closeBox() {
      this.$emit("closeBoxz");
    },
    async fetchAmountForEndorsementDetails() {
      let res = await getAmountForEndorsementDetails();
      if (res.status == "success") {
        this.dataList = res.data.map((item) => {
          return {
            CollectorName: item.CollectorName,
            CashierName: item.CashierName,
            ShopName: item.BettingShopName,
            DateTime: this.dateTime,
            TransactionNo: "",
            PrevBalance: item.PreviousBalance,
            //PrevBalance: 20,
            CurrentRevenue: item.CurrentRevenue,
            Commission: item.Commissions,
            ShopAdd: item.BettingShopLocation,
            AmountToEndorsed: item.AmountToEndorse,
            TotalBets: item.TotalBets,
            TotalPayout: item.TotalPayout,
          };
        });
        this.AmountToEndorse = res.data[0].AmountToEndorse;
        localStorage.setItem(
          "defAmountToEndorse",
          JSON.stringify(res.data[0].AmountToEndorse)
        );
        this.compute(0);
      } else {
        this.errorMessage =
          "Amount For Endorsement Details List failed to fetch!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
    },
    async fetchAmountForEndorsementDetailsCollector() {
      let res = await getAmountForEndorsementDetailsCollector();
      if (res.status == "success") {
        this.detailsA = res.dataA;
        this.detailsB = res.dataB;
        this.details = res.dataA.map((item) => {
          return {
            avatar:
              "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1669898314~exp=1669898914~hmac=64fa02c1355bd51f4ee3b3cf225deaaf28788a7dd4e88444be26879e067cf375",
            EmployeeNo: item.AccountId,
            CollectorName: item.Name,
            Status: item.Status === 1 ? "Active" : "Inactive",
            MobileNo: item.MobileCountryCode + " " + item.MobileNumber,
          };
        });
        this.postAccountId = res.dataA[0].AccountId;
      } else {
        this.errorMessage =
          "Amount For EndorsementDetails Collector List failed to fetch!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
    },
    changeCollector() {
      if (this.selectedCollector == 1) {
        this.details = this.detailsA.map((item) => {
          return {
            avatar:
              "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1669898314~exp=1669898914~hmac=64fa02c1355bd51f4ee3b3cf225deaaf28788a7dd4e88444be26879e067cf375",
            EmployeeNo: item.AccountId,
            CollectorName: item.Name,
            Status: item.Status === 1 ? "Active" : "Inactive",
            MobileNo: item.MobileCountryCode + " " + item.MobileNumber,
          };
        });
        this.postAccountId = this.detailsA[0].AccountId;
      } else {
        this.details = this.detailsB.map((item) => {
          return {
            avatar:
              "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1669898314~exp=1669898914~hmac=64fa02c1355bd51f4ee3b3cf225deaaf28788a7dd4e88444be26879e067cf375",
            EmployeeNo: item.AccountId,
            CollectorName: item.Name,
            Status: item.Status === 1 ? "Active" : "Inactive",
            MobileNo: item.MobileCountryCode + " " + item.MobileNumber,
          };
        });
        this.postAccountId = this.detailsB[0].AccountId;
      }
    },
  },
  mounted() {
    this.fetchAmountForEndorsementDetails();
    this.fetchAmountForEndorsementDetailsCollector();
    this.getDateTime();
    this.formatAMPM();
  },
};
</script>
