<template>
  <div class="row">
    <div class="col-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 center-holder-box">
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("reprint-receipt") }}</div>
          </div>
          <div class="tab-content">
            <div
              class="container bet-cancel-data"
              v-bind:key="index"
              v-for="(data, index) in transactionReceipt"
            >
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("transactionno") }}</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">{{ data.TransactionNo }}</p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("date-and-time") }}</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">
                    {{
                      new Date(
                        data.TransactionDateTime.slice(0, -1)
                      ).toLocaleDateString()
                    }}
                    {{
                      new Date(
                        data.TransactionDateTime.slice(0, -1)
                      ).toLocaleTimeString()
                    }}
                  </p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("fightno") }}</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">{{ data.FightNumber }}</p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("betoption") }}</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">{{ data.BetOption }}</p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("betamount") }}</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">
                    {{
                      Number(data.betAmount)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
                    }}
                  </p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("sidebar.Payout") }}</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">
                    {{
                      Number(data.Payout)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
                    }}
                  </p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("odds") }}</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">
                    {{
                      Number(data.Odds)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
                    }}
                  </p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("cashier") }}</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">{{ data.Cashier }}</p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("status") }}</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">{{ data.PlaceBetStatus }}</p>
                </div>
              </div>
            </div>
            <BetFooter
              :status="status"
              :showLoadingIcon="showLoadingIcon"
              @handleTriggerModal="callModal"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 right-nav-hider"
      :class="{
        'rightbar--open': open2 === true,
      }"
    >
      <div class="text-left">
        <img
          src="../assets/img/close_box.svg"
          class="close-slider"
          @click="closeBox"
        />
      </div>
      <RightNav />
    </div>
  </div>
  <Modal
    :status="status"
    :status2="status2"
    v-if="isShowModal"
    @close="close"
    @clickSubmit="printReceipt"
    v-model:threshold="threshold"
    @submitThresholdAction="submitThresholdActions"
  >
  </Modal>
  <sweet-modal ref="modalError" icon="error">
    {{ errorMessage }}
  </sweet-modal>
  <sweet-modal ref="modalSuccess" icon="success">
    {{ successMessage }}
  </sweet-modal>
</template>

<script>
import BetFooter from "../components/tabs/BetFooter";
import RightNav from "../components/RightNav";
import Modal from "../components/modal/InputModal";
import { useThresholdStore } from "@/store/threshold.store";
import { useLicenseStatus } from "@/store/license.store";
import { useAuthStore } from "@/store/user.store";

export default {
  name: "ReprintReceipt",
  props: ["open2"],
  emits: ["closeBoxz"],
  components: { BetFooter, RightNav, Modal },
  data() {
    return {
      showLoadingIcon: false,
      threshold: null,
      disabled: true,
      successMessage: "",
      errorMessage: "",
      status: "ReprintReceipt",
      status2: false,
      isShowModal: false,
      module: JSON.parse(sessionStorage.getItem("module")),
      code: JSON.parse(sessionStorage.getItem("barcode")),
      transactionReceipt: JSON.parse(
        localStorage.getItem("TransactionReceipt")
      ),
    };
  },
  methods: {
    closeBox() {
      this.$emit("closeBoxz");
    },
    close() {
      this.isShowModal = false;
      this.status2 = false;
    },
    callModal() {
      this.isShowModal = true;
      this.status2 = false;
    },
    showModal() {
      this.isShowModal = true;
      this.status2 = true;
      this.threshold = null;
    },
    printReceipt() {
      this.close();
      this.successMessage = this.$t("print-submit");
      this.$refs.modalSuccess.open();
      setTimeout(() => this.$refs.modalSuccess.close(), 5000);
      this.$router.push({ name: "ReCeipt" });
    },
    submitThresholdActions() {
      if (this.threshold > 0) {
        const action = useThresholdStore();
        action.submitThresholdAmount(this.threshold);
        const license = useLicenseStatus();
        license.getLicense();
        action.response.then((result) => {
          if (result.status === "success") {
            this.successMessage = this.$t(
              "your-request-submitted-successfully"
            );
            this.$refs.modalSuccess.open();
            setTimeout(() => this.$refs.modalSuccess.close(), 5000);
            this.isShowModal = false;
          } else {
            if (result.data.ErrorCode == "10000092") {
              this.errorMessage = this.$t(
                "error.login." + result.data.ErrorCode
              );
              var auth = useAuthStore();
              setTimeout(() => auth.logout(), 3000);
            } else {
              this.errorMessage = this.$t("your-request-failed");
            }
            this.$refs.modalError.open();
            setTimeout(() => this.$refs.modalError.close(), 5000);
          }
        });
      } else {
        this.errorMessage = this.$t("please-make-sure-to-enter-amount");
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
    },
  },
};
</script>
