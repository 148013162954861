<template>
  <div class="modal-backdrop" @click="close">
    <div class="modal">
      <div class="container">
        <div class="row p-2 mt-3 mb-3">
          <div class="col text-center">
            <img
              v-if="type == 'alert-success'"
              src="../../assets/img/icons/successful.svg"
              class="modal-success-icon"
            />
            <div v-if="type == 'alert-info'" class="sweet-modal-icon sweet-modal-success animate">
              <span class="sweet-modal-line sweet-modal-tip animateSuccessTip"></span><span class="sweet-modal-line sweet-modal-long animateSuccessLong"></span><div class="sweet-modal-placeholder"></div><div class="sweet-modal-fix"></div>
            </div>
            <div v-if="type == 'alert-danger'" class="sweet-modal-icon sweet-modal-error animateErrorIcon">
              <span class="sweet-modal-x-mark animateXMark"><span class="sweet-modal-line sweet-modal-left"></span><span class="sweet-modal-line sweet-modal-right"></span></span>
            </div>
          </div>
        </div>
        <div class="row p-2 mb-2 text-content">
          <div class="col text-center">
            <span v-if="title" class="modal__title pt-2 pb-2">{{ title }}</span>
            <span v-if="code" class="modal__title pt-2 pb-4">{{
              $t(code, codeData)
            }}
            </span> 
          </div>
        </div>
        <div class="row py-2">
          <div class="col text-center">
            <button
              class="
                btn btn-sm
                close-btn
                btn-custom btn-primary btn-block
                fw-700
              "
              @click="close"
            >
              {{ closeLabel }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationPop",
  props: {
    title: {
      type: String,
      required: false,
    },
    code: {
      type: String,
      required: false,
    },
    codeData: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "alert-info",
    },
    closeLabel: {
      type: String,
      required: false,
      default: "Close",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    document.body.classList.add("overflow-hidden");
  },
  unmounted() {
    document.body.classList.remove("overflow-hidden");
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .4) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: relative;
  padding: 30px;
  width: 640px;
  height: auto;
  z-index: 1000;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.response-modal {
  width: 508px;
  height: 286px;
  top: -135px;
  border-radius: 20px;
}

.text-content {
  min-height: 12vh!important;
}

.btn-close {
  position: absolute;
  top: 17px;
  right: 17px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: var(--blue2);
  background: transparent;
}
.close-btn {
  width: 118.2px;
  height: 50px;
  background: var(--orange) !important;
  border-color: var(--orange) !important;
  border-radius: 10px;
  font-size: 20px;
}
.modal__title {
  font-size: 30px;
}
.modal-warning-icon {
  width: 80px;
  height: 80px;
}
</style>
