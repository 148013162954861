
const sitehost = window.location.hostname;
const activeSite = sitehost.includes('agent.')
  ? 'AGENT'
  : sitehost.includes('collector.')
  ? 'COLLECTOR'
  : 'CASHIER';
  
export const getActiveSite = function() {
	return activeSite;
}

export const isActiveSite = function(siteName){
	return (activeSite==siteName);
}