import { defineStore } from 'pinia';
import { requestCreditSubmit } from '@/api';

export const useCreditStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: 'creditStore',
  state: () => {
    return {
      response: null,
    };
  },

  actions: {
    submitRequestCredit(param) {
      let result = requestCreditSubmit(param);
      this.response = result;
    },
  },
});
