<template>
  <div
    class="numpad-holder"
    :class="{
      'numpad-holder2': status === 'CreditRequest',
    }"
  >
    <ul
      class="num-holder"
      :class="{
        'num-holder2': status === 'CreditRequest',
      }"
    >
      <li
        :class="clear === false && data.active === true ? 'active' : null"
        :key="index"
        v-for="(data, index) in data"
        @click="changeAmount(data.value)"
      >
        <span v-if="data.value !== 'clear'">
          {{ data.value }}
        </span>
        <img v-else src="../../assets/img/clear-button.svg" class="clear-btn" />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "NumPad",
  props: ["clear", "status"],
  emits: ["selectedNumAmount"],
  data() {
    return {
      data: [
        {
          id: 0,
          value: 1,
        },
        {
          id: 1,
          value: 2,
        },
        {
          id: 2,
          value: 3,
        },
        {
          id: 3,
          value: 4,
        },
        {
          id: 4,
          value: 5,
        },
        {
          id: 5,
          value: 6,
        },
        {
          id: 6,
          value: 7,
        },
        {
          id: 7,
          value: 8,
        },
        {
          id: 8,
          value: 9,
        },
        {
          id: 9,
          value: ".",
        },
        {
          id: 10,
          value: 0,
        },
        {
          id: 11,
          value: "clear",
        },
      ],
    };
  },
  methods: {
    changeAmount(amount) {
      this.$emit("selectedNumAmount", amount);
    },
  },
};
</script>
<style>
.numpad-holder {
  width: 50%;
  float: left;
  /* display: inline-block; */
  /* margin-left: 30px; */
}
.numpad-holder2 {
  width: 100%;
  float: left;
}
.num-holder {
  padding: 0;
  margin: 0;
}
.num-holder li {
  width: 30%;
  height: 70px;
  background: #28356d;
  border: 1px solid #656565;
  border-radius: 10px;
  color: var(--white);
  text-align: center;
  margin-left: 3%;
  margin-top: 15px;
  transition: 0.5s;
  padding: 20px 0;
  cursor: pointer;
  display: inline-block;
  font-size: 19px;
  font-weight: bold;
  font-family: "futura-book-heavy";
}
.num-holder2 li {
  margin-top: 0;
  margin-bottom: 15px;
}
.num-text {
  /* width: 208px;
  margin-left: 444px; */
  /* width: 632px; */
  width: 83%;
  height: 70px;
  margin-left: 0;
  font-size: 24px;
  margin-right: 1.5%;
}
.clear-btn {
  position: relative;
  top: -2px;
}

.numpad-holder > * {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>