<template>
  <div class="modal-backdrop">
    <div class="modal notification-modal">
      <div class="notification-title fz-17 fw-800">Notifications</div>
      <ul class="list-group" :key="index" v-for="(item, index) in dataList">
        <li class="list-group-item p-2" @click="showModal()">
          <div class="row">
            <div class="col-12 col-sm-4"><img class="item-avatar" :src="item.avatar"></div>
            <div class="col-12 col-sm-8 text-left">
              <div class="row fz-14 fw-800">{{ item.name }}</div>
              <div class="row fz-12">{{ item.desc }}</div>
            </div>
            <span class="badge badge-danger badge-pill">&nbsp;</span>
          </div>
        </li>
      </ul>     
    </div>
  </div>
</template>

<script>
export default {
  name: "AmountModal",
  props: {
    status: {
      type: String,
      required: false,
    },
    singleUpdate: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      state: null,
      confirmval: "",
      dataList: [
        {
          avatar: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1669898314~exp=1669898914~hmac=64fa02c1355bd51f4ee3b3cf225deaaf28788a7dd4e88444be26879e067cf375",
          name: "Juan Carlos",
          desc: "Is requesting to collect",
          read: 0
        },
        {
          avatar: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1669898314~exp=1669898914~hmac=64fa02c1355bd51f4ee3b3cf225deaaf28788a7dd4e88444be26879e067cf375",
          name: "Juan Carlos",
          desc: "Is requesting to collect",
          read: 0
        },
        {
          avatar: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1669898314~exp=1669898914~hmac=64fa02c1355bd51f4ee3b3cf225deaaf28788a7dd4e88444be26879e067cf375",
          name: "Juan Carlos",
          desc: "Is requesting to collect",
          read: 0
        },
        {
          avatar: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1669898314~exp=1669898914~hmac=64fa02c1355bd51f4ee3b3cf225deaaf28788a7dd4e88444be26879e067cf375",
          name: "Juan Carlos",
          desc: "Is requesting to collect",
          read: 0
        },
      ]
    };
  },
  emits: [
    "close",
    "clickModal",
  ],
  methods: {
    close() {
      this.$emit("close");
    },
    showModal() {
      this.$emit("clickModal");
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  padding: 30px;
  padding-left: 15px;
  padding-right: 15px;
  width: 400px;
  height: auto;
  z-index: 1000;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.notification-modal {
  padding: 30px;
  padding-left: 15px;
  padding-right: 15px;
  width: 293px;
  height: auto;
  z-index: 1000;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.z-index-0 {
  z-index: 0;
}
.list-group-item {
  background: #F2F2F2 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-top: 5px;
  cursor: pointer;
}
.badge-container {
  padding-right: 17px;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge-danger {
  background-color: #FF0000;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
  position: absolute;
  width: 14px;
  height: 14px;
  right: 10px;
}
.item-avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 1;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-rotate {
  animation: rotate 2s linear infinite;
}
</style>