<template>
  <div class="modal-backdrop">
    <div class="modal notification-modal">
       <div class="row content">
            <div class="col-12 col-sm-4 p-0"><img class="item-avatar" src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1669898314~exp=1669898914~hmac=64fa02c1355bd51f4ee3b3cf225deaaf28788a7dd4e88444be26879e067cf375"></div>
            <div class="col-12 col-sm-8 details-content text-left">
              <div class="row fz-16 fw-800">Juan Carlos</div>
              <div class="row fz-16 mt-2">Collector 5</div>
              <div class="row fz-16 fw-800 mt-3">Apuesta Total, Branch 10</div>
              <div class="row fz-16 mt-2">Collecting Amount: 800,000.00</div>
              <div class="row fz-16 mt-2">Cashier: Cassandra Sanchez</div>
              <div class="row fz-16 mt-2">Hermano, Can you collect Apuesta total branch 10 for me? because I our tire got broken in the middle of the road.</div>
            </div>
            <div class="bet-btn-holder text-center remove-border">
                <button
                    type="button"
                    class="bet-btn bet-btn-orange tvl-btn">Collection Details</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AmountModal",
  props: {
    status: {
      type: String,
      required: false,
    },
    singleUpdate: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      state: null,
      confirmval: "",
      dataList: [
        {
          avatar: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1669898314~exp=1669898914~hmac=64fa02c1355bd51f4ee3b3cf225deaaf28788a7dd4e88444be26879e067cf375",
          name: "Juan Carlos",
          desc: "Is requesting to collect",
          read: 0
        },
        {
          avatar: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1669898314~exp=1669898914~hmac=64fa02c1355bd51f4ee3b3cf225deaaf28788a7dd4e88444be26879e067cf375",
          name: "Juan Carlos",
          desc: "Is requesting to collect",
          read: 0
        },
        {
          avatar: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1669898314~exp=1669898914~hmac=64fa02c1355bd51f4ee3b3cf225deaaf28788a7dd4e88444be26879e067cf375",
          name: "Juan Carlos",
          desc: "Is requesting to collect",
          read: 0
        },
        {
          avatar: "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1669898314~exp=1669898914~hmac=64fa02c1355bd51f4ee3b3cf225deaaf28788a7dd4e88444be26879e067cf375",
          name: "Juan Carlos",
          desc: "Is requesting to collect",
          read: 0
        },
      ]
    };
  },
  emits: [
    "close",
    "clickModal",
  ],
  methods: {
    close() {
      this.$emit("close");
    },
    showModal($event) {
      this.$emit("clickModal", $event);
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: relative;
  padding: 30px;
  width: 517px;
  height: auto;
  z-index: 1000;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.modal button {
    background: var(--orange);
    box-shadow: 0px 8px 15px #e84e2559;
}
.z-index-0 {
  z-index: 0;
}
.content {
    padding: 30px;
    padding-bottom: 0px;
}
.item-avatar {
  vertical-align: middle;
  width: 114px;
  height: 114px;
  border-radius: 50%;
  opacity: 1;
}
.details-content {
    padding-left: 20px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-rotate {
  animation: rotate 2s linear infinite;
}
</style>