<script setup>
import { storeToRefs } from 'pinia';

import Notification from "../components/modal/Notification.vue";
import { useAlertStore } from '@/store/global';

const alertStore = useAlertStore();
const { alert } = storeToRefs(alertStore);

</script>

<template>
	<transition name="bounce">
		<Notification  v-if="alert && alert.message" :title="alert.message" :type="alert.type" @close="alertStore.close()" > </Notification>
	</transition>
	<transition name="bounce">
		<Notification  v-if="alert && alert.code" :codeData="alert.data" :code="alert.code" :type="alert.type" @close="alertStore.close()" > </Notification>
	</transition>
</template>
<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounceOut .5s;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
	opacity: 0;
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
	opacity: 1;
  }
}
@keyframes bounceOut {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(.95);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(.3);
  }
} 
</style>