<template>
  <div class="cash-holder">
    <ul class="cb-holder">
      <li
        :class="{
          'btn-selected': index === 0 || index === 1 || index === 2,
          active: clear === false && data.active === true,
        }"
        :key="index"
        v-for="(data, index) in data"
        @click="changeAmount(data.value, data.id)"
      >
        {{ data.value }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CaSh",
  props: ["clear"],
  emits: ["selectedAmount"],
  data() {
    return {
      data: [
        {
          id: 0,
          value: 100,
          active: false,
        },
        {
          id: 1,
          value: 300,
          active: false,
        },
        {
          id: 2,
          value: 500,
          active: false,
        },
        {
          id: 3,
          value: 1000,
          active: false,
        },
        {
          id: 4,
          value: 2000,
          active: false,
        },
        {
          id: 5,
          value: 3000,
          active: false,
        },
        {
          id: 6,
          value: 4000,
          active: false,
        },
        {
          id: 7,
          value: 5000,
          active: false,
        },
        {
          id: 8,
          value: 6000,
          active: false,
        },
        {
          id: 9,
          value: 7000,
          active: false,
        },
        {
          id: 10,
          value: 8000,
          active: false,
        },
        {
          id: 11,
          value: 9000,
          active: false,
        },
        {
          id: 12,
          value: 10000,
          active: false,
        },
      ],
    };
  },
  methods: {
    changeAmount(amount, id) {
      this.$emit("selectedAmount", amount);
      this.resetSelected();
      this.data[id].active = !this.data[id].active;
    },
    resetSelected() {
      this.data.map((item) => {
        item.active = false;
      });
    },
  },
};
</script>

<style scoped>
.cash-holder > * {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>