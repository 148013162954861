<template>
  <div class="row">
    <div class="col-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 center-holder-box">
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("summaryreports") }}</div>
          </div>
          <div class="tab-content">
            <vue3-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="false"
              :paginate-elements-by-height="1400"
              filename="Summary-Report"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width="800px"
              @progress="onProgress($event)"
              @hasStartedGeneration="hasStartedGeneration()"
              @hasGenerated="hasGenerated($event)"
              ref="html2Pdf"
            >
              <template v-slot:pdf-content>
                <ContentToPrint :dataSummary="dataSummary" />
              </template>
            </vue3-html2pdf>

            <div
              class="container bet-cancel-data"
              v-bind:key="index"
              v-for="(data, index) in dataSummary"
            >
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ data.title }}</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">{{ data.value }}</p>
                </div>
              </div>
            </div>

            <BetFooter
              :status="status"
              @handleAction="generatePDF"
              :showLoadingIcon="showLoadingIcon"
              @handleTriggerModal="callModal"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 right-nav-hider"
      :class="{
        'rightbar--open': open2 === true,
      }"
    >
      <div class="text-left">
        <img
          src="../assets/img/close_box.svg"
          class="close-slider"
          @click="closeBox"
        />
      </div>
      <RightNav @onClick="showModal" />
    </div>
  </div>
  <Modal
    :status="status"
    :status2="status2"
    v-if="isShowModal"
    @close="close"
    @clickSubmit="printReciept"
    v-model:threshold="threshold"
    @submitThresholdAction="submitThresholdActions"
  >
  </Modal>
  <sweet-modal ref="modalError" icon="error">
    {{ errorMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
  <sweet-modal ref="modalSuccess" icon="success">
    {{ successMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";
import ContentToPrint from "../components/ContentToPrint";
import BetFooter from "../components/tabs/BetFooter";
import RightNav from "../components/RightNav";
import Modal from "../components/modal/InputModal.vue";
import router from "../router";
import { useSummaryReportsStore } from "@/store/summary.store";
import { useThresholdStore } from "@/store/threshold.store";
import { useLicenseStatus } from "@/store/license.store";
import { useAuthStore } from "@/store/user.store";
import { useStats } from "@/store/stats.store";
export default {
  name: "SummaryReports",
  props: ["open2"],
  emits: ["closeBoxz"],
  components: { BetFooter, RightNav, Vue3Html2pdf, ContentToPrint, Modal },
  data() {
    return {
      status: "SummaryReports",
      status2: false,
      showLoadingIcon: false,
      isShowModal: false,
      successMessage: "",
      errorMessage: "",
      threshold: null,
      dataSummary: [
        {
          id: 1,
          title: this.$t("available-credits"),
          value: 0,
        },
        {
          id: 2,
          title: this.$t("cash-in"),
          value: 0,
        },
        {
          id: 3,
          title: this.$t("cash-on-hand"),
          value: 0,
        },
        {
          id: 4,
          title: this.$t("payouts"),
          value: 0,
        },
        {
          id: 5,
          title: this.$t("approved-credit-requests"),
          value: 0,
        },
        {
          id: 6,
          title: this.$t("cashiername"),
          value: "",
        },
        {
          id: 6,
          title: this.$t("Timestamp"),
          value: "",
        },
      ],
    };
  },
  methods: {
    closeBox() {
      this.$emit("closeBoxz");
    },
    // onProgress(event) {
    // console.log(`Processed: ${event} / 100`);
    // },
    hasGenerated() {
      alert(this.$t("pdf-generated-successfully"));
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    close() {
      this.isShowModal = false;
      this.status2 = false;
    },
    closeModal() {
      this.$refs.modalSuccess.close();
      this.$refs.modalError.close();
    },
    callModal() {
      this.isShowModal = true;
      this.status2 = false;
    },
    printReciept() {
      this.close();
      this.successMessage = this.$t("print-submit");

      const authStore = useAuthStore();
      if (authStore.printingModuleEnabled) {
        let printEndpoint = `${authStore.printBaseUrl}/cashier/print/receipt/summary-report/${authStore.getToken}`;
        window.open(printEndpoint);
        return;
      }
      router.push({ name: "ReCeipt" });
      setTimeout(() => this.$refs.modalSuccess.close(), 5000);
      setTimeout(() => this.$refs.modalSuccess.open(), 5000);
    },
    showModal() {
      this.isShowModal = true;
      this.status2 = true;
      this.threshold = null;
    },
    getDateTime() {
      const today = new Date();
      const date =
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + today.getDate()).slice(-2) +
        "/" +
        today.getFullYear();
      const time =
        String(today.getHours()).padStart(2, "0") +
        ":" +
        String(today.getMinutes()).padStart(2, "0") +
        ":" +
        String(today.getSeconds()).padStart(2, "0");
      const dateTime = date + " " + time;
      this.dateTime = dateTime;

      return date;
    },
    formatAMPM() {
      const date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      let strTime =
        String(hours).padStart(2, "0") +
        ":" +
        String(minutes).padStart(2, "0") +
        " " +
        ampm;
      return strTime;
    },
    setReciept(data) {
      localStorage.removeItem("module");
      localStorage.removeItem("TransactionReceipt");
      localStorage.removeItem("transactionno");
      localStorage.removeItem("datetime");
      localStorage.removeItem("fightno");
      localStorage.removeItem("betoption");
      localStorage.removeItem("betamount");
      localStorage.removeItem("payout");
      localStorage.removeItem("odds");
      localStorage.removeItem("cashierno");
      localStorage.removeItem("statuss");
      localStorage.removeItem("barcode");

      localStorage.removeItem("availablecredits");
      localStorage.removeItem("cashin");
      localStorage.removeItem("cashonhand");
      localStorage.removeItem("payouts");
      localStorage.removeItem("approvedcredit");
      // localStorage.removeItem("cashiername");
      localStorage.removeItem("timestamp");

      localStorage.setItem("module", JSON.stringify("SummaryReports"));
      localStorage.setItem(
        "availablecredits",
        JSON.stringify(
          Number(data.availableCredit)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
        )
      );
      localStorage.setItem(
        "cashin",
        JSON.stringify(
          Number(data.cashIn)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
        )
      );
      localStorage.setItem(
        "cashonhand",
        JSON.stringify(
          Number(data.cashOnHand)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
        )
      );
      localStorage.setItem(
        "payouts",
        JSON.stringify(
          Number(data.payOut)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
        )
      );
      localStorage.setItem(
        "approvedcredit",
        JSON.stringify(
          Number(data.approvedCreditRequests)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
        )
      );
      var name = useAuthStore();
      localStorage.setItem("cashiername", JSON.stringify(name.fullname));
      localStorage.setItem(
        "timestamp",
        JSON.stringify(this.getDateTime() + " " + this.formatAMPM())
      );
    },
    submitThresholdActions() {
      if (this.threshold > 0) {
        const action = useThresholdStore();
        action.submitThresholdAmount(this.threshold);
        const license = useLicenseStatus();
        license.getLicense();
        action.response.then((result) => {
          // console.log(result);
          if (result.status === "success") {
            this.successMessage = this.$t(
              "your-request-submitted-successfully"
            );
            this.$refs.modalSuccess.open();
            setTimeout(() => this.$refs.modalSuccess.close(), 5000);
            this.isShowModal = false;
          } else {
            if (result.data.ErrorCode == "10000092") {
              this.errorMessage = this.$t(
                "error.login." + result.data.ErrorCode
              );
              var auth = useAuthStore();
              setTimeout(() => auth.logout(), 3000);
            } else {
              this.errorMessage = this.$t("your-request-failed");
            }
            this.$refs.modalError.open();
            setTimeout(() => this.$refs.modalError.close(), 5000);
          }
        });
      } else {
        this.errorMessage = this.$t("please-make-sure-to-enter-amount");
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
    },
  },
  mounted() {
    const action = useSummaryReportsStore();
    action.getSummaryReports();
    const stats = useStats();
    stats.refresh();
    const license = useLicenseStatus();
    license.getLicense();
    action.response.then((result) => {
      // console.log(result);
      if (result.status === "success") {
        var data = useAuthStore();
        this.dataSummary[0].value =
          Number(result.data.availableCredit)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
        this.dataSummary[1].value =
          Number(result.data.cashIn)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
        this.dataSummary[2].value =
          Number(result.data.cashOnHand)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
        this.dataSummary[3].value =
          Number(result.data.payOut)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
        this.dataSummary[4].value =
          Number(result.data.approvedCreditRequests)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;

        this.dataSummary[5].value = data.fullname;
        this.dataSummary[6].value =
          this.getDateTime() + " " + this.formatAMPM();
        // let $datetime = new Date(
        //   result.data.timeStamp.replace("T", " ").replace(".000Z", "")
        // );
        // this.dataSummary[6].value =
        //   $datetime.toLocaleDateString($datetime) +
        //   " " +
        //   $datetime.toLocaleTimeString();
        // result.data.timeStamp = this.dataSummary[6].value;
        this.setReciept(result.data);
      } else {
        if (result.data.ErrorCode == 10000070) {
          localStorage.removeItem("auth");
          window.location.reload();
        } else {
          if (result.data.ErrorCode == "10000092") {
            this.errorMessage = this.$t("error.login." + result.data.ErrorCode);
            var auth = useAuthStore();
            setTimeout(() => auth.logout(), 3000);
          } else {
            this.errorMessage = this.$t("error." + result.data.ErrorCode);
          }
          this.$refs.modalError.open();
          setTimeout(() => this.$refs.modalError.close(), 5000);
        }
      }
    });
  },
};
</script>
