import { defineStore } from 'pinia';
import { requestThresholdSubmit } from '@/api';

export const useThresholdStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: 'thresholdStore',
  state: () => {
    return {
      response: null,
    };
  },

  actions: {
    submitThresholdAmount(param) {
      let result = requestThresholdSubmit(param);
      this.response = result;
    },
  },
});
