<template>
  <div class="row aligns-items-center justify-content-center">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12 center-holder-box pe-4">
      <div class="lang-switcher-outer">
        <div class="lang-switcher-holder">
          <LocaleSwitcher />
        </div>
      </div>
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">Collection Reports</div>
          </div>
          <div class="tab-content">
            <div class="row">
              <div class="col-12 col-sm-6">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <label class="form-label"
                      >{{ $t("label.BettingShop") }}:</label
                    >
                    <Select2
                      v-model="BettingShopValue"
                      :options="BettingShops"
                      :settings="{ settingOption: value, settingOption: value }"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Date:</label>
                    <input type="date" class="form-control agent-text" />
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Collector:</label>
                    <Select2
                      v-model="CollectorValue"
                      :options="Collectors"
                      :settings="{ minimumResultsForSearch: -1 }"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Status:</label>
                    <Select2
                      v-model="StatusValue"
                      :options="Statuses"
                      :settings="{ settingOption: value, settingOption: value }"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 mt-5 mb-5">
                <div class="row">
                  <div class="col-6"></div>
                  <div class="col-6 px-4">
                    <div class="row">
                      <div class="col-4 px-1">
                        <button type="button" class="rs-btn rs-gen active">
                          {{ $t("button.generatereport") }}
                        </button>
                      </div>
                      <div class="col-4 px-1">
                        <button type="button" class="rs-btn rs-dl active">
                          {{ $t("button.download") }}
                        </button>
                      </div>
                      <div class="col-4 px-1">
                        <button type="button" class="rs-btn rs-print active">
                          {{ $t("button.reprintreport") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 mb-3">
                <table
                  class="table table-striped table-hover dtable-border"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>{{ $t("label.BettingShop") }}</th>
                      <th class="cr-tw">Date & Time</th>
                      <th>Transaction No</th>
                      <th>Cashier Name</th>
                      <th>Collector</th>
                      <th class="text-right">Previous Balance</th>
                      <th class="text-right">Total Bets</th>
                      <th class="text-right">Payouts</th>
                      <th class="text-right">Commissions</th>
                      <th class="text-right">For Collection</th>
                      <th class="text-right">Collected Amount</th>
                      <th class="text-right">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="index" v-for="(item, index) in dataList">
                      <td>
                        {{ item.bettingShop + " " + parseInt(index + 1) }}
                      </td>
                      <td>{{ item.date }}</td>
                      <td>{{ item.transno + parseInt(index + 1) }}</td>
                      <td>{{ item.name + " " + parseInt(index + 1) }}</td>
                      <td>{{ item.collector + " " + parseInt(index + 1) }}</td>
                      <td class="text-right">{{ item.prevBalance }}</td>
                      <td class="text-right">{{ item.totalBet }}</td>
                      <td class="text-right">{{ item.payouts }}</td>
                      <td class="text-right">{{ item.commission }}</td>
                      <td class="text-right">{{ item.collection }}</td>
                      <td class="text-right">{{ item.collectedAmount }}</td>
                      <td class="text-right">{{ item.status }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Pagination />
      </div>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import Select2 from "vue3-select2-component";
import Pagination from "../components/Pagination";
export default {
  name: "CollectionReport",
  components: {
    Pagination,
    Select2,
    LocaleSwitcher,
  },
  data() {
    return {
      myValue: "",
      BettingShopValue: "",
      CollectorValue: "",
      StatusValue: "",
      myOptions: ["op1", "op2", "op3"], // or [{id: key, text: value}, {id: key, text: value}]
      BettingShops: ["Betting Shop 1", "Betting Shop 2", "Betting Shop 3"],
      Collectors: ["Collector 1", "Collector 2", "Collector 3"],
      Statuses: ["Fully Paid", "Paid", "Unpaid", "Partial Paid"],
      filterByOption: [
        { id: 1, text: "Date" },
        { id: 2, text: "Event" },
      ],
      filterVal: 1,
      dataList: [
        {
          bettingShop: "Betting Shop",
          date: "06/30/2021 3:00:00 PM",
          transno: "1233445",
          name: "Cashier",
          collector: "Collector",
          prevBalance: "123,000.00",
          totalBet: "1,000,000.00",
          payouts: "852,000.00",
          commission: "2,500.00",
          collection: "268,500.00",
          collectedAmount: "268,000.00",
          status: "Fully Paid",
        },
        {
          bettingShop: "Betting Shop",
          date: "06/30/2021 3:00:00 PM",
          transno: "1233445",
          name: "Cashier",
          collector: "Collector",
          prevBalance: "--",
          totalBet: "--",
          payouts: "--",
          commission: "--",
          collection: "--",
          collectedAmount: "--",
          status: "Unpaid",
        },
        {
          bettingShop: "Betting Shop",
          date: "06/30/2021 3:00:00 PM",
          transno: "1233445",
          name: "Cashier",
          collector: "Collector",
          prevBalance: "6,500.00",
          totalBet: "750,000.00",
          payouts: "453,000.00",
          commission: "1,600.00",
          collection: "301,900.00",
          collectedAmount: "301,900.00",
          status: "Partial Paid",
        },
        {
          bettingShop: "Betting Shop",
          date: "06/30/2021 3:00:00 PM",
          transno: "1233445",
          name: "Cashier",
          collector: "Collector",
          prevBalance: "4,600.00",
          totalBet: "100,000.00",
          payouts: "55,000.00",
          commission: "250.00",
          collection: "49,350.00",
          collectedAmount: "49,350.00",
          status: "Paid",
        },
        {
          bettingShop: "Betting Shop",
          date: "06/30/2021 3:00:00 PM",
          transno: "1233445",
          name: "Cashier",
          collector: "Collector",
          prevBalance: "--",
          totalBet: "--",
          payouts: "150,000.00",
          commission: "150.00",
          collection: "99,850.00",
          collectedAmount: "99,850.00",
          status: "Unpaid",
        },
      ],
    };
  },
  methods: {
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },
  },
};
</script>

<style>
@import "bootstrap";
@import "datatables.net-bs5";
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after {
  display: none !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  display: flex;
  margin: 2px 0 !important;
  margin-top: 15px !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination li {
  padding: 0;
  text-decoration: none;
  background: none;
  border-radius: 0;
  font-weight: normal;
}
div.dataTables_wrapper div.row div.col-md-6:first-child {
  display: none;
}
.dataTables_filter label {
  display: block;
}

.dtable-input {
  width: 89px;
  height: 35px;
  border: 1px solid #c9c9c9;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
}

.dtable-input2 {
  width: 89px;
  height: 35px;
  border: 1px solid #707070;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
  display: inline-block;
}
.dtable-btn {
  width: 68px;
  height: 35px;
  background: #23ad0e;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: -1px;
}
.dtable-btn2 {
  width: 116px;
  height: 35px;
  background: #35469a;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
}
.top-input {
  width: 122px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
  text-align: right;
  display: inline-block;
}
.top-apply-btn {
  width: 107px;
  height: 40px;
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2559;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
.ds-block {
  display: block;
}
.update-share-holder {
  display: inline-block;
  text-align: left;
}
.top-search {
  width: 241px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
}
table.dataTable td {
  vertical-align: middle;
}
table.dataTable th {
  vertical-align: top;
}
.dtable-text:focus {
  box-shadow: none;
  border: 1px solid #707070;
}
.tab-content {
  padding-bottom: 2% !important;
  padding-top: 2% !important;
}
.page-link.active,
.active > .page-link {
  background-color: var(--violet);
  border-color: var(--violet);
}
.rs-btn {
  width: 100%;
  font-size: 16px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  color: #656565;
  background: #efefef;
  box-shadow: none;
  border: none;
}
.rs-gen.active {
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2570;
  color: #fff;
}
.rs-dl.active {
  background: #0081ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0081ff59;
  color: #fff;
}
.rs-print.active {
  background: #2cb742 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2cb74259;
  color: #fff;
}
.dtable-border {
  border-top: 1px solid #efefef;
}
.table th,
.table td {
  vertical-align: middle;
}
.cr-tw {
  width: 120px;
}
</style>