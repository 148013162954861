import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import { createApp } from 'vue';
import App from './App';
import VueNumber from 'vue-number-animation';
import Toaster from '@meforma/vue-toaster';
import { createPinia } from 'pinia';
import router from './router';
import { languages, defaultLocale } from './i18n';
import { createI18n } from 'vue-i18n';
import SweetModal from 'sweet-modal-vue-3';
import VueNumberFormat from 'vue-number-format'

const localeStorageLang = localStorage.getItem('lang');
const messages = Object.assign(languages);
const i18n = createI18n({
  legacy: false,
  locale: localeStorageLang || defaultLocale,
  fallbackLocale: 'en',
  messages,
  numberFormats: {
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD',
      },
    },
  },
});

const pinia = createPinia();
const app = createApp(App);

var sitehost = window.location.hostname;
var activeSite = sitehost.includes("agent.")
  ? "AGENT"
  : sitehost.includes("collector.")
  ? "COLLECTOR"
  : "CASHIER";

app.provide("activeSite", activeSite);
app.config.globalProperties.activeSite = activeSite; 

app.use(VueNumberFormat);
app.use(router);
app.use(VueNumber);
app.use(Toaster);
app.use(pinia);
app.use(i18n);
app.use(SweetModal);
app.mount("#app");
