import { defineStore } from 'pinia';
import { requestSummaryReports } from '@/api';

export const useSummaryReportsStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: 'summaryReportsStore',
  state: () => {
    return {
      response: null,
    };
  },

  actions: {
    getSummaryReports() {
      let result = requestSummaryReports();
      this.response = result;
    },
  },
});
