<template>
  <form @submit.prevent="handlePassResetRequest">
    <div class="form-outline mb-4">
      <transition name="bounce"
        ><CircleSpinner :active="inProgress"></CircleSpinner
      ></transition>
      <label class="form-label" v-if="stage == 0">{{  $t("enterEmail") }}</label>
      <div v-if="stage == 0" class="ginput_container ginput_container_text">
        <input
          type="email"
          class="form-control email"
          v-model="email"
          :placeholder="$t('email')"
          @keyup="validateEmail(email)"
        /><span class="line-div">|</span>
      </div>
      <transition name="bounce">
        <div v-if="stage == 1 && !inProgress">
          <!-- <div class="ginput_container ginput_container_text mb-3">
            <input
              type="text"
              class="form-control resetcode"
              v-model="code"
              :placeholder="$t('resetpassword.resetcode')"
            /><span class="line-div">|</span>
          </div>
          <hr /> -->
          <div class="ginput_container ginput_container_text mt-4">
            <label for="password" class="fw-700 mb-2">{{ $t('resetpassword.newpassword') }}</label>
            <input
              id="password"
              type="password"
              class="form-control password"
              v-model="newpassword"
              minlength="8"
              :placeholder="$t('resetpassword.newpassword')"
            /><span class="line-div">|</span>
          </div>
          <div class="ginput_container ginput_container_text mt-5">
            <label for="password" class="fw-700 mb-2">{{ $t('resetpassword.newpasswordagain') }}</label>
            <input
              type="password"
              class="form-control password"
              v-model="newpasswordagain"
              minlength="8"
              :placeholder="$t('resetpassword.newpasswordagain')"
            /><span class="line-div">|</span>
          </div>
        </div>
      </transition>
      <div class="form-outline mt-5">
        <button
          v-if="stage == 0"
          :disabled="validEmail || inProgress"
          type="submit"
          id="submitReset"
          class="btn btn-lg btn-custom btn-danger btn-block fw-700 w-100 text-uppercase"
        >
          {{ $t("button.resetpass") }}
        </button>

        <button
          v-if="stage == 1"
          :disabled="inProgress || !isSubmittable"
          type="submit"
          id="submitSavePassword"
          class="btn btn-lg btn-custom btn-danger btn-block fw-700 w-100 btn-red"
        >
          {{ $t("button.resetpassword") }}
        </button>
      </div>
    </div>
    <slot></slot>
  </form>
  <sweet-modal ref="modalError" icon="error">
    {{ $t(errorMessage) }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">{{  $t("button.close")  }}</button>
    </div>
  </sweet-modal>
  <sweet-modal ref="modalSuccess" icon="success">
    {{ $t(successMessage) }}
    <div class="text-center ">
      <button type="button" class="sweet-btn" @click="closeModal">{{  $t("button.close")  }}</button>
    </div>
  </sweet-modal>
</template>
<script>
export default {
  name: "PasswordReset",
  data() {
    return { 
      validEmail: true,
      sucessResetPassword: false,
    }
  },
  methods: {
    validateEmail(el) {
      let valid = String(el)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      if (valid) {
        // this.validEmail = false;
       document.getElementById('submitReset').removeAttribute("disabled");

      } else {
        // this.validEmail = true;
       document.getElementById('submitReset').setAttribute("disabled","disabled");

      }
      // console.log(this.validEmail);
    }
  }
};
</script>

<script setup>
import { ref, computed, onMounted } from "vue";
import { resetPassword, saveNewPassword, validateToken } from "@/api";
// import { useAlertStore } from "@/store/global";
import CircleSpinner from "../misc/CircleSpinner.vue";
import router from "../../router";
// import { useAuthStore } from "@/store/user.store";
// const auth = useAuthStore();

// const alert = useAlertStore();

const email = ref("");
const newpassword = ref("");
const newpasswordagain = ref("");
const code = ref("");
const stage = ref(0);
const inProgress = ref(false);
const modalError = ref('modalError');
const modalSuccess = ref('modalSuccess');
const errorMessage = ref("");
const successMessage = ref("");

const isSubmittable = computed(
  () =>
    code.value.length > 7 &&
    newpassword.value.length > 7 &&
    newpasswordagain.value.length > 7 &&
    newpassword.value == newpasswordagain.value
);

const props = defineProps(["resetCode"]);

onMounted(() => {
  if (props.resetCode !== "" && props.resetCode !== "forgot") {
    stage.value = 1;
    code.value = props.resetCode;
    handleToken();
  } else if (props.resetCode === "forgot") {
    stage.value = 0;
  }

});

async function handleToken () {
  let res = await validateToken(code.value);
  if (res.status !== 'success') {
    errorMessage.value = "error.login.10000070";
    modalError.value.open()
    setTimeout(() => { router.push({name:"login"}) }, 3000)
  }

}
async function handlePassResetRequest() {
  inProgress.value = true;
  if (stage.value == 0) {
    let resetResponse = await resetPassword(email.value, code.value);
    if (resetResponse.status == "success") {
      // stage.value = 1;
      successMessage.value = "resetpassword.resetcodesent"
      modalSuccess.value.open()
    } else {
      errorMessage.value = "error.login." + resetResponse.data.ErrorCode;
      modalError.value.open()
    }
  } else if (stage.value == 1) {
    let resetResponse = await saveNewPassword(newpassword.value, code.value);

    if (resetResponse.status == "success") {
      stage.value = 2;

      successMessage.value = "resetpassword.success"
      modalSuccess.value.open()
    } else {
      errorMessage.value = "error.login." + resetResponse.data.ErrorCode;
      modalError.value.open()
    }
  }

  inProgress.value = false;
}

function closeModal() {
  modalError.value.close()
  modalSuccess.value.close()
  if (stage.value == 2) {
      router.push({name:"login"});
  }

}
</script>

<style>
.btn-red {
  background-color: var(--orange) !important;
}
#submitSavePassword {
  height: 60px;
}
</style>