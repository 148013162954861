<template>
  <div class="top-gain-status-holder">
    <div class="d-flex justify-content-end gap-3 mt-4">
      <div
        class="
          rounded
          p-3
          px-4
          pr-10
          rounded-pill
          orange-bg
          top-gain-status-box
        "
      >
        <div class="top-gain-status-text">
          {{ $t("stats.availableCredits") }}
        </div>
        <span class="text-white">{{ statsSummary.availableCredit }}</span>
      </div>
      <div
        class="
          rounded
          p-3
          px-4
          pr-5
          gap-2
          rounded-pill
          green-bg
          top-gain-status-box
        "
      >
        <div class="top-gain-status-text">
          {{ $t("stats.cashOnHand") }}
        </div>
        <span class="text-white">{{ statsSummary.cashOnHand }}</span>
      </div>
      <div
        class="rounded p-3 px-4 pr-10 rounded-pill blue-bg top-gain-status-box"
      >
        <div class="top-gain-status-text">
          {{ $t("stats.payout") }}
        </div>
        <span class="text-white">{{ statsSummary.payOut }}</span>
      </div>
      <div
        class="
          rounded
          p-3
          px-4
          pr-10
          rounded-pill
          bg-white
          shadow
          top-gain-threshold-box
        "
      >
        <div class="rightnav-trigger">{{ $t("threshold") }}</div>
        <span> | </span>
        <LocaleSwitcher />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useStats } from "@/store/stats.store";
import LocaleSwitcher from "../LocaleSwitcher.vue";

const statsSummary = useStats();
onMounted(() => {
  statsSummary.fetch();
});
</script>