<template>
  <div class="row">
    <div class="col-12"><TopGainStatus @onClick="showModal" /></div>
  </div>
  <div class="row">
    <div
      class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 center-holder-box"
    >
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("sidebar.TransactionLogs") }}</div>
          </div>
          <div class="tab-content p-4">
            <div class="row pb-3 filter-container">
              <div class="col-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pd-r">
                <div class="row align-items-center">
                  <label class="col-md-4 text-left"
                    >{{ $t("transactionno") }}:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="cash-text card-text mr-0"
                      id="transactionNumber"
                      v-model="transactionNumber"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pd-l">
                <div class="row align-items-center">
                  <label class="col-md-4 text-left">{{ $t("fightno") }}:</label>
                  <div class="col-md-8">
                    <input
                      type="text"
                      class="cash-text card-text mr-0 w-50"
                      id="fightNo"
                      v-model="fightNo"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pd-r">
                <div class="row align-items-center">
                  <label class="col-md-4">{{ $t("betoption") }}:</label>
                  <div class="col-md-8">
                    <select class="card-select w-50 mr-0" v-model="betOption">
                      <option value="0" selected>{{ $t("all") }}</option>
                      <option value="1">{{ $t("red") }}</option>
                      <option value="2">{{ $t("blue") }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pt-3 pd-l">
                <div class="row align-items-center">
                  <label class="col-md-4 text-left"
                    >{{ $t("transactiontype") }}:</label
                  >
                  <div class="col-md-8">
                    <select
                      class="card-select w-100 mr-0"
                      v-model="transactionType"
                    >
                      <option value="0" selected>{{ $t("all") }}</option>
                      <option
                        v-for="(row, index) in transactionTypesList"
                        :key="index"
                        :value="row.TransactionTypeId"
                      >
                        {{
                          $t("transactiontypes." + row.Name.replace(/\s/g, ""))
                        }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pt-3 pd-r">
                <div class="row align-items-center">
                  <label class="col-md-4 text-left"
                    >{{ $t("paymentmethod") }}:</label
                  >
                  <div class="col-md-8">
                    <select
                      class="card-select w-100 mr-0"
                      v-model="paymentMethod"
                    >
                      <option value="0" selected>{{ $t("all") }}</option>
                      <option
                        v-for="(row, index) in paymentMethodsList"
                        :key="index"
                        :value="row.PaymentGatewayId"
                      >
                        {{ $t("paymentmethods." + row.Name) }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 pt-3 pd-l">
                <div class="row align-items-center">
                  <label class="col-md-4">{{ $t("fightresults") }}:</label>
                  <div class="col-md-8">
                    <select
                      class="card-select w-100 mr-0"
                      v-model="fightresults"
                    >
                      <option value="0" selected>{{ $t("all") }}</option>
                      <option value="1">{{ $t("red") }}</option>
                      <option value="2">{{ $t("blue") }}</option>
                      <option value="5">{{ $t("cancelled") }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12 pt-3 text-right">
                <button
                  class="bet-btn bet-btn-orange tvl-btn w-20"
                  @click="DisplayData"
                >
                  {{ $t("button.generate") }}
                </button>
              </div>
            </div>
            <tablecontent
              v-if="tableData"
              :theData="tableData"
              @custom-print="showReceipt"
              :cols="cols"
              :rowloading="rowloading"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 p-0 w-100">
        <div class="tabs-holder-outer">
          <div class="row">
            <div class="col-12 col-md-12 text-right mob-align w-100 p-0 pt-3">
              <Pagination
                v-if="tableData"
                :totalRecords="totalRecords"
                :perPageOptions="perPageOptions"
                v-model="pagination"
                @custom-change="handleCustomChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3
        dsp-none
        right-nav-hider
      "
      :class="{
        'rightbar--open': open2 === true,
      }"
    >
      <div class="text-left">
        <img
          src="../assets/img/close_box.svg"
          class="close-slider"
          @click="closeBox"
        />
      </div>
      <RightNav />
    </div>
  </div>
  <Modal
    :status="status"
    :status2="status2"
    v-if="isShowModal"
    @close="close"
    v-model:threshold="threshold"
    @submitThresholdAction="submitThresholdActions"
  >
  </Modal>
  <sweet-modal ref="modalError" icon="error">
    {{ errorMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
  <sweet-modal ref="modalSuccess" icon="success">
    {{ successMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
</template>

<script>
import tablecontent from "@/components/table/TableItems";
import TopGainStatus from "../components/stats/TopGain.vue";
import Pagination from "@/components/table/PaginationItems.vue";
import Modal from "../components/modal/InputModal.vue";
import { useTransactionStore } from "@/store/transaction.store";
import { useThresholdStore } from "@/store/threshold.store";
import { useLicenseStatus } from "@/store/license.store";
import { useAuthStore } from "@/store/user.store";
import { useStats } from "@/store/stats.store";
import RightNav from "../components/RightNav";
const perPageOptions = 10;

export default {
  name: "TransactionLog",
  props: ["open2"],
  emits: ["closeBoxz"],
  components: { TopGainStatus, tablecontent, Pagination, Modal, RightNav },
  data() {
    return {
      perPageOptions,
      pagination: { page: 1, perPage: 10 },
      state: "top",
      status: "TransactionLogs",
      status2: false,
      dataItemId: "",
      tableData: [],
      filter: "",
      typeFilter: [],
      arr: [],
      transactionType: 0,
      fightNo: "",
      betOption: 0,
      fightresults: 0,
      transactionNumber: "",
      paymentMethod: 0,
      isShowModal: false,
      successMessage: "",
      errorMessage: "",
      threshold: null,
      intStart: 0,
      intLimitPerPage: 10,
      totalRecords: 0,
      rowloading: "Loading...",
      transactionTypesList: JSON.parse(
        localStorage.getItem("transactionTypes") ?? "[]"
      ),
      paymentMethodsList: JSON.parse(
        localStorage.getItem("paymentMethods") ?? "[]"
      ),
      cols: [
        { key: "TransactionDateTime", title: "transaction-date", type: "date" },
        { key: "TransactionNo", title: "transactionno", type: "text" },
        { key: "BetOption", title: "betoption", type: "text" },
        { key: "FightNumber", title: "fightno", type: "text" },
        { key: "TransactionType", title: "transactiontype", type: "text" },
        { key: "PaymentMethod", title: "paymentmethod", type: "text" },
        {
          key: "TransactionAmount",
          title: "transaction-amount",
          type: "amount",
        },
        { key: this.$t("odds"), title: "odds", type: "odds" },
        { key: "PlaceBetStatus", title: "place-bet-status", type: "text" },
        { key: "FightResult", title: "fightresults", type: "text" },
        {
          key: "AvailableCredits",
          title: "stats.availableCredits",
          type: "amount",
        },
        { key: "CashOnHand", title: "cash-on-hand", type: "amount" },
        { key: "TransactionNo", title: "action", type: "button" },
      ],
    };
  },
  computed: {
    computedTableData() {
      if (!this.tableData) return [];
      else {
        const firstIndex = (this.pagination.page - 1) * this.pagination.perPage;
        const lastIndex = this.pagination.page * this.pagination.perPage;
        return this.tableData.slice(firstIndex, lastIndex);
      }
    },
  },
  methods: {
    closeBox() {
      this.$emit("closeBoxz");
    },
    getData() {
      this.rowloading = "Loading...";
      let data = {};
      data = {
        transactionType: this.transactionType,
        fightno: this.fightNo,
        betOption: this.betOption,
        transactionNumber: this.transactionNumber,
        paymentMethod: this.paymentMethod,
        intStart: this.intStart,
        intLimitPerPage: this.intLimitPerPage,
        intFightResult: this.fightresults,
      };

      const action = useTransactionStore();
      action.getTransactionData(data);
      action.response.then((result) => {
        if (result.status === "success") {
          this.tableData = result.data;
          this.pagination.perPage =
            result.meta.pagination.per_page == null
              ? 10
              : result.meta.pagination.per_page;
          this.pagination.pages =
            result.meta.pagination.total_pages == null
              ? 0
              : result.meta.pagination.total_pages;
          this.pagination.totalRecords =
            result.meta.pagination.total == null
              ? 0
              : result.meta.pagination.total;
          this.pagination.perPageOptions =
            result.meta.pagination.total_pages == null
              ? 0
              : result.meta.pagination.total_pages;
          this.perPageOptions =
            result.meta.pagination.per_page == null
              ? 10
              : result.meta.pagination.per_page;
          this.totalRecords = this.pagination.totalRecords;
        } else {
          this.tableData = [];
          this.pagination.pages = 0;
          this.pagination.totalRecords = 0;
          this.pagination.perPageOptions = 0;
          this.totalRecords = 0;
          this.rowloading = "No result found";
          if (
            result.data.ErrorCode == 10000070 ||
            result.data.ErrorCode == "10000092"
          ) {
            this.errorMessage = this.$t("error.login." + result.data.ErrorCode);
            this.$refs.modalError.open();
            localStorage.removeItem("auth");
            setTimeout(() => window.location.reload(), 6500);
          }
        }
      });
    },
    handleCustomChange(s) {
      this.pagination.page = s.page;
      this.pagination.perPage = s.perPage;
      // this.intStart = (s.page - 1) * s.perPage;
      this.intStart = s.page - 1;
      this.getData();
    },
    DisplayData() {
      this.pagination.page = 1;
      this.page = 1;
      this.intStart = 0;
      this.$emit("changeNum", 1);
      this.getData();
    },
    closeModal() {
      this.$refs.modalSuccess.close();
      this.$refs.modalError.close();
    },
    close() {
      this.isShowModal = false;
      this.status2 = false;
    },
    callModal() {
      this.isShowModal = true;
      this.status2 = false;
    },
    showModal() {
      this.isShowModal = true;
      this.status2 = true;
      this.threshold = null;
    },
    showReceipt(id) {
      sessionStorage.removeItem("barcode");
      const action = useTransactionStore();
      action.getTransactionReceipt(id);
      const license = useLicenseStatus();
      license.getLicense();
      action.response.then((result) => {
        if (result.status === "success") {
          this.arr.push(result.data);
          localStorage.setItem("module", JSON.stringify("TransactionReceipt"));
          localStorage.setItem("TransactionReceipt", JSON.stringify(this.arr));
          if (result.data.Barcode !== undefined) {
            localStorage.setItem(
              "barcode",
              JSON.stringify(result.data.Barcode)
            );
          }
          this.arr = [];
          this.$router.push({ name: "ReprintReceipt" });
        } else {
          if (
            result.data.ErrorCode == "10000092" ||
            result.data.ErrorCode == 10000070
          ) {
            this.errorMessage = this.$t("error.login." + result.data.ErrorCode);
            var auth = useAuthStore();
            setTimeout(() => auth.logout(), 3000);
          } else {
            this.errorMessage = this.$t("error." + result.data.ErrorCode);
          }
          this.$refs.modalError.open();
          setTimeout(() => this.$refs.modalError.close(), 5000);
        }
      });
    },
    submitThresholdActions() {
      if (this.threshold > 0) {
        const action = useThresholdStore();
        action.submitThresholdAmount(this.threshold);
        const license = useLicenseStatus();
        license.getLicense();
        action.response.then((result) => {
          if (result.status === "success") {
            this.successMessage = this.$t(
              "your-request-submitted-successfully"
            );
            this.$refs.modalSuccess.open();
            setTimeout(() => this.$refs.modalSuccess.close(), 5000);
            this.isShowModal = false;
          } else {
            if (
              result.data.ErrorCode == "10000092" ||
              result.data.ErrorCode == 10000070
            ) {
              this.errorMessage = this.$t(
                "error.login." + result.data.ErrorCode
              );
              var auth = useAuthStore();
              setTimeout(() => auth.logout(), 3000);
            } else {
              this.errorMessage = this.$t("your-request-failed");
            }
            this.$refs.modalError.open();
            setTimeout(() => this.$refs.modalError.close(), 5000);
          }
        });
      } else {
        this.errorMessage = this.$t("please-make-sure-to-enter-amount");
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
    },
  },
  mounted: function () {
    const license = useLicenseStatus();
    license.getLicense();
    const stats = useStats();
    stats.refresh();
    setTimeout(() => this.getData(), 100);
    document.body.removeAttribute("style");
    document.getElementById("favicon").href = localStorage.getItem("icon");
  },
};
</script>
