import { ref } from "vue";

import { defineStore } from "pinia";
import { PaymentMethods } from "@/api";
import { TransactionTypes, getPusherCreds } from "@/api";
import Pusher from "pusher-js";
import { useAuthStore } from "@/store/user.store";
import { useStats } from "@/store/stats.store";
import { getActiveSite, isActiveSite } from "@/helpers/activesite";

export const useAlertStore = defineStore({
  id: "alert",
  state: () => ({
    alert: null,
  }),
  actions: {
    success(message) {
      this.alert = { message, type: "alert-success" };
    },
    successCode(code, data) {
      this.alert = { code: code, data, type: "alert-success" };
    },
    error(message) {
      this.alert = { message, type: "alert-danger" };
    },
    errorCode(code, data) {
      this.alert = { code: code, data, type: "alert-danger" };
    },
    info(message) {
      this.alert = { message, type: "alert-info" };
    },
    infoCode(code, data) {
      this.alert = { code: code, data: data, type: "alert-info" };
    },
    clear() {
      this.alert = null;
    },
    close() {
      setTimeout(() => {
        this.clear();
      }, 200);
    },
  },
});

const ttl = 3600;

export const useStructure = defineStore({
  id: "structure",
  state: () => ({
    lastFetched: null,
    paymentMethods: ref([]),
    transactionTypes: ref([]),
  }),
  getters: {
    getPaymentMethod() {
      return this.paymentMethods;
    },
    getTransactionTypes() {
      return this.transactionTypes;
    },
  },
  actions: {
    fetch() {
      if (
        this.lastFetched == null ||
        this.lastFetched > new Date().getTime() - ttl
      ) {
        this.refresh();
      }
      return this.state;
    },
    async refresh() {
      let rPaymentMethods = await PaymentMethods();
      if (rPaymentMethods.status == "success") {
        this.$patch((state) => {
          state.paymentMethods = rPaymentMethods.data;
          state.lastFetched = new Date().getTime();
        });
        localStorage.setItem(
          "paymentMethods",
          JSON.stringify(rPaymentMethods.data)
        );
      }
      let rTransactionTypes = await TransactionTypes();
      if (rTransactionTypes.status == "success") {
        localStorage.setItem(
          "transactionTypes",
          JSON.stringify(rTransactionTypes.data)
        );
      }
    },
  },
});

export const fightEvents = defineStore({
  id: "fight-events",
  state: () => ({
    StatusName: null,
  }),
  getters: {
    getStatusName() {
      return this.StatusName;
    },
  },
});

export const getMachineId = function () {
  let machineId = localStorage.getItem("MachineId");

  if (!machineId) {
    machineId = crypto.randomUUID();
    localStorage.setItem("MachineId", machineId);
  }

  return machineId;
};

export const realtimeEvents = {
  async listenToUserEvents(userInfo) {
    const alert = useAlertStore();
    const pusherPrefix = isActiveSite("CASHIER") ? "user" : getActiveSite();
    let res = await getPusherCreds();
    const $pusher = new Pusher(res.key, {
      cluster: res.cluster,
    });

    $pusher.subscribe(pusherPrefix + userInfo.AccountId);
    $pusher.bind("login", (data) => {
      if (data.data.ClientUniqueId !== getMachineId()) {
        $pusher.unsubscribe(pusherPrefix + userInfo.AccountId);
        $pusher.unbind_all();
        alert.errorCode("error.login.10000093");
        const auth = useAuthStore();
        auth.logout();
      }
    });
    $pusher.bind("credit-request-approved", (data) => {
      let statsSummary = useStats();
      statsSummary.refresh();
      // console.log(data.data);
      if (data.data.Status === "Approved") {
        let amt = data.data.totalAmountApproved;
        data.data.totalAmountApproved = amt;
        if (!isNaN(amt)) {
          data.data.totalAmountApproved =
            Number(amt)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
        }
        alert.infoCode("credit-request-approved-new-balance", data.data);
      } else {
        data.data.totalAmountRequested =
          Number(data.data.totalAmountRequested)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
        alert.errorCode("credit-request-rejected-amount", data.data);
      }
    });
    // Fight events
    let fightStatus = fightEvents();
    $pusher.subscribe("streaming");
    $pusher.bind("realtime-update", (data) => {
      fightStatus.$patch((state) => {
        state.StatusName = data.data.StatusName;
        // if (data.data.Status == 5) {
        //   state.StatusName = "FIGHT CANCELLED"
        // }
        //if (data.data.NextVideoFileName == '' && data.data.Status != 1) {
        //  state.StatusName = null;
        //}
        if (data.data.FinalResultName != "") {
          state.StatusName = data.data.FinalResultName;
        }
        if (data.data.FinalResult == 1) {
          state.StatusName = "RED WINS";
        }
        if (data.data.FinalResult == 2) {
          state.StatusName = "BLUE WINS";
        }
      });
    });
    $pusher.bind("state-change", (data) => {
      fightStatus.$patch((state) => {
        if (data.state == "COMPLETED") {
          return;
        }
        state.StatusName = data.data[0].StatusName;
        // if (data.data[0].Status == 5) {
        //   state.StatusName = "FIGHT CANCELLED"
        // }
        //if (data.data[0].NextVideoFileName == '' && data.data[0].Status != 1) {
        //  state.StatusName = null;
        //}
        if (data.data[0].FinalResult == 1) {
          state.StatusName = "RED WINS";
        }
        if (data.data[0].FinalResult == 2) {
          state.StatusName = "BLUE WINS";
        }
        // console.log(state, data.data[0]);
      });
    });
  },
};
