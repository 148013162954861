<template>
  <div class="row aligns-items-center justify-content-center">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12 center-holder-box pe-4">
      <div class="lang-switcher-outer hide-elem">
        <div class="notif-holder">
          <div class="position-relative bell-holder" @click="showNotif()">
            <i class="fas fa-bell"></i>
            <span
              class="
                position-absolute
                top-0
                start-100
                translate-middle
                badge
                rounded-pill
                bg-danger
              "
            >
              1
            </span>
          </div>
          Saturday, September 17
        </div>
        <div class="lang-switcher-holder">
          <LocaleSwitcher />
        </div>
      </div>
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("summaryreports") }}</div>
          </div>
          <div class="tab-content">
            <div class="row">
              <div class="col-12 col-sm-6 hide-elem">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <label class="form-label"
                      >{{ $t("label.BettingShop") }}:</label
                    >
                    <Select2
                      v-model="myValue"
                      :options="bettingShopOptions"
                      :settings="{ minimumResultsForSearch: -1 }"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">{{ $t("date") }}:</label>
                    <input type="date" class="form-control agent-text" />
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 hide-elem">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <label class="form-label">{{ $t("cashier") }}:</label>
                    <Select2
                      v-model="myValue"
                      :options="cashierOptions"
                      :settings="{ minimumResultsForSearch: -1 }"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Status:</label>
                    <Select2
                      v-model="myValue"
                      :options="statusOptions"
                      :settings="{ minimumResultsForSearch: -1 }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 mt-4 mb-4 hide-elem">
                <div class="row">
                  <div class="col-6"></div>
                  <div class="col-6 px-4">
                    <div class="row">
                      <div class="col-4 px-1">
                        <button type="button" class="rs-btn rs-gen active">
                          {{ $t("button.generatereport") }}
                        </button>
                      </div>
                      <div class="col-4 px-1">
                        <button
                          type="button"
                          class="rs-btn rs-dl active"
                          @click="generatePDF"
                        >
                          {{ $t("button.download") }}
                        </button>
                      </div>
                      <div class="col-4 px-1">
                        <button
                          type="button"
                          class="rs-btn rs-print active"
                          @click="printPreview"
                        >
                          {{ $t("button.reprintreport") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <vue3-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="1400"
                filename="Summary Reports"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="landscape"
                pdf-content-width="100%"
                @progress="onProgress($event)"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
                ref="html2Pdf"
              >
                <template v-slot:pdf-content>
                  <ContentToDownloadCollector :dataList="dataList" />
                </template>
              </vue3-html2pdf>

              <div class="col-12 col-sm-12 mb-3" id="printTable">
                <table
                  class="table table-striped table-hover dtable-border"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>{{ $t("label.BettingShop") }}</th>
                      <th class="cr-tw">{{ $t("label.DateTime") }}</th>
                      <th>{{ $t("label.TransactionNo") }}</th>
                      <th>{{ $t("label.CashierName") }}</th>
                      <th class="text-right">
                        {{ $t("label.PreviousBalance") }}
                      </th>
                      <th class="text-right">Total Bets</th>
                      <th class="text-right">Payouts</th>
                      <th class="text-right">Commissions</th>
                      <th class="text-right">For Collection</th>
                      <th class="text-right">
                        {{ $t("label.CollectedAmount") }}
                      </th>
                      <th class="text-center">{{ $t("label.Status") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="index" v-for="(item, index) in dataList">
                      <td>
                        {{ item.bettingShop + " " + parseInt(index + 1) }}
                      </td>
                      <td>{{ item.date }}</td>
                      <td>{{ item.transactionNo + parseInt(index + 1) }}</td>
                      <td>
                        {{ item.cashierName + " " + parseInt(index + 1) }}
                      </td>
                      <td class="text-right">{{ item.prevBalance }}</td>
                      <td class="text-right">{{ item.totalBet }}</td>
                      <td class="text-right">{{ item.payouts }}</td>
                      <td class="text-right">{{ item.commission }}</td>
                      <td class="text-right">{{ item.collection }}</td>
                      <td class="text-right">{{ item.collectedAmount }}</td>
                      <td class="text-center">{{ item.status }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Pagination />
      </div>
    </div>
  </div>
  <Notification :notif="notif" v-if="isShowNotif" @close="close"></Notification>
  <NotificationDetails
    :status="status"
    v-if="isShowDetails"
    @close="close"
  ></NotificationDetails>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import Notification from "../components/modal/NotificationList";
import NotificationDetails from "../components/modal/NotificationDetails";
import Vue3Html2pdf from "vue3-html2pdf";
import ContentToDownloadCollector from "../components/ContentToDownloadCollector";
import Select2 from "vue3-select2-component";
import Pagination from "../components/Pagination";
export default {
  name: "ReportSummary2",
  components: {
    Notification,
    NotificationDetails,
    Pagination,
    Select2,
    LocaleSwitcher,
    Vue3Html2pdf,
    ContentToDownloadCollector,
  },
  data() {
    return {
      isShowModal: false,
      isShowDetails: false,
      isShowNotif: false,
      status: "",
      notif: "",
      myValue: "",
      bettingShopOptions: [
        "Betting Shop 1",
        "Betting Shop 2",
        "Betting Shop 3",
      ], // or [{id: key, text: value}, {id: key, text: value}]
      cashierOptions: ["Cashier 1", "Cashier 2", "Cashier 3"], // or [{id: key, text: value}, {id: key, text: value}]
      statusOptions: ["Paid", "Unpaid"], // or [{id: key, text: value}, {id: key, text: value}]
      filterByOption: [
        { id: 1, text: "Date" },
        { id: 2, text: "Event" },
      ],
      filterVal: 1,
      dataList: [
        {
          bettingShop: "Betting Shop",
          date: "06/30/2021 3:00:00 PM",
          transactionNo: "1233445",
          cashierName: "Cashier",
          prevBalance: "123,000.00",
          totalBet: "1,000,000.00",
          payouts: "852,000.00",
          commission: "2,500.00",
          collection: "268,500.00",
          collectedAmount: "268,000.00",
          status: "Fully Paid",
        },
        {
          bettingShop: "Betting Shop",
          date: "06/30/2021 3:00:00 PM",
          transactionNo: "1233445",
          cashierName: "Cashier",
          prevBalance: "--",
          totalBet: "--",
          payouts: "--",
          commission: "--",
          collection: "--",
          collectedAmount: "--",
          status: "Unpaid",
        },
        {
          bettingShop: "Betting Shop",
          date: "06/30/2021 3:00:00 PM",
          transactionNo: "1233445",
          cashierName: "Cashier",
          prevBalance: "6,500.00",
          totalBet: "750,000.00",
          payouts: "453,000.00",
          commission: "1,600.00",
          collection: "301,900.00",
          collectedAmount: "301,900.00",
          status: "Partial Paid",
        },
        {
          bettingShop: "Betting Shop",
          date: "06/30/2021 3:00:00 PM",
          transactionNo: "1233445",
          cashierName: "Cashier",
          prevBalance: "4,600.00",
          totalBet: "100,000.00",
          payouts: "55,000.00",
          commission: "250.00",
          collection: "49,350.00",
          collectedAmount: "49,350.00",
          status: "Paid",
        },
        {
          bettingShop: "Betting Shop",
          date: "06/30/2021 3:00:00 PM",
          transactionNo: "1233445",
          cashierName: "Cashier",
          prevBalance: "--",
          totalBet: "--",
          payouts: "150,000.00",
          commission: "150.00",
          collection: "99,850.00",
          collectedAmount: "99,850.00",
          status: "Unpaid",
        },
      ],
    };
  },
  methods: {
    close() {
      this.isShowModal = false;
      this.status = "";
      this.notif = "";
    },
    showNotif() {
      this.isShowNotif = true;
    },
    hasGenerated() {
      this.successMessage = "PDF generated successfully!";
      this.$refs.modalSuccess.open();
      setTimeout(() => this.$refs.modalSuccess.close(), 5000);
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    printPreview() {
      document.getElementById("printTable").innerHTML;
      window.focus();
      window.print();
    },
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },
  },
};
</script>

<style>
@import "bootstrap";
@import "datatables.net-bs5";
@media print {
  .hide-elem,
  .sidebar-holder-outer {
    display: none;
  }
  .tabs-holder-outer {
    display: block;
    width: 1160px;
    margin-left: 30px;
  }
  #printTable {
    display: block;
    width: 100%;
    margin: 0 auto;
    float: none;
  }

}
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after {
  display: none !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  display: flex;
  margin: 2px 0 !important;
  margin-top: 15px !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination li {
  padding: 0;
  text-decoration: none;
  background: none;
  border-radius: 0;
  font-weight: normal;
}
div.dataTables_wrapper div.row div.col-md-6:first-child {
  display: none;
}
.dataTables_filter label {
  display: block;
}

.dtable-input {
  width: 89px;
  height: 35px;
  border: 1px solid #c9c9c9;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
}

.dtable-input2 {
  width: 89px;
  height: 35px;
  border: 1px solid #707070;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
  display: inline-block;
}
.dtable-btn {
  width: 68px;
  height: 35px;
  background: #23ad0e;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: -1px;
}
.dtable-btn2 {
  width: 116px;
  height: 35px;
  background: #35469a;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
}
.top-input {
  width: 122px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
  text-align: right;
  display: inline-block;
}
.top-apply-btn {
  width: 107px;
  height: 40px;
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2559;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
.ds-block {
  display: block;
}
.update-share-holder {
  display: inline-block;
  text-align: left;
}
.top-search {
  width: 241px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
}
table.dataTable td {
  vertical-align: middle;
}
table.dataTable th {
  vertical-align: top;
}
.dtable-text:focus {
  box-shadow: none;
  border: 1px solid #707070;
}
.tab-content {
  padding-bottom: 2% !important;
  padding-top: 2% !important;
}
.page-link.active,
.active > .page-link {
  background-color: var(--violet);
  border-color: var(--violet);
}
.rs-btn {
  width: 100%;
  font-size: 16px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  color: #656565;
  background: #efefef;
  box-shadow: none;
  border: none;
}
.rs-gen.active {
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2570;
  color: #fff;
}
.rs-dl.active {
  background: #0081ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0081ff59;
  color: #fff;
}
.rs-print.active {
  background: #2cb742 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2cb74259;
  color: #fff;
}
.dtable-border {
  border-top: 1px solid #efefef;
}
.table th,
.table td {
  vertical-align: middle;
}
.cr-tw {
  width: 120px;
}
</style>