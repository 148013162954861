import { defineStore } from 'pinia';
import { placeBetSubmit } from '@/api';

export const useActionStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: 'actionStore',
  state: () => {
    return {
      response: null,
    };
  },

  actions: {
    submitPlaceBet(data) {
      let betResult = placeBetSubmit(data);
      this.response = betResult;
    }
  },
});
