<template>
  <a
    class="nav-link dropdown-toggle rightnav-trigger"
    href="#"
    id="navbarDarkDropdownMenuLink"
    role="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <div class="rightnav-trigger text-uppercase">
      {{ locale }}
    </div>
  </a>

  <ul
    class="dropdown-menu dropdown-menu-light"
    aria-labelledby="navbarDarkDropdownMenuLink"
  >
    <template v-for="lang in ['en', 'es', 'pt']" :key="lang">
      <li class="px-0 last-type-border" v-if="lang != locale" @click="switchlang(lang)">
        <a class="dropdown-item px-3"  href="#">{{ $t("language." + lang) }}</a>
      </li>
    </template>
  </ul>
</template>
<script setup>
import { useI18n } from "vue-i18n";
const { locale } = useI18n({ useScope: "global" });
const emit = defineEmits(['switchLang']);

const switchlang = (val) => {
  locale.value = val;
  localStorage.setItem("lang", locale.value);
  emit("switchLang", val);

};
</script>
<style scoped>
.dropdown-menu {
  border: none;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}
.dropdown-item{
  border-radius: 5px;
  padding:10px 20px;
  
}
.last-type-border:last-of-type > a.dropdown-item {
  border-bottom: 0px !important;
}
</style>
