<template>
  <!-- <section class="h-100 login-section" v-bind:class="isMobile()"> -->
  <section class="h-100 login-section">
    <div class="h-100 logo-bg-cockpit"></div>
    <div class="container login-cont">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-xl-12 login-cont-inner">
          <div class="">
            <div class="row g-0 mob-holder">
              <div class="col-12 mob-log-logo">
                <img :src="logo" alt="logo" class="login-logo" v-if="logo" />
              </div>
              <div
                class="
                  login-form-container
                  card
                  rounded-3
                  col-lg-6
                  position-relative
                "
              >
                <transition name="bounce"
                  ><CircleSpinner :active="inProgress"></CircleSpinner
                ></transition>
                <div
                  v-if="auth.isAnonymous"
                  class="card-body p-md-4 mx-md-4 login-padd p-lg-5"
                >
                  <div class="text-left">
                    <span class="float-right login-lang"
                      ><LocaleSwitcher
                    /></span>
                    <h4 class="mt-1 mb-5 pb-1 font-bold fz-30">
                      {{ $t("siteNames." + siteName) }}
                      <span
                        v-if="userMode == 'reset'"
                        class="text-red-resetpassword font-bold"
                        >{{ $t("button.resetpassword") }}</span
                      >
                    </h4>
                  </div>
                  <transition name="bounce">
                    <PasswordReset
                      v-if="userMode == 'reset'"
                      :resetCode="resetCode"
                    >
                      <button
                        class="
                          btn btn-lg btn-custom btn-outline-primary btn-block
                          fw-700
                          w-100
                          d-none
                        "
                        @click="setMode('login')"
                      >
                        {{ $t("back") }}
                      </button>
                    </PasswordReset>
                    <form
                      v-else
                      :class="{ shake: shakeState }"
                      @submit.prevent="handleSubmit"
                    >
                      <div class="form-outline mb-5 mob-form">
                        <label class="form-label">{{ $t("username") }}</label>
                        <div class="ginput_container ginput_container_text">
                          <input
                            type="username"
                            class="form-control username"
                            v-model="credentials.username"
                          />
                          <span class="line-div">|</span>
                        </div>
                      </div>
                      <div class="form-outline mb-1">
                        <label class="form-label">{{ $t("password") }}</label>
                        <div class="field has-addons">
                          <div class="control is-expanded">
                            <div
                              class="
                                ginput_container ginput_container_text
                                password
                              "
                            >
                              <input
                                v-if="showPassword"
                                type="text"
                                class="form-control input password"
                                v-model="credentials.password"
                              />
                              <input
                                v-else
                                type="password"
                                class="form-control input password"
                                v-model="credentials.password"
                              />
                              <span class="line-div">|</span>
                            </div>
                          </div>
                          <div class="control">
                            <span class="login-password-eye" @click="toggleShow"
                              ><span class="icon is-small is-right">
                                <i
                                  class="fas"
                                  :class="{
                                    'fa-eye': showPassword,
                                    'fa-eye-slash': !showPassword,
                                  }"
                                ></i
                              ></span>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="form-outline mb-5 col-12 login-form-forgot">
                        <span class="c-black" v-on:click="showModal">{{
                          $t("forgotPassword")
                        }}</span>
                      </div>
                      <div class="form-outline mb-4 col-12 mt-30">
                        <button
                          type="submit"
                          id="submit"
                          class="
                            btn btn-lg btn-custom btn-danger btn-block
                            fw-700
                            w-100
                          "
                        >
                          {{ $t("login") }}
                        </button>
                      </div>
                    </form>
                  </transition>
                </div>
                <div v-else class="card-body p-md-4 py-5 mx-md-4">
                  <h2 style="min-height: 200px" class="my-5">
                    {{ $t("hi", { name: auth.fullname }) }}
                  </h2>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="auth.logout"
                  >
                    {{ $t("logout") }}
                  </button>
                </div>
              </div>
              <div
                class="col-lg-6 d-flex align-items-center login-logo-container"
              >
                <img :src="logo" alt="logo" class="login-logo" v-if="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Modal
    :status="''"
    v-if="isShowModal"
    :title="'resetpassword.recoverpassword'"
    @close="close"
    @submitEmailResetPassword="handleEmailResetPassword"
    :isLoading="loadingRequestEmail"
  >
  </Modal>
</template>

<script setup>
/**
 * Test user accunts from dummy json
 * https://gist.github.com/dionedomingo/d82e9f72c668ee58df0bb13a045d8131
 *
 * TODO: delete this note after real api is implemented.
 */
import { ref, onMounted, inject } from "vue";
import { useAuthStore } from "@/store/user.store";
import { useLicenseStatus } from "@/store/license.store";
import CircleSpinner from "../components/misc/CircleSpinner.vue";
import PasswordReset from "../components/user/PasswordReset.vue";

import router from "../router";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import Modal from "../components/modal/InputModal";
import { resetPassword } from "../api";
import { useAlertStore } from "@/store/global";
const auth = useAuthStore();
const alert = useAlertStore();
const license = useLicenseStatus();
const inProgress = ref(false);
const shakeState = ref(false);
const resetCode = ref("");
const successMessage = ref("");
const loadingRequestEmail = ref(false);
const logo = ref("");
const siteName = inject("activeSite", "CASHIER");

onMounted(() => {
  if (
    router.currentRoute.value.name == "resetcode" ||
    router.currentRoute.value.name == "forgot"
  ) {
    userMode.value = "reset";
    resetCode.value = router.currentRoute.value.params.resetcode;
    if (router.currentRoute.value.name == "forgot") {
      resetCode.value = "forgot";
    }
  }

  getLogo();
});

const userMode = ref("login");
auth.verifyLocalStorage(localStorage.getItem("auth"));

const credentials = ref({
  username: "",
  password: "",
});

const showPassword = ref(false);
const isShowModal = ref(false);
const status2 = ref(false);

function getLogo() {
  let action = license.getLogo();
  action.then((result) => {
    logo.value = result;
  });
}

function close() {
  isShowModal.value = false;
  status2.value = false;
}

function showModal() {
  isShowModal.value = true;
}

function toggleShow() {
  showPassword.value = !showPassword.value;
}

function handleSubmit() {
  inProgress.value = true;
  let stuff = auth.login(credentials.value);
  stuff.then((success) => {
    inProgress.value = false;
    if (!success) {
      shakeIt();
      if(sessionStorage.getItem("ccode")){
        alert.errorCode(sessionStorage.getItem("ccode"));
      }
    } else {
      successMessage.value = sessionStorage.getItem("ccode");
      // alert.successCode(sessionStorage.getItem("ccode"));
    }
  });
}

function setMode(newMode) {
  userMode.value = newMode;
  if (newMode == "login") {
    router.push({ name: "login" });
  }
}

function shakeIt() {
  shakeState.value = true;
  setTimeout(() => {
    shakeState.value = false;
  }, 500);
}

async function handleEmailResetPassword(email) {
  if (email == null) return alert.errorCode("enterEmail");
  if (email.trim() === "") return alert.errorCode("enterEmail");
  if (!auth.validateEmail(email)) return alert.errorCode("enterValidEmail");

  loadingRequestEmail.value = true;
  const license = useLicenseStatus();
  let logo = await license.getLogo();
  let res = await resetPassword(email, logo);

  if (res.status === "success") {
    alert.successCode("resetpassword.resetcodesent");
    isShowModal.value = false;
  } else {
    alert.errorCode("error.login.10000121");
  }
  loadingRequestEmail.value = false;
}
</script>

<style>
div.login-form-container .rightnav-trigger {
  background: transparent !important;
  color: #fff;
  border: none;
  width: 20px;
  margin: 0 auto;
  border: none !important;
}
div.login-form-container .rightnav-trigger::after {
  display: none;
}
div.login-form-container .rightnav-trigger:hover > ::after {
  display: block;
}
</style>
<style scoped>
.float-right {
  float: right;
}
.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 35%;
  background-color: rgba(0, 0, 0, 0.2);
}
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

.text-red-resetpassword {
  color: #e84e25;
  font-size: 24px;
}
.mob-log-logo {
  display: none;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
