import { defineStore } from 'pinia';
import { GetSummaryTransAmountByCashierId } from '@/api';
const ttl = 300;
export const useStats = defineStore({
  id: 'stats',
  state: () => {
    return {
      summary: {
        availableCredit: 0,
        cashOnHand: 0,
        payOut: 0,
      },
      lastFetched: null,
    };
  },
  getters: {
    availableCredit(state) {
      return (
        state.summary.availableCredit
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
      );
    },
    cashOnHand(state) {
      return (
        state.summary.cashOnHand
          ?.toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
      );
    },
    payOut(state) {
      return (
        state.summary.payOut
          ?.toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
      );
    },
    cashIn(state) {
      return (
        state.summary?.cashIn
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
      );
    },
  },
  actions: {
    fetch() {
      if (
        this.lastFetched == null ||
        this.lastFetched > new Date().getTime() - ttl
      ) {
        this.refresh();
      }
    },
    async refresh() {
      // console.log(this.lastFetched);
      let resultSummary = await GetSummaryTransAmountByCashierId();
      // console.log(resultSummary);
      if (resultSummary.status == 'success') {
        this.$patch((state) => {
          state.summary = resultSummary.data;
          state.lastFetched = new Date().getTime();
        });
      }else {
          if (resultSummary.data.ErrorCode == 10000070) {
            localStorage.removeItem("auth");
            setTimeout(() => window.location.reload(), 800);
          }
      }
    },
  },
});
