<template>
  <div class="row">
    <div class="col-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{$t('summaryreports')}}</div>
          </div>
          <div class="tab-content">
            <div
              class="container bet-cancel-data"
              v-bind:key="index"
              v-for="(data, index) in dataSummary"
            >
              <div class="row">
                <div class="col-sm">
                  <p class="text-left">{{ data.title }}</p>
                </div>
                <div class="col-sm">
                  <p class="text-right font-bold">{{ data.value }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentToPrint",
  props: ["dataSummary"],
};
</script>
