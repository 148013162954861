<template>
  <section class="h-100 login-section">
    <div class="logo-bg-cockpit"></div>
    <div class="container">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="text-white text-center d-flex justify-content-center align-items-center" style="height: 100vh">
          <div class="wrapper-license-expired">
            <div class="container-license-expired">
              <div class="license-expired-aside-logo align-items-center justify-content-center">
                <div class="container container-license-expired-logo">
                          <img class="logo image-thumbnail w-25" src="//ibk-public.s3.ap-southeast-1.amazonaws.com/images/12/1665747128.png">
                      </div>
                </div>
                  <div class="license-expired-aside-message align-items-center justify-content-center">
                    <div class="container container-license-expired-message animated fadeIn">
                      <h3 class="title fw-bold mb-3 mt-5">{{ $t('error.10000030') }}</h3>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CheckLicense",
}
</script>