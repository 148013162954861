<template>
  <div>
    <SideBarLogo />
    <SideBarUser />
    <SideBarNav />
    <LogOut />
  </div>
</template>

<script>
import SideBarLogo from "./SideBarLogo";
import SideBarUser from "./SideBarUser";
import SideBarNav from "./SideBarNav";
import LogOut from "./LogOut";

export default {
  components: { SideBarLogo, SideBarUser, SideBarNav, LogOut },
};
</script>