<template>
  <div
    :class="[
      'modal-backdrop',
      { 'z-index-0': title === 'resetpassword.recoverpassword' },
      isMobile(),
    ]"
  >
    <div
      class="modal"
      :class="{
        'response-modal':
          status === 'PlaceBet' ||
          status === 'CancelBet' ||
          status === 'ReprintReceipt' ||
          status === 'SummaryReports' ||
          status === 'CashEndorsement' ||
          status === 'CreditRequest',
        'response-modal2': status2 === true,
        'recovery-modal': title === 'resetpassword.recoverpassword',
      }"
    >
      <!----------------------THRESHOLD SET MODAL------------------------->
      <div
        class="placebet-cont"
        v-if="
          (status === 'PlaceBet' && status2 === true) ||
          (status === 'PayOut' && status2 === true) ||
          (status === 'CancelBet' && status2 === true) ||
          (status === 'SummaryReports' && status2 === true) ||
          (status === 'TransactionLogs' && status2 === true) ||
          (status === 'CreditRequest' && status2 === true)
        "
      >
        <p class="fz-20 text-center placebet-p text-left placebet-p2">
          Set Credit Threshold
        </p>
        <input
          type="text"
          class="cash-text card-text th-text"
          :value="threshold"
          @input="$emit('update:threshold', $event.target.value)"
          @keyup="InputNumberOnly"
        />
        <div class="text-center">
          <button
            type="button"
            class="btn btn-lg btn-primary btn-block place-bet-btn th-btn"
            @click="submitThreshold"
          >
            Yes
          </button>

          <button
            type="button"
            class="btn btn-lg btn-primary btn-block place-bet-no th-btn"
            @click="close"
          >
            No
          </button>
        </div>
      </div>

      <!----------------------PLACEBET MODAL------------------------->
      <div
        class="placebet-cont"
        v-else-if="status === 'PlaceBet' && status2 === false"
      >
        <p class="fz-20 text-center placebet-p text-center">
          {{
            $t("areyousureyouwanttoplacebet", {
              amount:
                Number(amount)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0,
            })
          }}
          <span>{{ bettingOption === 1 ? $t("red") : $t("blue") }}</span
          >?
        </p>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-lg btn-primary btn-block place-bet-btn"
            @click="handleSubmitAction"
          >
            {{ $t("yes") }}
          </button>

          <button
            type="button"
            class="btn btn-lg btn-primary btn-block place-bet-no"
            @click="close"
          >
            {{ $t("no") }}
          </button>
        </div>
      </div>

      <!----------------------SUMMARY REPORTS MODAL------------------------->
      <div
        class="placebet-cont"
        v-else-if="status === 'SummaryReports' && status2 === false"
      >
        <p class="fz-20 text-center placebet-p text-center">
          {{ $t("areyousureyouwanttoprintreport") }}
        </p>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-lg btn-primary btn-block place-bet-btn th-btn"
            @click="handleSubmitAction"
          >
            {{ $t("yes") }}
          </button>

          <button
            type="button"
            class="btn btn-lg btn-primary btn-block place-bet-no th-btn"
            @click="close"
          >
            {{ $t("no") }}
          </button>
        </div>
      </div>

      <!----------------------Reprint Receipt Modal------------------------->
      <div
        class="placebet-cont"
        v-else-if="status === 'ReprintReceipt' && status2 === false"
      >
        <p class="fz-20 text-center placebet-p text-center">
          {{ $t("areyousureyouwanttoprintreceipt") }}
        </p>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-lg btn-primary btn-block place-bet-btn th-btn"
            @click="handleSubmitAction"
          >
            {{ $t("yes") }}
          </button>

          <button
            type="button"
            class="btn btn-lg btn-primary btn-block place-bet-no th-btn"
            @click="close"
          >
            {{ $t("no") }}
          </button>
        </div>
      </div>

      <!----------------------CREDIT REQUEST MODAL------------------------->
      <div
        class="placebet-cont"
        v-else-if="status === 'CreditRequest' && status2 === false"
      >
        <p class="fz-20 text-center placebet-p text-center">
          {{ $t("areyousureyouwanttorequestadditionalcredits") }}
        </p>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-lg btn-primary btn-block place-bet-btn th-btn"
            @click="handleCredit"
          >
            {{ $t("yes") }}
          </button>

          <button
            type="button"
            class="btn btn-lg btn-primary btn-block place-bet-no th-btn"
            @click="close"
          >
            {{ $t("no") }}
          </button>
        </div>
      </div>
      <!----------------------CANCEL BET MODAL------------------------->
      <div v-else-if="status === 'CancelBet' && status2 === false">
        <div class="form-outline pb-4 pt-4 text-center">
          <span class="modal__title fz-17 font-bold"
            >{{ $t("areyousureyouwanttocancelbet") }}
          </span>
        </div>
        <div class="form-outline text-center btn-holder">
          <button
            type="submit"
            id="cancelbet"
            name="cancel"
            class="btn btn-lg btn-primary btn-block modal-footer-btn"
            @click="handleSubmitAction"
          >
            {{ $t("yes") }}
          </button>
          <button
            type="button"
            class="btn btn-lg btn-primary btn-block place-bet-no"
            @click="close"
          >
            {{ $t("no") }}
          </button>
        </div>
      </div>

      <div v-else-if="status === 'CashEndorsement' && status2 === false">
        <div class="form-outline pb-4 pt-4 text-center">
          <span class="modal__title fz-17 font-bold"
            >{{ $t("areyousureyouwanttoproceed") }}
          </span>
        </div>
        <div class="form-outline text-center btn-holder">
          <button
            type="submit"
            id="cancelbet"
            name="cancel"
            class="btn btn-lg btn-primary btn-block modal-footer-btn"
            @click="handleSubmitAction"
          >
            {{ $t("yes") }}
          </button>
          <button
            type="button"
            class="btn btn-lg btn-primary btn-block place-bet-no"
            @click="close"
          >
            {{ $t("no") }}
          </button>
        </div>
      </div>

      <div v-else-if="status === 'Notification' && status2 === false">
        <span class="btn-close" @click="close">&#10006;</span>
        <div class="row p-2">
          <div class="col text-center w-100 icon-container">
            <div
              class="{{ state === 1 ? 'icon-success' : 'icon-failed' }}"
            ></div>
          </div>
        </div>
        <div class="form-outline pb-4 pt-4 text-center">
          <span class="modal__title fz-17 font-bold">{{ notif }}</span>
        </div>
      </div>

      <div class="modal-container" v-else>
        <p class="modal__title fz-30 !font-bold text-center">
          <strong>{{ $t(title) }}</strong>
        </p>
        <form @submit.prevent="RecoveryEmailSubmit">
          <div class="form-outline pb-4 pt-4">
            <input
              type="text"
              id="recovery_email"
              :placeholder="$t('enterEmail')"
              class="form-control"
              v-model="recovery_email"
              :title="$t('enterValidEmail')"
            />
          </div>
          <div class="form-outline mt-3 mx-5 modal-button-container">
            <button
              type="submit"
              id="subRecovery"
              :class="[
                'btn btn-lg btn-custom btn-primary btn-block font-regular mx-2',
                { disabled: isLoading },
              ]"
            >
              {{ $t("button.resetpassword") }}
            </button>

            <button
              type="button"
              id="subRecoveryCancel"
              :class="[
                'btn btn-lg btn-primary btn-block place-bet-no mx-2 btn-cancel',
                { disabled: isLoading },
              ]"
              @click="close"
            >
              {{ $t("button.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <sweet-modal ref="modalError" icon="error">
    {{ errorMessage }}
  </sweet-modal>
</template>

<script>
export default {
  name: "InputModal",
  props: {
    title: {
      type: String,
      required: false,
    },
    status: {
      type: String,
      required: false,
    },
    status2: {
      type: Boolean,
      required: false,
    },
    bettingOption: {
      type: Number,
      required: false,
    },
    amount: {
      type: Number,
      required: false,
    },
    threshold: {
      type: String,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      state: null,
      notif: null,
      recovery_email: null,
      recoveryEmailError: "",
      errorMessage: "",
    };
  },
  emits: [
    "close",
    "clickSubmit",
    "clickModal",
    "submitCreditRequest",
    "submitThresholdAction",
    "update:threshold",
    "submitEmailResetPassword",
  ],
  methods: {
    handleSubmitReset() {
      // console.log(this.recovery_email);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    handleSubmitAction() {
      this.$emit("clickSubmit");
    },
    RecoveryEmailSubmit() {
      this.$emit("submitEmailResetPassword", this.recovery_email);
    },
    // showModal($event) {
    //   //  this.$emit("clickModal", $event);
    //   console.log($event);
    // },
    handleCredit() {
      this.$emit("submitCreditRequest");
    },
    submitThreshold() {
      this.$emit("submitThresholdAction");
    },
    InputNumberOnly($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
        this.errorMessage = "Please make sure you enter a Number!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return "clsmobile";
      } else {
        return "clsweb";
      }
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: relative;
  padding: 30px;
  width: 400px;
  height: 220px;
  z-index: 1000;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
#subRecovery {
  box-shadow: 0 15px 20px rgb(28 63 237 / 30%);
}

.clsmobile .modal-button-container {
  margin: 0 0 0 5px !important;
  justify-content: center !important;
}

.clsmobile #recovery_email {
  height: 55px;
}

.clsmobile #subRecovery {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.clsmobile #subRecoveryCancel {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.modal button {
  background-color: var(--blue);
}
.response-modal {
  width: 508px;
  height: 286px;
  top: -135px;
  border-radius: 20px;
}
.response-modal2 {
  width: 364px;
  height: 296px;
  top: -135px;
  border-radius: 20px;
}
.recovery-modal {
  top: 0;
  border-radius: 20px;
  width: auto;
  height: auto;
  padding-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px;
  margin: 0 15px;
}

.clsmobile .recovery-modal {
  padding-left: 18px;
  padding-right: 18px;
  /* width: 100%; */
}

#recovery_email {
  padding: 8px 12px;
  font-size: 0.9rem;
}
.btn-cancel {
  width: auto;
  border-width: 1px !important;
  font-size: 20px !important;
}
.btn-cancel:hover {
  background-color: #f0f0f0 !important;
}
.place-bet-btn {
  width: 158px;
  height: 50px;
  border: 2px solid var(--orange);
  box-shadow: 0px 9px 20px #e84e2559;
  border-radius: 10px;
  font-size: 24px;
  color: var(--white);
  background: var(--orange) !important;
  line-height: 24px;
  text-align: center;
  margin-right: 15px;
}
.place-bet-btn:hover {
  border: 2px solid var(--orange);
}
.place-bet-no {
  width: 158px;
  height: 50px;
  border: 2px solid var(--primary-col2);
  border-radius: 10px;
  font-size: 24px;
  color: var(--primary-col2);
  background: var(--white) !important;
  line-height: 24px;
  text-align: center;
}
.place-bet-no:hover {
  border: 2px solid var(--primary-col2);
  color: var(--primary-col2);
}
.placebet-p span {
  text-transform: uppercase;
}
.placebet-p {
  padding: 0 12%;
  margin-bottom: 60px;
}
.placebet-p2 {
  margin-bottom: 25px;
  padding: 0;
}
.th-text {
  margin: 0;
  margin-bottom: 30px;
}
.th-btn {
  width: 144px;
}
.placebet-cont {
  padding: 26px 0;
}
.btn-holder {
  padding: 3% 0;
  margin-top: 3%;
}
.modal-footer-btn {
  width: 158px;
  height: 50px;
  border: 2px solid var(--orange);
  border-radius: 10px;
  font-size: 24px;
  color: var(--white);
  background-color: var(--orange) !important;
  line-height: 24px;
  text-align: center;
  margin-right: 15px;
}
.modal-footer-btn:hover {
  color: var(--white) !important;
  background-color: var(--orange) !important;
  border: 1px solid var(--orange) !important;
}
.z-index-0 {
  z-index: 0;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-rotate {
  animation: rotate 2s linear infinite;
}
</style>