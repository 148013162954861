<template>
  <div
    class="
      container-fluid
      min-vh-100
      d-flex
      flex-column
      p-0
      m-0
      overflow-hidden
    "
  >
    <div class="row flex-grow-1">
      <div
        class="
          col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12
          mob-header-holder
        "
      >
        <MobileHeader
          @triggerSidebarAction="triggerSidebar"
          @triggerRightbarAction="triggerRightbar"
        />
      </div>
      <div
        class="col-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 sidebar-holder-outer"
        :class="{
          'sidebar--open': open === true,
        }"
      >
        <div class="sidebar-holder">
          <SideBar />
        </div>
      </div>
      <div
        class="col-12 col-md-9 col-lg-9 col-xl-10 col-xxl-10 center-holder-box"
      >
        <ContentView :open2="open2" @closeBoxz="closeBox" />
      </div>
      <div class="mob-overlay" @click="closeBox" v-if="open || open2"></div>
    </div>
  </div>
</template>

<script>
import SideBar from "../components/sidebar/SideBar";
import ContentView from "../components/ContentView";
import MobileHeader from "../components/MobileHeader";

export default {
  name: "SideBarNav",
  emits: ["triggerSidebarAction, triggerRightbarAction"],
  components: { SideBar, ContentView, MobileHeader },
  data() {
    return {
      open: false,
      open2: false,
    };
  },
  methods: {
    triggerSidebar() {
      this.open = !this.open;
      this.open2 = false;
    },
    triggerRightbar() {
      this.open = false;
      this.open2 = !this.open2;
    },
    closeBox() {
      this.open = false;
      this.open2 = false;
    },
  },
};
</script>