<template>
  <div class="row">
    <div class="col-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 center-holder-box">
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("sidebar.PlaceBet") }}</div>
            <ul class="nav nav-tabs">
              <li
                v-for="pm in structure.paymentMethods"
                v-bind:key="pm.PaymentGatewayId"
                class="nav-item"
              >
                <a
                  class="nav-link"
                  :class="{
                    active: activePaymentMethod == pm.PaymentGatewayId,
                  }"
                  href="#cash"
                  data-bs-toggle="tab"
                  @click="setActivePaymentMethod(pm)"
                  >{{ $t(pm.Name) }}</a
                >
              </li>
            </ul>
          </div>
          <div class="tab-content"></div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 right-nav-hider">
      <RightNav />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useStructure } from "@/store/global";
import RightNav from "@/components/RightNav.vue";

const activePaymentMethod = ref(null);
const structure = useStructure();
//const structureState = ref({});

const setActivePaymentMethod = function (pm) {
  // console.log(pm.PaymentGatewayId);
  activePaymentMethod.value = pm.PaymentGatewayId;
};
onMounted(() => {
  // console.log("hey");
  structure.fetch();
  //  structureState.value = structure.fetch();
});
</script>