<template>
  <img :src="logo" alt="logo" class="sidebar-logo" v-if="logo" />
  <img src="../../assets/img/loading.svg" className="logo-loader" v-else />
</template>

<script>
export default {
  name: "SideBarLogo",
  data() {
    return {
      logo: localStorage.getItem('logo'),
    };
  },
  methods: {
    getAPILogo() {
      let getStorageLogo = localStorage.getItem('logo');
      if (!getStorageLogo) {
        localStorage.setItem('logo', 'https://ibk-public.s3.ap-southeast-1.amazonaws.com/images/4/1667631882.png');
      }
      setTimeout(() => this.logo = localStorage.getItem('logo'), 1500);
    },
    getTabIcon() {
      document.getElementById("favicon").href = localStorage.getItem('icon');
    },
  },
  mounted() {
    this.getAPILogo();
    this.getTabIcon();
  },
};
</script>