<template>
  <div class="row aligns-items-center justify-content-center">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12 center-holder-box pe-4">
      <div class="lang-switcher-outer">
        <div class="lang-switcher-holder">
          <LocaleSwitcher />
        </div>
      </div>
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="row">
              <div class="col-6">
                <div class="tabs-title">Commissions</div>
              </div>
              <div class="col-6 text-right pr-0">
                <button type="button" class="com-btn" @click="showMod">
                  Commission Settlement
                </button>
              </div>
            </div>
          </div>
          <div class="tab-content">
            <div class="row">
              <div class="col-12 col-sm-6">
                <div class="row">
                  <div class="col-12 col-sm-3">
                    <label class="form-label"
                      >{{ $t("label.FilterBy") }}:</label
                    >
                    <Select2
                      v-model="filterVal"
                      :options="filterByOption"
                      :settings="{ minimumResultsForSearch: -1 }"
                    />
                  </div>
                  <div class="col-12 col-sm-9">
                    <div class="row" v-if="filterVal == 1">
                      <div class="col-12 col-sm-6">
                        <label class="form-label"
                          >{{ $t("label.DateFrom") }}:</label
                        >
                        <input type="date" class="form-control agent-text" />
                      </div>
                      <div class="col-12 col-sm-6">
                        <label class="form-label"
                          >{{ $t("label.DateTo") }}:</label
                        >
                        <input type="date" class="form-control agent-text" />
                      </div>
                    </div>
                    <div class="row" v-else>
                      <div class="col-12">
                        <label class="form-label"
                          >{{ $t("label.Event") }}:</label
                        >
                        <Select2
                          v-model="myValue"
                          :options="myOptions"
                          :settings="{
                            settingOption: value,
                            settingOption: value,
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <label class="form-label"
                      >{{ $t("create.account-type") }}:</label
                    >
                    <Select2
                      v-model="AccountTypeValue"
                      :options="AccountTypeOptions"
                      :settings="{ minimumResultsForSearch: -1 }"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">{{ $t("create.name") }}:</label>
                    <Select2
                      v-model="NameValue"
                      :options="NameOptions"
                      :settings="{ settingOption: value, settingOption: value }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="row">
                  <div class="col-3">
                    <label class="form-label"
                      >{{ $t("label.BettingShop") }}:</label
                    >
                    <Select2
                      v-model="BettingShopValue"
                      :options="BettingShopOptions"
                      :settings="{ settingOption: value, settingOption: value }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3 mb-5">
                <div class="row">
                  <div class="col-6"></div>
                  <div class="col-6 px-4">
                    <div class="row">
                      <div class="col-4 px-1">
                        <button type="button" class="rs-btn rs-gen active">
                          {{ $t("button.generatereport") }}
                        </button>
                      </div>
                      <div class="col-4 px-1">
                        <button type="button" class="rs-btn rs-dl active">
                          {{ $t("button.download") }}
                        </button>
                      </div>
                      <div class="col-4 px-1">
                        <button type="button" class="rs-btn rs-print active">
                          {{ $t("button.reprintreport") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 mb-3">
                <table
                  class="table table-striped table-hover dtable-border"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>{{ $t("date") }}</th>
                      <th>{{ $t("create.account-type") }}</th>
                      <th>{{ $t("create.location") }}</th>
                      <th>{{ $t("label.BettingShop") }}</th>
                      <th>{{ $t("label.Event") }}</th>
                      <th class="text-right">Total Commissions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="index" v-for="(item, index) in dataList">
                      <td>{{ item.date }}</td>
                      <td>{{ item.accountType }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.bettingShop }}</td>
                      <td>{{ item.event }}</td>
                      <td class="text-right">{{ item.totalCommissions }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Pagination />
      </div>
    </div>
  </div>
  <Modal
    :status="status"
    :account="account"
    v-if="isShowModal"
    @close="close"
    @submit="updateSubmit"
    page="commission"
  >
  </Modal>
  <sweet-modal ref="modalError" icon="error">
    {{ errorMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
  <sweet-modal ref="modalSuccess" icon="success">
    {{ successMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
</template>

<script>
import Modal from "../components/modal/ConfirmModal";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import Select2 from "vue3-select2-component";
import Pagination from "../components/Pagination";
export default {
  name: "CommisSion",
  components: {
    Pagination,
    Select2,
    LocaleSwitcher,
    Modal,
  },
  data() {
    return {
      account: "",
      status: "",
      isShowModal: false,
      myValue: "",
      AccountTypeValue: "",
      NameValue: "",
      BettingShopValue: "",
      myOptions: ["op1", "op2", "op3"], // or [{id: key, text: value}, {id: key, text: value}]
      AccountTypeOptions: ["Agent", "Sub Agent", "Cashier"],
      NameOptions: ["Name 1", "Name 2", "Name 3"],
      BettingShopOptions: ["BettingShop1", "BettingShop2", "BettingShop3"],
      filterByOption: [
        { id: 1, text: "Date" },
        { id: 2, text: "Event" },
      ],
      filterVal: 1,
      dataList: [
        {
          date: "06/30/2021",
          accountType: "Agent 1",
          name: "Name 2",
          bettingShop: "Shop 1",
          event: "Event 1",
          totalCommissions: "4,000.00",
        },
      ],
    };
  },
  methods: {
    close() {
      this.isShowModal = false;
      this.status = "";
    },
    closeModal() {
      this.$refs.modalSuccess.close();
      this.$refs.modalError.close();
    },
    showMod() {
      this.isShowModal = true;
    },
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },
  },
};
</script>

<style>
@import "bootstrap";
@import "datatables.net-bs5";
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after {
  display: none !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  display: flex;
  margin: 2px 0 !important;
  margin-top: 15px !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination li {
  padding: 0;
  text-decoration: none;
  background: none;
  border-radius: 0;
  font-weight: normal;
}
div.dataTables_wrapper div.row div.col-md-6:first-child {
  display: none;
}
.dataTables_filter label {
  display: block;
}

.dtable-input {
  width: 89px;
  height: 35px;
  border: 1px solid #c9c9c9;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
}

.dtable-input2 {
  width: 89px;
  height: 35px;
  border: 1px solid #707070;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
  display: inline-block;
}
.dtable-btn {
  width: 68px;
  height: 35px;
  background: #23ad0e;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: -1px;
}
.dtable-btn2 {
  width: 116px;
  height: 35px;
  background: #35469a;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
}
.top-input {
  width: 122px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
  text-align: right;
  display: inline-block;
}
.top-apply-btn {
  width: 107px;
  height: 40px;
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2559;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
.ds-block {
  display: block;
}
.update-share-holder {
  display: inline-block;
  text-align: left;
}
.top-search {
  width: 241px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
}
table.dataTable td {
  vertical-align: middle;
}
table.dataTable th {
  vertical-align: top;
}
.dtable-text:focus {
  box-shadow: none;
  border: 1px solid #707070;
}
.tab-content {
  padding-bottom: 2% !important;
  padding-top: 2% !important;
}
.page-link.active,
.active > .page-link {
  background-color: var(--violet);
  border-color: var(--violet);
}
.rs-btn {
  width: 100%;
  font-size: 16px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  color: #656565;
  background: #efefef;
  box-shadow: none;
  border: none;
}
.rs-gen.active {
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2570;
  color: #fff;
}
.rs-dl.active {
  background: #0081ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0081ff59;
  color: #fff;
}
.rs-print.active {
  background: #2cb742 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2cb74259;
  color: #fff;
}
.dtable-border {
  border-top: 1px solid #efefef;
}
.table th,
.table td {
  vertical-align: middle;
}
.com-btn {
  width: 208px;
  height: 40px;
  background: #0081ff;
  border: 1px solid #0081ff;
  border-radius: 5px;
  text-align: center;
  color: #fff;
}
</style>