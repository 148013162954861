<template>
  <div class="row">
    <div class="col-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 center-holder-box">
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("sidebar.CancelBet") }}</div>
          </div>
          <div class="tab-content">
            <div class="container bet-cancel-data">
              <div class="row transaction-sect align-items-center">
                <div class="col-md-3 col-xl-2">
                  <p class="text-left m-0">{{ $t("transactionno") }}:</p>
                </div>
                <div class="col-9 col-sm-8 col-md-7 col-xl-8">
                  <input
                    type="text"
                    class="cash-text card-text"
                    placeholder="XXX-XXXXXX"
                    ref="cancelInput"
                    v-model="strTransactionId"
                    @keyup="inputTrans"
                  />
                </div>
                <div class="col-3 col-sm-4 col-md-2 col-xl-2 col-xxl-2 p-0">
                  <button type="button" class="clear-btn2" @click="clearInput">
                    {{ $t("button.clear") }}
                  </button>
                </div>
              </div>
            </div>
            <div
              class="container bet-cancel-data"
              v-bind:key="index"
              v-for="(data, index) in dataCancelBet"
            >
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("transactionno") }}:</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">{{ data.TransactionNo }}</p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("date-and-time") + ":" }}</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">{{ data.dateTime }}</p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("fightno") }}:</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">{{ data.fightNo }}</p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("betoption") }}:</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">{{ data.betOption }}</p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("betamount") }}:</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">
                    {{ data.betAmount }}
                  </p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("odds") }}:</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">
                    {{ data.odds == "" ? "" : Number(data.odds).toFixed(2) }}
                  </p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("cashierno") }}:</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">{{ data.CashierNo }}</p>
                </div>
              </div>
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t("status") }}:</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">{{ data.fightStatus }}</p>
                </div>
              </div>
            </div>
            <BetFooter
              :disabled="disabled"
              :showLoadingIcon="showLoadingIcon"
              :status="status"
              @clickModal="callModal"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 right-nav-hider"
      :class="{
        'rightbar--open': open2 === true,
      }"
    >
      <div class="text-left">
        <img
          src="../assets/img/close_box.svg"
          class="close-slider"
          @click="closeBox"
        />
      </div>
      <RightNav @onClick="showModal" />
    </div>
  </div>
  <Modal
    :status="status"
    :status2="status2"
    v-if="isShowModal"
    @close="close"
    v-model:threshold="threshold"
    @clickSubmit="handleSubmitAction"
    @submitThresholdAction="submitThresholdActions"
  >
  </Modal>
  <sweet-modal ref="modalError" icon="error">
    {{ errorMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
  <sweet-modal ref="modalSuccess" icon="success">
    {{ successMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
</template>

<script>
import BetFooter from "../components/tabs/BetFooter";
import RightNav from "../components/RightNav";
import Modal from "../components/modal/InputModal";
import { useCancelStore } from "@/store/cancel.store";
import { useThresholdStore } from "@/store/threshold.store";
import { useLicenseStatus } from "@/store/license.store";
import { useAuthStore } from "@/store/user.store";
import { useStats } from "@/store/stats.store";

const defaultData = [
  {
    TransactionNo: "",
    dateTime: "",
    fightNo: "",
    betOption: "",
    betAmount: "",
    odds: "",
    CashierNo: "",
    fightStatus: "",
  },
];

export default {
  name: "CancelBet",
  props: ["open2"],
  emits: ["closeBoxz"],
  components: { BetFooter, RightNav, Modal },
  data() {
    return {
      arr: [],
      status: "CancelBet",
      status2: false,
      isShowModal: false,
      transNo: "",
      threshold: null,
      dataCancelBet: defaultData,
      strTransactionId: "",
      disabled: true,
      successMessage: "",
      errorMessage: "",
      intBetId: "",
      strPinCode: "",
      showLoadingIcon: false,
    };
  },
  methods: {
    closeBox() {
      this.$emit("closeBoxz");
    },
    inputTrans($event) {
      this.transNo = $event.target.value;
      const mArr = this.transNo.split("-");
      if (mArr.length == 2) {
        this.intBetId = mArr[0];
        this.strPinCode = mArr[1];
        if (mArr[1].length == 6) {
          this.getData();
        }
      } else {
        this.dataCancelBet = defaultData;
        this.disabled = true;
      }
    },
    clearInput() {
      this.strTransactionId = "";
      this.dataCancelBet = defaultData;
      this.disabled = true;
    },
    getData() {
      const action = useCancelStore();
      let data = {};
      this.dataCancelBet = defaultData;
      this.disabled = true;

      data = {
        intBetId: this.intBetId,
        strPinCode: this.strPinCode,
      };
      action.getCancelData(data);
      const license = useLicenseStatus();
      license.getLicense();
      action.response.then((result) => {
        this.arr.push(result.data);
        if (result.status === "success") {
          let $datetime = new Date(this.arr.dateTime);
          this.arr.dateTime =
            $datetime.toLocaleDateString() +
            " " +
            $datetime.toLocaleTimeString();
          this.arr.betAmount =
            Number(this.arr.betAmount)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
          this.arr.odds =
            Number(this.arr.odds)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
          this.dataCancelBet = this.arr;
          this.disabled = false;
          this.arr = [];
        } else {
          if (result.data.ErrorCode == 10000070) {
            this.errorMessage = this.$t("error.login." + result.data.ErrorCode);
            this.$refs.modalError.open();
            localStorage.removeItem("auth");
            setTimeout(() => window.location.reload(), 6500);
          } else {
            if (result.data.ErrorCode == "10000092") {
              this.errorMessage = this.$t(
                "error.login." + result.data.ErrorCode
              );
              var auth = useAuthStore();
              setTimeout(() => auth.logout(), 3000);
            } else {
              this.errorMessage = this.$t("error." + result.data.ErrorCode);
            }
            this.$refs.modalError.open();
            setTimeout(() => this.$refs.modalError.close(), 5000);
          }
        }
      });
    },
    close() {
      this.isShowModal = false;
      this.status2 = false;
    },
    closeModal() {
      this.$refs.modalSuccess.close();
      this.$refs.modalError.close();
    },
    callModal() {
      this.isShowModal = true;
      this.status2 = false;
    },
    showModal() {
      this.isShowModal = true;
      this.status2 = true;
      this.threshold = null;
    },
    handleSubmitAction() {
      const action = useCancelStore();
      let data = {};
      data = {
        intBetId: this.intBetId,
        strPinCode: this.strPinCode,
      };

      action.submitCancelBet(data);
      const license = useLicenseStatus();
      license.getLicense();
      action.response.then((result) => {
        if (result.status === "success") {
          this.successMessage = this.$t("successfuly-cancelled");
          this.$refs.modalSuccess.open();
          setTimeout(() => this.$refs.modalSuccess.close(), 5000);
          this.dataCancelBet = defaultData;
          this.disabled = true;
          const stats = useStats();
          stats.refresh();
        } else {
          if (result.data.ErrorCode == 10000070) {
            this.errorMessage = this.$t("error.login." + result.data.ErrorCode);
            this.$refs.modalError.open();
            localStorage.removeItem("auth");
            setTimeout(() => window.location.reload(), 6500);
          } else {
            if (result.data.ErrorCode == "10000092") {
              this.errorMessage = this.$t(
                "error.login." + result.data.ErrorCode
              );
              var auth = useAuthStore();
              this.$refs.modalError.open();
              setTimeout(() => auth.logout(), 3000);
            } else {
              this.errorMessage = this.$t("error." + result.data.ErrorCode);
            }
          }

          this.$refs.modalError.open();
          setTimeout(() => this.$refs.modalError.close(), 5000);
        }
      });
      this.isShowModal = false;
    },
    submitThresholdActions() {
      if (this.threshold > 0) {
        const action = useThresholdStore();
        action.submitThresholdAmount(this.threshold);
        const license = useLicenseStatus();
        license.getLicense();
        action.response.then((result) => {
          if (result.status === "success") {
            this.successMessage = this.$t(
              "your-request-submitted-successfully"
            );
            this.$refs.modalSuccess.open();
            setTimeout(() => this.$refs.modalSuccess.close(), 5000);
            this.isShowModal = false;
          } else {
            if (result.data.ErrorCode == 10000070) {
              this.errorMessage = this.$t(
                "error.login." + result.data.ErrorCode
              );
              this.$refs.modalError.open();
              localStorage.removeItem("auth");
              setTimeout(() => window.location.reload(), 6500);
            } else {
              if (result.data.ErrorCode == "10000092") {
                this.errorMessage = this.$t(
                  "error.login." + result.data.ErrorCode
                );
                var auth = useAuthStore();
                setTimeout(() => auth.logout(), 3000);
              } else {
                this.errorMessage = this.$t("your-request-failed");
              }
            }

            this.$refs.modalError.open();
            setTimeout(() => this.$refs.modalError.close(), 5000);
          }
        });
      } else {
        this.errorMessage = this.$t("please-make-sure-to-enter-amount");
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
    },
  },
  mounted() {
    this.$refs.cancelInput.focus();
    const license = useLicenseStatus();
    license.getLicense();
    const stats = useStats();
    stats.refresh();
  },
};
</script>
