<template>
  <div class="d-flex justify-content-between">
    <input
      type="text"
      class="cash-text num-text w-100"
      :value="amount"
      @input="$emit('update:amount', $event.target.value)"
      @keypress="onKeypress"
      placeholder="0"
    />
    <button type="button" class="cash-clear h-auto py-2 btn-clear" @click="ClearData">
      {{ $t("button.clear") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "BetAmount",
  emits: ["onClick", "keyPress", "update:amount"],
  props: {
    amount: String,
  },

  methods: {
    ClearData() {
      this.$emit("onClick");
    },
    onKeypress($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (((keyCode < 48 || keyCode > 57) && keyCode !== 46) || keyCode == 45) {
        // 46 is dot
        $event.preventDefault();
      }
      if (keyCode === 46) {
        if (this.amount.includes(".")) {
          $event.preventDefault();
        }
      }
    },
  },
};
</script>

<style>
.btn-clear {
  margin-right: 6px;
}
</style>