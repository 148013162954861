import { defineStore } from 'pinia';
import { payOutGet, payOutSubmit } from '@/api';

export const usePayoutStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: 'payOutStore',
  state: () => {
    return {
      response: null,
    };
  },

  actions: {
    getPayout(param) {
      let result = payOutGet(param);
      this.response = result;
    },
    submitPayout(param) {
      let result = payOutSubmit(param);
      this.response = result;
    },
  },
});
