import { createRouter, createWebHistory } from 'vue-router';
import NotFound from '../views/NotFound.vue';
import LoginForm from '../views/LoginForm';
import LogoutForm from '../views/LogoutForm';
import CheckLicense from '../views/CheckLicense';
import SideBarNav from '../views/SideBarNav';
import PlaceBet from '../views/PlaceBet';
import CreateBet from '../views/CreateBet';
import CancelBet from '../views/CancelBet';
import ReprintReceipt from '../views/ReprintReceipt';
import TransactionLog from '../views/TransactionLog';
import Payout from '../views/Payout';
import SummaryReports from '../views/SummaryReports';
import CreditRequest from '../views/CreditRequest';
import ReCeipt from '../views/ReCeipt';
import Registrations from '../views/Registrations';
import RevenueUpdateAgent from '../views/RevenueUpdateAgent';
import RevenueUpdateSubAgent from '../views/RevenueUpdateSubAgent';
import RevenueUpdateView from '../views/RevenueUpdateView';
import RevenueUpdateCashier from '../views/RevenueUpdateCashier';
import ReportSummary from '../views/ReportSummary';
import Commissions from '../views/Commission';
import CollectionReports from '../views/CollectionReport';
import CReportSummary from '../views/ReportSummary2';
import AssignedBetttingShop from '../views/AssignedBetttingShop';
import RemittanceHistory from '../views/RemittanceHistory';
import CashEndorsement from '../views/CashEndorsement';
import PublicPages from '../views/PublicPages';

import { useAuthStore } from '@/store/user.store';
import { useAlertStore } from '@/store/global';
// import { getActiveSite } from '@/helpers/activesite';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      component: PublicPages,
      redirect: '/login',
      meta: { isGuest: true },
      children: [
        { path: '/login', name: 'login', component: LoginForm },
        {
          path: '/reset-password/:resetcode',
          name: 'resetcode',
          component: LoginForm,
        },
        { path: '/forgot', name: 'forgot', component: LoginForm },
        { path: '/logout', name: 'LogoutForm', component: LogoutForm },
        { path: '/license', name: 'license', component: CheckLicense },
      ],
    },
    {
      component: SideBarNav,
      redirect: '/place/bet',
      meta: { requiresAuth: true, for: 'CASHIER' },
      children: [
        { path: '/bet', name: 'CreateBet', component: CreateBet },
        { path: '/place/bet', name: 'PlaceBet', component: PlaceBet },
        { path: '/payout', name: 'PayOut', component: Payout },
        {
          path: '/summary/reports',
          name: 'SummaryReports',
          component: SummaryReports,
        },
        { path: '/cancel/bet', name: 'CancelBet', component: CancelBet },
        {
          path: '/credit/request',
          name: 'CreditRequest',
          component: CreditRequest,
        },
        {
          path: '/transactions',
          name: 'TransactionLog',
          component: TransactionLog,
        },
        {
          path: '/reprint',
          name: 'ReprintReceipt',
          component: ReprintReceipt,
        },
        { path: '/receipt', name: 'ReCeipt', component: ReCeipt },
        {
          path: '/remittance/history',
          name: 'RemittanceHistory',
          component: RemittanceHistory,
        },
        {
          path: '/cash/endorsement',
          name: 'CashEndorsement',
          component: CashEndorsement,
        },
      ],
    },
    {
      component: SideBarNav,
      redirect: '/registrations',
      meta: { requiresAuth: true, for: 'AGENT' },
      children: [
        {
          path: '/registrations',
          name: 'RegisTrations',
          component: Registrations,
        },
        {
          path: '/revenue/update/agent',
          name: 'RevenueUpdateAgent',
          component: RevenueUpdateAgent,
        },
        {
          path: '/revenue/update/subagent',
          name: 'RevenueUpdateSubAgent',
          component: RevenueUpdateSubAgent,
        },
        {
          path: '/revenue/update/view',
          name: 'RevenueUpdateView',
          component: RevenueUpdateView,
        },
        {
          path: '/revenue/update/cashier',
          name: 'RevenueUpdateCashier',
          component: RevenueUpdateCashier,
        },
        {
          path: '/report/summary',
          name: 'ReportSummary',
          component: ReportSummary,
        },
        {
          path: '/commission',
          name: 'Commissions',
          component: Commissions,
        },
        {
          path: '/collection/report',
          name: 'CollectionReport',
          component: CollectionReports,
        },
      ],
    },
    {
      component: SideBarNav,
      redirect: '/assigned/betting/shop',
      meta: { requiresAuth: true, for: 'COLLECTOR' },
      children: [
        {
          path: '/assigned/betting/shop',
          name: 'AssignedBetttingShop',
          component: AssignedBetttingShop,
        },
        {
          path: '/collector/report/summary',
          name: 'CReportSummary',
          component: CReportSummary,
        },
      ],
    },
    { path: '/:catchAll(.*)', name: 'NotFound', component: NotFound },
  ],
});

router.beforeEach(async (to) => {
  // clear alert on route change
  const alertStore = useAlertStore();
  alertStore.close();
  // redirect to login page if not logged in and trying to access a restricted page
  const authStore = useAuthStore();

  // if (to.meta.requiresAuth && authStore.isAnonymous) {
  if (to.meta.requiresAuth && !localStorage.getItem('auth')) {
    // i temporarily changed line 146 to 147 - Somehow authStore states are removed when page reload on agent site. - Aaron D.
    return '/login';
  } else if (authStore.returnRedirect && to.meta.isGuest && localStorage.getItem('auth')) {
    authStore.$patch((state) => {
      state.returnUrl = null;
    });
    return authStore.returnRedirect;
  } else {
    // if (to.meta.for === getActiveSite() && to.path != router.currentRoute.value.path) {
    // return authStore.returnRedirect;
    // }
  }
});
export default router;
