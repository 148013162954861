<template>
  <div class="mobile-header">
    <img
      src="../assets/img/hamburger.svg"
      class="mobile-hamburger"
      @click="openSidebar"
    />
    <ul class="mobile-menu">
      <li @click="openRightbar">{{ $t("amount") }}</li>
      <li>{{ $t("threshold") }}</li>
      <li class="mob-menu-last"><LocaleSwitcher /></li>
    </ul>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
export default {
  name: "MobileHeader",
  components: { LocaleSwitcher },
  emits: ["triggerSidebarAction, triggerRightbarAction"],
  methods: {
    openSidebar() {
      this.$emit("triggerSidebarAction");
    },
    openRightbar() {
      this.$emit("triggerRightbarAction");
    },
  },
};
</script>