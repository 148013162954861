<template>
  <table class="table table-striped table-hover dtable-border" width="100%">
    <thead>
      <tr>
        <th>Collector's Name</th>
        <th>Date & Time</th>
        <th>Transaction No</th>
        <th>Previouse Balance</th>
        <th>Total Bets</th>
        <th class="text-right">Payouts</th>
        <th class="text-right">Commissions</th>
        <th class="text-center">For Collection</th>
        <th>Collected Amount</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr :key="index" v-for="(item, index) in dataList">
        <td>{{ item.collectorsName }}</td>
        <td>{{ item.datetime }}</td>
        <td>{{ item.transactionNo }}</td>
        <td>{{ item.prevBalance }}</td>
        <td>{{ item.totalBets }}</td>
        <td class="text-right">{{ item.payouts }}</td>
        <td class="text-right">{{ item.commissions }}</td>
        <td class="text-center">{{ item.forCollection }}</td>
        <td>{{ item.collectedAmount }}</td>
        <td>{{ item.status }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ContentToPrint",
  props: ["dataList"],
};
</script>