<template>
   <form @submit.prevent="handleLogout">
	<button type="submit" class="sidebar-logout" >{{$t('logout')}}</button>
  </form>
</template>
<script>
export default {
	name: "LogOut",
};
</script>

<script setup>
import { useAuthStore } from "@/store/user.store";
const auth = useAuthStore();
async function handleLogout() {
	auth.logout();
}
</script>