<template>
  <div class="modal-backdrop">
    <div class="modal confirm-modal2">
      <div class="confirm-cont" v-if="page === 'commission'">
        <p class="fz-26 com-title">Commission Settlement</p>
        <div class="fz-16 font-bold">Jessy Garcia</div>
        <div class="mt-3 mb-4">
          <label class="form-label">Commission Amount:</label>
          <input
            type="text"
            class="cash-text card-text th-text confirm-text text-right"
            placeholder="0"
          />
        </div>
        <div class="row">
          <div class="col-8">
            <button
              type="button"
              class="
                btn btn-lg btn-primary btn-block
                place-bet-btn
                th-btn
                confirm-btn-update
                w-100
              "
              @click="submit"
            >
              Submit to Settle
            </button>
          </div>
          <div class="col-4">
            <button
              type="button"
              class="
                btn btn-lg btn-primary btn-block
                place-bet-no
                th-btn
                confirm-btn-no
                w-100
              "
              @click="close"
            >
              No
            </button>
          </div>
        </div>
      </div>
      <div class="confirm-cont" v-else>
        <p
          class="
            fz-20
            text-center
            placebet-p
            text-left
            placebet-p2
            confirm-title
          "
        >
          {{
            status === "update-all"
              ? "This will be applied to all " +
                this.accountConvert(account) +
                " accounts."
              : "This will be applied to " +
                singleUpdate +
                " " +
                this.accountConvert(account) +
                " account."
          }}
        </p>
        <input
          type="text"
          class="cash-text card-text th-text confirm-text"
          v-model="confirmval"
        />
        <div class="confirm-sub">
          Type <span>CONFIRM</span> if you want to proceed with this update.
        </div>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-lg btn-primary btn-block place-bet-btn th-btn"
            :class="
              confirmval.toLowerCase() === 'confirm'
                ? 'confirm-btn-update'
                : 'confirm-btn-disabled'
            "
            :disabled="confirmval.toLowerCase() == 'confirm' ? false : true"
            @click="submit"
          >
            Update
          </button>

          <button
            type="button"
            class="
              btn btn-lg btn-primary btn-block
              place-bet-no
              th-btn
              confirm-btn-no
            "
            @click="close"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: {
    status: {
      type: String,
      required: false,
    },
    page: {
      type: String,
      required: false,
    },
    singleUpdate: {
      type: String,
      required: false,
    },
    account: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      state: null,
      confirmval: "",
    };
  },
  emits: ["close", "submit"],
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit");
    },
    accountConvert(account) {
      switch (account) {
        case 1:
          return "Master Agent";
        case 2:
          return "Agent";
        case 3:
          return "Sub Agent";
        case 4:
          return "Cashier";
        default:
          return "Unknown";
      }
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: relative;
  padding: 30px;
  width: 400px;
  height: 220px;
  z-index: 1000;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
#subRecovery {
  box-shadow: 0 15px 20px rgb(28 63 237 / 30%);
}

.clsmobile .modal-button-container {
  margin: 0 0 0 5px !important;
  justify-content: center !important;
}

.clsmobile #recovery_email {
  height: 55px;
}

.clsmobile #subRecovery {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.clsmobile #subRecoveryCancel {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.modal button {
  background-color: var(--blue);
}
.response-modal {
  width: 508px;
  height: 286px;
  top: -135px;
  border-radius: 20px;
}
.response-modal2 {
  width: 364px;
  height: 296px;
  top: -135px;
  border-radius: 20px;
}
.recovery-modal {
  top: 0;
  border-radius: 20px;
  width: auto;
  height: auto;
  padding-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px;
  margin: 0 15px;
}

.clsmobile .recovery-modal {
  padding-left: 18px;
  padding-right: 18px;
  /* width: 100%; */
}

#recovery_email {
  padding: 8px 12px;
  font-size: 0.9rem;
}
.btn-cancel {
  width: auto;
  border-width: 1px !important;
  font-size: 20px !important;
}
.btn-cancel:hover {
  background-color: #f0f0f0 !important;
}
.place-bet-btn {
  width: 158px;
  height: 50px;
  border: 2px solid var(--orange);
  box-shadow: 0px 9px 20px #e84e2559;
  border-radius: 10px;
  font-size: 24px;
  color: var(--white);
  background: var(--orange) !important;
  line-height: 24px;
  text-align: center;
  margin-right: 15px;
}
.place-bet-btn:hover {
  border: 2px solid var(--orange);
}
.place-bet-no {
  width: 158px;
  height: 50px;
  border: 2px solid var(--primary-col2);
  border-radius: 10px;
  font-size: 24px;
  color: var(--primary-col2);
  background: var(--white) !important;
  line-height: 24px;
  text-align: center;
}
.place-bet-no:hover {
  border: 2px solid var(--primary-col2);
  color: var(--primary-col2);
}
.placebet-p span {
  text-transform: uppercase;
}
.placebet-p {
  padding: 0 12%;
  margin-bottom: 60px;
}
.placebet-p2 {
  margin-bottom: 25px;
  padding: 0;
}
.th-text {
  margin: 0;
  margin-bottom: 30px;
}
.th-btn {
  width: 144px;
}
.placebet-cont {
  padding: 26px 0;
}
.btn-holder {
  padding: 3% 0;
  margin-top: 3%;
}
.modal-footer-btn {
  width: 158px;
  height: 50px;
  border: 2px solid var(--orange);
  border-radius: 10px;
  font-size: 24px;
  color: var(--white);
  background-color: var(--orange) !important;
  line-height: 24px;
  text-align: center;
  margin-right: 15px;
}
.modal-footer-btn:hover {
  color: var(--white) !important;
  background-color: var(--orange) !important;
  border: 1px solid var(--orange) !important;
}
.z-index-0 {
  z-index: 0;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-rotate {
  animation: rotate 2s linear infinite;
}
.confirm-modal2 {
  width: 508px;
  height: auto;
  border-radius: 20px;
}
.confirm-title {
  text-align: center !important;
  font-size: 26px;
}
.confirm-sub {
  text-align: center;
  font-size: 14px;
  margin-bottom: 30px;
}
.confirm-sub span {
  text-transform: uppercase;
  color: #e84e25;
  font-weight: bold;
}
.confirm-cont {
  padding: 0 10%;
}
.confirm-text {
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 15px;
}
.confirm-btn-disabled {
  border: 2px solid #c9c9c9 !important;
  background: #c9c9c9 !important;
  color: #ffffff !important;
}
.confirm-btn-update:hover {
  border: 2px solid var(--orange) !important;
}
.confirm-btn-no:hover {
  border: 2px solid var(--primary-col2) !important;
  color: var(--primary-col2) !important;
}
.com-title {
  font-size: 26px;
  font-weight: bold;
}
</style>