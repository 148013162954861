<template>
  <div class="sidebar-user-holder" v-if="auth.userId">
    <div class="fz-16 font-bold sidebar-value">
      {{ $t("hi", { name: auth.fullname }) }}
    </div>
  </div>
</template>

<script >
import { useAuthStore } from "@/store/user.store";


export default {
  name: "SideBarUser",
  setup() {
    const auth = useAuthStore();
    if (!auth.fullname) {
      // console.log('no fullname') no full name
      auth.setLoggedIn(JSON.parse(localStorage.getItem('auth')), false);
    }
    return { auth };
  },
};
</script>