<template>
  <div class="row aligns-items-center justify-content-center">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12 center-holder-box pe-4">
      <div class="lang-switcher-outer">
        <div class="lang-switcher-holder">
          <LocaleSwitcher />
        </div>
      </div>
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">
              {{ $t("sidebar.RevenueUpdates") }}
              <span class="float-end fz-20"
                >{{
                  account == 1
                    ? "Master Agent"
                    : null || account == 2
                    ? "Agent"
                    : null || account == 3
                    ? "Sub Agent"
                    : null || account == 4
                    ? "Cashier"
                    : null
                }}
                {{ $t("label.RevShare") }} ({{ $t("label.max-of") }}
                {{ maxRevShare }}%)</span
              >
            </div>
          </div>
          <div class="tab-content">
            <div class="row">
              <div class="col-12 col-sm-12 mb-3">
                <div class="row mb-5">
                  <div class="col-12 col-sm-6">
                    <label class="form-label">{{ $t("label.Search") }}:</label>
                    <input
                      type="text"
                      class="form-control agent-text top-search"
                      v-model="keyword"
                      @keyup="searchData"
                    />
                  </div>
                  <!-- <div class="col-12 col-sm-6 text-right">
                    <div class="update-share-holder">
                      <label class="form-label ds-block"
                        >{{ $t("label.EnterNewShare") }}:</label
                      >
                      <input
                        type="text"
                        placeholder="0"
                        class="form-control top-input dtable-text"
                        @keypress="checkRevShare2($event)"
                        v-model="intRevShare2"
                        @keydown.delete="
                          inputRevShare2 = inputRevShare2.slice(0, -1)
                        "
                      />
                      <button
                        type="button"
                        class="top-apply-btn"
                        @click="showConfirm(1, null)"
                      >
                        {{ $t("button.applytoall") }}
                      </button>
                    </div>
                  </div> -->
                </div>
                <div class="position-relative">
                  <table class="table table-striped table-hover" width="100%">
                    <thead>
                      <tr>
                        <th>{{ $t("create.account-type") }}</th>
                        <th>{{ $t("create.name") }}</th>
                        <th class="text-right">
                          {{ $t("label.MasterAgentRevShare") }}
                        </th>
                        <th class="text-right">
                          {{ $t("label.CurrentAgentRevShare") }}
                        </th>
                        <th class="text-right">
                          {{ $t("label.SetNewAgentRevShare") }}
                        </th>
                        <th class="text-right action-pr">{{ $t("action") }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="datas.length">
                      <tr :key="index" v-for="(item, index) in datas">
                        <td>
                          {{
                            account == 1
                              ? "Master Agent"
                              : null || account == 2
                              ? "Agent"
                              : null || account == 3
                              ? "Sub Agent"
                              : null || account == 4
                              ? "Cashier"
                              : null
                          }}
                         
                        </td>
                        <td>{{ item.Name }}</td>
                        <td class="text-right">
                          <input
                            type="text"
                            :value="Number(item.MasterAgentRS).toFixed(0)"
                            class="dtable-input"
                            disabled
                          />
                        </td>
                        <td class="text-right">
                          {{ Number(item.CurrentAgentRS).toFixed(0) }}
                        </td>
                        <td class="text-right">
                          <input
                            type="text"
                            @keypress="checkRevShare($event)"
                            v-model="revShareArray[index]"
                            @keydown.delete="
                              inputRevShare = inputRevShare.slice(0, -1)
                            "
                            class="dtable-input2"
                            placeholder="0"
                          /><button
                            type="button"
                            class="dtable-btn"
                            @click="
                              showConfirm(
                                0,
                                item.Name,
                                item.AccountType,
                                revShareArray[index]
                              )
                            "
                          >
                            {{ $t("button.update") }}
                          </button>
                        </td>
                        <td class="text-right">
                          <button
                            type="button"
                            class="dtable-btn2"
                            @click="
                              ViewSub(item.AccountType, item.Name, account)
                            "
                          >
                            {{ $t("button.viewsubagent") }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tr v-else>
                      <td colspan="6" class="text-center pt-3">
                        No records found.
                      </td>
                    </tr>
                  </table>
                  <div v-if="loading" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          :AccountId="AccountId"
          :pagenum="pagenum"
          @triggerPaginate="paginate"
        />
      </div>
    </div>
  </div>
  <Modal
    :status="status"
    :singleUpdate="singleUpdate"
    :account="account"
    v-if="isShowModal"
    @close="close"
    @submit="updateSubmit"
  >
  </Modal>
  <sweet-modal ref="modalError" icon="error">
    {{ errorMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
  <sweet-modal ref="modalSuccess" icon="success">
    {{ successMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import Modal from "../components/modal/ConfirmModal";
import Pagination from "../components/Pagination";
import {
  getMaxRevShare,
  getAgentListHierarchy,
  postUpdateRevSingle,
  postUpdateRevBatch,
} from "../api";
import router from "../router";
export default {
  name: "RevenueUpdateAgent",
  components: {
    Modal,
    Pagination,
    LocaleSwitcher,
  },
  data() {
    return {
      isShowModal: false,
      status: "",
      singleUpdate: "",
      keyword: "",
      account: "",
      maxRevShare: 0,
      successMessage: "",
      errorMessage: "",
      intRevShare: "",
      inputRevShare: "",
      intRevShare2: "",
      inputRevShare2: "",
      selectedid: 0,
      revShareArray: [],
      loading: false,
      AccountId: 0,
      pagenum: 1,
      datas: [],
    };
  },

  methods: {
    close() {
      this.isShowModal = false;
      this.status = "";
    },
    closeModal() {
      this.$refs.modalSuccess.close();
      this.$refs.modalError.close();
    },
    showConfirm(val, val2, id, inputRev) {
      this.intRevShare = inputRev;
      if (this.inputRevShare !== "" || this.inputRevShare2 !== "") {
        this.isShowModal = true;

        if (val === 1) {
          this.status = "update-all";
        } else {
          this.status = "update-single";
          this.singleUpdate = val2;
          this.selectedid = id;
        }
      } else {
        this.errorMessage = "Invalid Amount!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
    },
    checkRevShare($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57 || keyCode == 46) {
        // 46 is dot
        $event.preventDefault();
      }
      if (keyCode === 46) {
        if (this.intRevShare.includes(".")) {
          $event.preventDefault();
        }
      }
      if (!isNaN($event.key) || keyCode === 46) {
        this.inputRevShare += $event.key;
        if (parseFloat(this.inputRevShare) > this.maxRevShare) {
          this.inputRevShare = this.inputRevShare.slice(0, -1);
          $event.preventDefault();
        }
      }
    },
    checkRevShare2($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57 || keyCode == 46) {
        // 46 is dot
        $event.preventDefault();
      }
      if (keyCode === 46) {
        if (this.intRevShare2.includes(".")) {
          $event.preventDefault();
        }
      }
      if (!isNaN($event.key) || keyCode === 46) {
        this.inputRevShare2 += $event.key;
        if (parseFloat(this.inputRevShare2) > this.maxRevShare) {
          this.inputRevShare2 = this.inputRevShare2.slice(0, -1);
          $event.preventDefault();
        }
      }
    },
    async updateSubmit() {
      let res;
      if (this.status === "update-all") {
        res = await postUpdateRevBatch({
          intParentAccountId: this.selectedid,
          dblRevShare: this.intRevShare2,
        });
      } else {
        res = await postUpdateRevSingle({
          intAccountTypeId: this.selectedid,
          intParentRevShare: this.maxRevShare - this.intRevShare,
          intRevShare: this.intRevShare,
        });
      }

      if (res.status == "success") {
        this.successMessage = "Update Success!";
        this.$refs.modalSuccess.open();
        setTimeout(() => this.$refs.modalSuccess.close(), 5000);
        this.reloadData();
      } else {
        this.errorMessage = "Update Failed!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
      this.close();
    },
    reloadData() {
      this.auth = JSON.parse(localStorage.getItem("auth"));
      this.fetchAgentOptions({
        intParentAccountId: this.auth.AccountId,
        intLimitPerPage: 10,
        strRef: "",
        intStart: parseInt(this.pagenum * 10 - 10),
      });
      this.inputRevShare = "";
      this.inputRevShare2 = "";
    },
    async fetchAgentOptions($params) {
      let res = await getAgentListHierarchy($params);
      if (res.status == "success") {
        this.datas = res.data.map((item) => {
          return {
            AccountType: item.AccountId,
            Name: item.Name,
            CurrentAgentRS: item.RevShare,
            MasterAgentRS: item.ParentRevShare,
          };
        });
      } else {
        this.errorMessage = "Agent List failed to fetch!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
      this.loading = false;
    },
    async setMaxRevShare(parentId) {
      let res = await getMaxRevShare(parentId);
      if (res.status == "success") {
        this.maxRevShare = res.data[0].MaxRevShare;
      } else {
        this.errorMessage = "Max Revenue failed to fetch!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
    },
    searchData() {
      if (this.keyword !== "") {
        this.auth = JSON.parse(localStorage.getItem("auth"));
        this.fetchAgentOptions({
          intParentAccountId: this.auth.AccountId,
          intLimitPerPage: 10,
          strRef: this.keyword,
          intStart: 0,
        });
      } else {
        this.auth = JSON.parse(localStorage.getItem("auth"));
        this.fetchAgentOptions({
          intParentAccountId: this.auth.AccountId,
          intLimitPerPage: 10,
          strRef: "",
          intStart: 0,
        });
      }
    },
    ViewSub(id, name, account) {
      localStorage.removeItem("SubAgentId");
      localStorage.removeItem("SubAgentName");
      localStorage.removeItem("acctype1");
      localStorage.setItem("SubAgentId", JSON.stringify(id));
      localStorage.setItem("SubAgentName", JSON.stringify(name));
      localStorage.setItem("acctype1", JSON.stringify(account));
      router.push({ name: "RevenueUpdateSubAgent" });
    },
    paginate(val) {
      if (val === "prev") {
        if (this.pagenum > 1) {
          this.pagenum = parseInt(this.pagenum - 1);
          this.auth = JSON.parse(localStorage.getItem("auth"));
          this.fetchAgentOptions({
            intParentAccountId: this.auth.AccountId,
            intLimitPerPage: 10,
            strRef: "",
            intStart: parseInt(this.pagenum * 10 - 10),
          });
        }
      } else if (val === "next") {
        if (this.pagenum !== JSON.parse(localStorage.getItem("pageTotal"))) {
          this.pagenum = parseInt(this.pagenum + 1);
          this.auth = JSON.parse(localStorage.getItem("auth"));
          this.fetchAgentOptions({
            intParentAccountId: this.auth.AccountId,
            intLimitPerPage: 10,
            strRef: "",
            intStart: parseInt(this.pagenum * 10 - 10),
          });
        }
      } else {
        this.pagenum = val;
        this.auth = JSON.parse(localStorage.getItem("auth"));
        this.fetchAgentOptions({
          intParentAccountId: this.auth.AccountId,
          intLimitPerPage: 10,
          strRef: "",
          intStart: parseInt(this.pagenum * 10 - 10),
        });
      }
    },
  },

  mounted() {
    this.auth = JSON.parse(localStorage.getItem("auth"));
    this.AccountId = this.auth.AccountId;
    this.account = Number(this.auth.AccountTypeId) + 1;
    this.setMaxRevShare(this.auth.AccountId);
    this.selectedid = this.auth.AccountId;
    this.fetchAgentOptions({
      intParentAccountId: this.auth.AccountId,
      intLimitPerPage: 10,
      strRef: "",
      intStart: 0,
    });
    this.loading = true;
  },
};
</script>

<style>
@import "bootstrap";
@import "datatables.net-bs5";
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after {
  display: none !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  display: flex;
  margin: 2px 0 !important;
  margin-top: 15px !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination li {
  padding: 0;
  text-decoration: none;
  background: none;
  border-radius: 0;
  font-weight: normal;
}
div.dataTables_wrapper div.row div.col-md-6:first-child {
  display: none;
}
.dataTables_filter label {
  display: block;
}

.dtable-input2 {
  width: 89px;
  height: 35px;
  border: 1px solid #707070;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
  display: inline-block;
}
.dtable-btn {
  width: 68px;
  height: 35px;
  background: #23ad0e;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: -1px;
}
.dtable-btn2 {
  width: 116px;
  height: 35px;
  background: #35469a;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
}
.top-input {
  width: 122px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
  text-align: right;
  display: inline-block;
}
.top-apply-btn {
  width: 107px;
  height: 40px;
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2559;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
.ds-block {
  display: block;
}
.update-share-holder {
  display: inline-block;
  text-align: left;
}
.top-search {
  width: 241px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
}
table.dataTable td {
  vertical-align: middle;
}
table.dataTable th {
  vertical-align: top;
}
.dtable-text:focus {
  box-shadow: none;
  border: 1px solid #707070;
}
.tab-content {
  padding-bottom: 2% !important;
  padding-top: 2% !important;
}
.page-link.active,
.active > .page-link {
  background-color: var(--violet);
  border-color: var(--violet);
}
.table th,
.table td {
  vertical-align: middle;
}
.action-pr {
  padding-right: 3.5% !important;
}
</style>