<template>
  <div class="modal-backdrop">
    <div class="modal confirm-modal2">
      <div class="confirm-cont">
        <div class="confirm-card-holder">
            <div class="col-12 p-0">
                <div class="row">
                    <div class="col-6">
                        <div class="col-12 col-sm-6 fz-15 fw-500">Total Balance</div>
                        <div class="col-12 col-sm-6 fz-18 fw-900">1,200,000.00</div>
                    </div>
                    <div class="col-6 fz-12">
                        <div class="col-12 text-right">Apuesta total</div>
                        <div class="col-12 text-right">Calle, 373, Cercado, Lima, Peru</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-6">
                <div class="mb-3">
                    <label class="form-label">Cashier Name</label>
                    <input type="text" class="form-control agent-text" disabled/>
                </div>
                <div class="mb-3">
                    <label class="form-label">Date & Time</label>
                    <input type="text" class="form-control agent-text" disabled/>
                </div>
                <div class="mb-3">
                    <label class="form-label">Transaction No</label>
                    <input type="text" class="form-control agent-text" disabled/>
                </div>
            </div>

            <div class="col-6">
                <div class="mb-3">
                    <label class="form-label">Amounts Due</label>
                    <input type="text" class="form-control agent-text" disabled/>
                </div>
                <div class="mb-3">
                    <label class="form-label">Collected Amount</label>
                    <input type="text" class="form-control agent-text" />
                </div>
            </div>

        </div>
        
        <div class="bet-btn-holder text-right">
          <button
            type="button"
            class="btn btn-lg btn-primary btn-block place-bet-btn th-btn confirm-btn-update"
          >
            Confirm
          </button>

          <button
            type="button"
            class="
              btn btn-lg btn-primary btn-block
              place-bet-no
              th-btn
              confirm-btn-no
            "
            @click="close"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AmountModal",
  props: {
    status: {
      type: String,
      required: false,
    },
    singleUpdate: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      state: null,
      confirmval: "",
    };
  },
  emits: ["close"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: relative;
  padding: 30px;
  width: 400px;
  height: 220px;
  z-index: 1000;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.modal button {
  background-color: var(--blue);
}

.btn-cancel {
  width: auto;
  border-width: 1px !important;
  font-size: 20px !important;
}
.btn-cancel:hover {
  background-color: #f0f0f0 !important;
}
.place-bet-btn {
  width: 158px;
  height: 50px;
  border: 2px solid var(--orange);
  box-shadow: 0px 9px 20px #e84e2559;
  border-radius: 10px;
  font-size: 24px;
  color: var(--white);
  background: var(--orange) !important;
  line-height: 24px;
  text-align: center;
  margin-right: 15px;
}
.place-bet-btn:hover {
  border: 2px solid var(--orange);
}
.place-bet-no {
  width: 158px;
  height: 50px;
  border: 2px solid var(--primary-col2);
  border-radius: 10px;
  font-size: 24px;
  color: var(--primary-col2);
  background: var(--white) !important;
  line-height: 24px;
  text-align: center;
}
.place-bet-no:hover {
  border: 2px solid var(--primary-col2);
  color: var(--primary-col2);
}
.placebet-p span {
  text-transform: uppercase;
}
.placebet-p {
  padding: 0 12%;
  margin-bottom: 60px;
}
.placebet-p2 {
  margin-bottom: 25px;
  padding: 0;
}
.th-text {
  margin: 0;
  margin-bottom: 30px;
}
.th-btn {
  width: 144px;
}
.placebet-cont {
  padding: 26px 0;
}
.btn-holder {
  padding: 3% 0;
  margin-top: 3%;
}
.modal-footer-btn {
  width: 158px;
  height: 50px;
  border: 2px solid var(--orange);
  border-radius: 10px;
  font-size: 24px;
  color: var(--white);
  background-color: var(--orange) !important;
  line-height: 24px;
  text-align: center;
  margin-right: 15px;
}
.modal-footer-btn:hover {
  color: var(--white) !important;
  background-color: var(--orange) !important;
  border: 1px solid var(--orange) !important;
}
.z-index-0 {
  z-index: 0;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-rotate {
  animation: rotate 2s linear infinite;
}
.confirm-modal2 {
  width: 692px;
  height: auto;
  border-radius: 20px;
}
.confirm-title {
  text-align: center !important;
  font-size: 26px;
}
.confirm-sub {
  text-align: center;
  font-size: 14px;
  margin-bottom: 30px;
}
.confirm-sub span {
  text-transform: uppercase;
  color: #e84e25;
  font-weight: bold;
}
.confirm-cont {
  padding: 0 1%;
}
.confirm-card-holder {
    box-shadow: 0px 9px 28px #00000012;
    border-radius: 10px;
    background: var(--violet);
    padding: 20px;
    color: var(--white);
}
.confirm-text {
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 15px;
}
.confirm-btn-disabled {
  border: 2px solid #c9c9c9 !important;
  background: #c9c9c9 !important;
  color: #ffffff !important;
}
.confirm-btn-update:hover {
  border: 2px solid var(--orange) !important;
}
.confirm-btn-no:hover {
  border: 2px solid var(--primary-col2) !important;
  color: var(--primary-col2) !important;
}
</style>