<template>
  <div>
    <div class="right-nav-box orange-bg">
      <div class="right-nav-text">{{ $t("stats.availableCredits") }}</div>
      <span class="right-nav-value">{{ statsSummary.availableCredit }}</span>
    </div>
    <div class="right-nav-box green-bg">
      <div class="right-nav-text">{{ $t("stats.cashOnHand") }}</div>
      <span class="right-nav-value">{{ statsSummary.cashOnHand }}</span>
    </div>
    <div class="right-nav-box blue-bg">
      <div class="right-nav-text">{{ $t("stats.payout") }}</div>
      <span class="right-nav-value">{{ statsSummary.payOut }}</span>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import { useStats } from "@/store/stats.store";

const statsSummary = useStats();
onMounted(() => {
  statsSummary.fetch();
});

</script>
