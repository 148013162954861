<template>
  <div class="row aligns-items-center justify-content-center" v-if="showForm">
    <div class="col-12 col-md-12 col-lg-9 col-xl-9 center-holder-box">
      <div class="lang-switcher-outer">
        <div class="lang-switcher-holder">
          <LocaleSwitcher @switch-lang="switchLang" />
        </div>
      </div>
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("registrations") }}</div>
          </div>
          <div class="tab-content">
            <div class="row">
              <div class="col-12 col-sm-6">
                <div class="mb-3">
                  <label class="form-label">{{
                    $t("create.account-type")
                  }}</label>
                  <Select2
                    v-model="accountType"
                    :options="accountTypeOption"
                    :settings="{ minimumResultsForSearch: -1 }"
                    @select="setAccountType($event)"
                  />
                </div>
                <div class="mb-3" v-if="((accountType == 3 && auth.AccountTypeId < 2) || (accountType == 4 && auth.AccountTypeId < 3))">
                  <label class="form-label">{{$t("create.parent-agent-id")}}</label>
                  <Select2
                    v-model="intParentAccountId"
                    :options="accountType == 3 ? agentOptions : accountType == 4 ? subAgentOptions : myOptions"
                    @change="myChangeEvent($event)"
                    @select="setAgentParentId($event)"
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">{{
                    $t("create.parent-rev-share")
                  }}</label>
                  <input
                    type="text"
                    class="form-control agent-text"
                    v-model="computeIntParentRevShare"
                    disabled
                  />
                </div>

                <div class="mb-3">
                  <label class="form-label">{{ $t("create.name") }}</label>
                  <div v-if="accountType == 2 || accountType == 3">
                    <input
                      type="text"
                      class="form-control agent-text"
                      v-model="strName"
                    />
                  </div>
                  <div v-else>
                    <Select2
                      v-model="intIBKAccountId"
                      :options="cashierOptions"
                      @change="myChangeEvent($event)"
                      @select="selectCashier($event)"
                    />
                  </div>
                </div>

                <div class="mb-3" v-if="(accountType == 2 || accountType == 3 || accountType == 4)">
                  <label v-if="(accountType == 2)" class="form-label">
                    {{ $t("create.rev-share", {
                      agent: $t("options.agent"), 
                      maxRevShare: maxRevShare
                      }) 
                    }}
                  </label>
                  <label v-if="accountType == 3" class="form-label">
                    {{
                      $t("create.rev-share", {
                        agent: $t("options.sub-agent"),
                        maxRevShare: maxRevShare,
                      })
                    }}
                  </label>
                  <label v-if="(accountType == 4)" class="form-label">
                    {{ $t("create.rev-share", {
                      agent: $t("options.cashier"), 
                      maxRevShare: maxRevShare
                      }) 
                    }}
                  </label>
                  <input type="text" class="form-control agent-text" @blur="this.intRevShare == '' ? this.intRevShare = 0 : ''" @focus="(this.intRevShare == 0 ? this.intRevShare = '' : '')" @keypress="checkRevShare($event)"  v-model="intRevShare" @keydown.delete="(inputRevShare = inputRevShare.slice(0,-1))"/>
                </div>
                <!-- <div class="mb-3" v-if="(accountType == 3)">
                  <label class="form-label"
                    >Sub Agent Rev Share (max of 4%)</label
                  >
                  <input type="text" class="form-control agent-text" />
                </div> -->

                <div class="mb-3" v-if="accountType == 2 || accountType == 3">
                  <label class="form-label">{{ $t("create.username") }}</label>
                  <input
                    type="text"
                    class="form-control agent-text"
                    v-model="strUserName"
                  />
                </div>

                <div class="mb-3" v-if="accountType == 2 || accountType == 3">
                  <label class="form-label">{{ $t("create.password") }}</label>
                  <div class="input-group">
                    <input
                      :type="showpass === true ? 'text' : 'password'"
                      class="form-control agent-text w-icon"
                      v-model="strEncryptedPassword"
                    />
                    <span class="input-group-text" @click="showPass">
                      <i
                        class="cursor-pointer"
                        :class="
                          showpass === true ? 'fas fa-eye' : 'fas fa-eye-slash'
                        "
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="mb-3" v-if="accountType == 2 || accountType == 3">
                  <label class="form-label">{{ $t("create.location") }}</label>
                  <div class="input-group">
                    <input
                      type="text"
                      v-model="strLocation"
                      class="form-control agent-text w-icon"
                    />
                    <span class="input-group-text">
                      <i class="fas fa-map-marker-alt"></i
                    ></span>
                  </div>
                </div>

                <div class="mb-3" v-if="accountType == 2 || accountType == 3">
                  <label class="form-label">{{ $t("create.timezone") }}</label>
                  <Select2
                      v-model="strTimeZone"
                      :options="timezoneOptions"
                    />
                  <div class="input-group">
                    <!-- #### Temporarily Comment this to change input to Select2 dropdown #### -->
                    <!-- <input
                      type="text"
                      v-model="strTimeZone"
                      class="form-control agent-text w-icon"
                    /> -->
                    
                    <!-- <span class="input-group-text">
                      <i class="fas fa-clock"></i>
                    </span> -->
                    <!-- #### Temporarily Comment this to change input to Select2 dropdown #### -->
                  </div>
                </div>

                <div class="mb-3" v-if="accountType == 3">
                  <label class="form-label">{{
                    $t("create.assigned-collector")
                  }}</label>
                  <Select2
                    v-model="intAssignedCollectorAccountId"
                    :options="collectorOptions"
                    @change="myChangeEvent($event)"
                    @select="selectCollector($event)"
                  />
                </div>

                <div class="mb-3" v-if="accountType == 3">
                  <label class="form-label">{{
                    $t("create.backup-collector")
                  }}</label>
                  <Select2
                    v-model="intBackupCollectorAccountId"
                    :options="backupCollectorOptions"
                    @change="myChangeEvent($event)"
                    @select="mySelectEvent($event)"
                  />
                </div>

                <div class="mb-3" v-if="accountType == 2 || accountType == 3">
                  <label class="form-label">{{
                    $t("create.contact-person")
                  }}</label>
                  <input
                    type="text"
                    v-model="strContactPerson"
                    class="form-control agent-text"
                  />
                </div>

                <div class="mb-3" v-if="accountType == 2 || accountType == 3">
                  <label class="form-label">{{
                    $t("create.mobile-number")
                  }}</label>
                  <div class="agent-text">
                    <div class="mob-left-cont" @click="showMobOptions">
                      <img
                        :src="require('../assets/img/flags/' + mobImg + '.png')"
                        class="mob-flags"
                      />
                      <div class="mob-flag-text">
                        {{ mobValue }} <i class="fas fa-caret-down"></i>
                      </div>
                      <div class="mob-option-list" v-if="moboption">
                        <ul>
                          <li
                            v-for="item in mobileOptions"
                            :key="item.id"
                            @click="selectCode(item.id)"
                          >
                            <img
                              :src="
                                require('../assets/img/flags/' +
                                  item.img +
                                  '.png')
                              "
                              class="mob-flags"
                            />
                            <div class="mob-flag-text">{{ item.text }}</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="mob-flag-input-holder">
                      <input
                        type="text"
                        v-model="strMobileNumber"
                        class="form-control mob-flag-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bet-btn-holder">
              <button
                type="button"
                :disabled="disableCreateBtn"
                class="reg-btn reg-btn-orange"
                @click="submitAgent"
              >
                {{ $t("button.create") }}
              </button>
              <button type="button" @click="clearInput" class="reg-btn reg-btn-white">
                {{ $t("button.cancel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import Select2 from "vue3-select2-component";
import { postAgent, getMaxRevShare, getAgentListHierarchy, getCollectorList, getCashierList } from "../api"
import { useAlertStore } from "@/store/global";

import timezones from 'timezones-list';

export default {
  name: "RegisTrations",
  components: { Select2, LocaleSwitcher },
  data() {
    return {
      showForm: false,
      showpass: false,
      moboption: false,
      mobValue: "+34",
      mobImg: "es",
      myValue: "",
      mobileOptions: [
        { id: 0, text: "+34", img: "es" },
        { id: 1, text: "+351", img: "pt" },
        { id: 2, text: "+63", img: "ph" },
      ],
      myOptions: ["op1", "op2", "op3"], // or [{id: key, text: value}, {id: key, text: value}]
      accountType: "2",
      accountTypeOption: [
        { id: 2, text: this.$t("options.agent") },
        { id: 3, text: this.$t("options.sub-agent") },
        { id: 4, text: this.$t("options.cashier") },
      ],
      collectorOptions: [],
      backupCollectorOptions: [],
      cashierOptions: [],
      subAgentOptions: [],
      auth: {},
      strUserName: "",
      strEncryptedPassword: "",
      intParentAccountId : "",
      intIBKAccountId : "", 
      strName : "",
      strLocation : "", 
      strTimeZone : "", 
      intRevShare : 0, 
      strContactPerson : "", 
      strEmail : "", 
      strMobileCountryCode : "", 
      strMobileNumber : "", 
      strLandlineCountryCode : "", 
      strLandlineNumber : "", 
      intAssignedCollectorAccountId : "", 
      intBackupCollectorAccountId : "",
      maxRevShare: 0,
      intParentRevShare: 0,
      disableCreateBtn: false,
      agentOptions: [],
      AgentAccountId: "",
      subAgentAccountId: "",
      inputRevShare: "",
      timezoneOptions: []
    };
  },
  methods: {
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },
    showPass() {
      this.showpass = !this.showpass;
    },
    showMobOptions() {
      this.moboption = !this.moboption;
    },
    selectCode(val) {
      this.mobImg = this.mobileOptions[val].img;
      this.mobValue = this.mobileOptions[val].text;
    },
    async submitAgent() {
      this.disableCreateBtn = true;
      try {
        let res = await postAgent({
          strUserName: this.strUserName,
          strEncryptedPassword: this.strEncryptedPassword,
          intAccountTypeId : this.accountType, 
          intParentAccountId : this.intParentAccountId,
          intParentRevShare : this.computeIntParentRevShare, 
          intIBKAccountId : this.intIBKAccountId == '' ? 0 : this.intIBKAccountId, 
          strName : this.strName,
          strLocation : this.strLocation, 
          strTimeZone : this.strTimeZone, 
          intRevShare : this.intRevShare, 
          strContactPerson : this.strContactPerson, 
          strEmail : this.strUserName, 
          strMobileCountryCode : this.mobValue, 
          strMobileNumber : this.strMobileNumber, 
          strLandlineCountryCode : this.strLandlineCountryCode == "" ? "empty" : this.strLandlineCountryCode, 
          strLandlineNumber : this.strLandlineNumber == "" ? "empty" : this.strLandlineNumber, 
          intAssignedCollectorAccountId : this.intAssignedCollectorAccountId == '' ? 0 : this.intAssignedCollectorAccountId, 
          intBackupCollectorAccountId : this.intBackupCollectorAccountId == '' ? 0 : this.intBackupCollectorAccountId,
          strLoginUri: `${window.location.origin}/login`
        });
        const alert = useAlertStore();
        if (res.status == "failed") {
          alert.errorCode(`error.create.${res.data.ErrorCode}`);
        } else if (res.status == "success") {
          alert.successCode("create.agent.success", {
            agent: this.accountTypeOption.find((a) => a.id == this.accountType)
              .text,
          });
          this.clearInput();
          this.loadDefaultValue();
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.disableCreateBtn = false;
        if (this.accountType == 2 || this.accountType == 3) {
          // If new agent, reload agent options
          this.fetchAgentOptions();
        }
        if (this.accountType == 4) {
          //if cashier, reload cashierOptions
          this.fetchCashierOptions();
        }
        
      }
    },
    checkRevShare($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
      if (keyCode === 46) {
        if (this.intRevShare.includes(".")) {
          $event.preventDefault();
        }
      }
      if (!isNaN($event.key) || keyCode === 46) {
        this.inputRevShare += $event.key;
        if (parseFloat(this.inputRevShare) > this.maxRevShare) {
          this.inputRevShare = this.inputRevShare.slice(0, -1);
          $event.preventDefault();
        }
      }
    },
    async setMaxRevShare(parentId) {
      let res = await getMaxRevShare(parentId);
      if (res.status == "success") {
        this.maxRevShare = res.data[0].MaxRevShare;
      } else {
        const alert = useAlertStore();
        alert.error("Max Revenue failed to fetch");
      }
    },

    async fetchAgentOptions() {
      let res = await getAgentListHierarchy({ 
          intParentAccountId: this.auth.AccountId,
          intLimitPerPage: 1000,
          strRef: '',
          intStart: 0
      });
      this.agentOptions = res.data.map((opt) => {
        return {
          id: opt.AccountId,
          text: opt.Name,
        };
      });
      await this.fetchSubAgentOptions();
    },
    async fetchSubAgentOptions() {
      const subAgents = [];
      this.agentOptions.forEach( async (opt) => {
        let res = await getAgentListHierarchy({ 
          intParentAccountId: opt.id,
          intLimitPerPage: 1000,
          strRef: '',
          intStart: 0
        });
        let map = res.data.map( (opt) => {
          return {id: `${opt.AccountId}`, text: opt.Name}
        });
        map.forEach( (m) => subAgents.push(m));
      })
      this.subAgentOptions = subAgents;
      
    },
    async fetchCollectorOptions() {
      let res = await getCollectorList();
      this.collectorOptions = res.data.map( (d) => {
        return {id: d.AccountId, text: d.Name}
      })
    },
    async fetchCashierOptions() {
      let res = await getCashierList();
      this.cashierOptions = res.data.map( (d) => {
        return {id: d.accountId, text: `${d.cashierName} - ${d.bettingShopName}`}
      });
    },
    async clearInput() {
      this.strUserName = "";
      this.strEncryptedPassword = "";
      this.computeIntParentRevShare = 0;
      this.strName = "";
      this.strLocation = "";
      this.strTimeZone = "";
      this.strContactPerson = "";
      this.strMobileNumber = "";
      this.maxRevShare = 0;
      this.computeIntParentRevShare = 0;
      this.AgentAccountId = "";
      this.inputRevShare = "";
      this.RevShare = "";
      this.intParentAccountId = "";
      this.intAssignedCollectorAccountId = "";
      this.intBackupCollectorAccountId = "";
      this.intIBKAccountId = "";
    },
    switchLang() {
      this.accountTypeOption = [
        { id: 2, text: this.$t("options.agent") },
        { id: 3, text: this.$t("options.sub-agent") },
        { id: 4, text: this.$t("options.cashier") },
      ];
    },
    setAccountType($event) {
      this.clearInput();
      if (this.auth.AccountTypeId == 1 && $event.id == 2) {
        this.loadDefaultValue();
      } else if (this.auth.AccountTypeId == 2 && $event.id == 3) {
        this.loadDefaultValue();
      } else if (this.auth.AccountTypeId == 3 && $event.id == 4) {
        this.loadDefaultValue();
      }
    },
    setAgentParentId($event) {
      this.AgentAccountId = $event.id;
      this.setMaxRevShare($event.id);
    },
    selectCollector($event) {
      if ($event.id == this.intBackupCollectorAccountId) {
        this.intBackupCollectorAccountId = "";
      }
      this.backupCollectorOptions = this.collectorOptions.filter( (c) => c.id != $event.id);
    },
    selectCashier($event) {
      this.strName = $event.text.split('-')[0];
      this.strUserName = `test.${$event.text.split('-')[0]}`;
      this.strEncryptedPassword = '123!@#qweQWE';
      this.strLocation = '<location>';
      this.strTimeZone = '<timezone>';
      this.strContactPerson = '<contact-person>';
      this.strMobileNumber = '00000000000';
    },
    loadDefaultValue() {
      this.intParentAccountId = `${this.auth.AccountId}`;
      this.accountType = `${this.auth.AccountTypeId + 1}`;
      this.accountTypeOption = this.accountTypeOption.filter(
        (t) => t.id > this.auth.AccountTypeId
      );
      this.setMaxRevShare(this.auth.AccountId);
    },
  },
  computed: {
    computeIntParentRevShare: {
      get() {
        return this.maxRevShare - this.intRevShare;
      },
      set(value) {
        this.intRevShare = value;
        return this.maxRevShare - value;
      }
    }
  }, 
  mounted() {
    this.auth = JSON.parse(localStorage.getItem("auth"));
    this.fetchAgentOptions();
    this.fetchCashierOptions();
    this.fetchCollectorOptions();
    this.loadDefaultValue();
    this.showForm = true;

    this.timezoneOptions = timezones.map( (t) => t.label );
  },
};
</script>
