<template>
  <div class="row hide-elem mb-5">
    <div class="col-12">
      <div class="paginate-holder float-end">
        <button class="page-prev" @click="getPage('prev')">
          <i class="fas fa-chevron-left"></i>
        </button>

        <button
          :key="i"
          v-for="(n, i) in dataLength"
          class="page-num"
          :class="{ active: i === pagenum - 1 }"
          @click="getPage(n)"
        >
          {{ n }}
        </button>

        <button class="page-next" @click="getPage('next')">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { getAgentListHierarchy } from "../api";
export default {
  name: "PaginateComponents",
  props: ["AccountId", "pagenum"],
  data() {
    return {
      dataLength: 0,
    };
  },
  methods: {
    async fetchAgentOptions($params) {
      let res = await getAgentListHierarchy($params);
      const wnum = Math.floor(res.data.length / 10);
      const rnum = res.data.length % 10;
      if (rnum > 0) {
        this.dataLength = parseInt(wnum + 1);
      } else {
        this.dataLength = parseInt(wnum + 0);
      }
      localStorage.removeItem("pageTotal");
      localStorage.setItem("pageTotal", JSON.stringify(this.dataLength));
    },
    getPage(e) {
      this.$emit("triggerPaginate", e);
    },
  },
  mounted() {
    setTimeout(() => {
      this.fetchAgentOptions({
        intParentAccountId: this.AccountId,
        intLimitPerPage: 1000,
        strRef: "",
        intStart: 0,
      });
    }, 1000);
  },
};
</script>
<style scoped>
.page-prev {
  width: 30px;
  height: 28px;
  border: 1px solid #c9c9c9;
  border-radius: 5px 0px 0px 5px;
  color: #707070;
  background: #fff;
  float: left;
  font-size: 13px;
  line-height: 14px;
  padding: 5px 0;
}
.page-next {
  width: 30px;
  height: 28px;
  border: 1px solid #c9c9c9;
  border-radius: 0px 5px 5px 0px;
  color: #707070;
  background: #fff;
  float: left;
  font-size: 13px;
  line-height: 14px;
  padding: 5px 0;
}
.page-num {
  color: #707070;
  background: #fff;
  border-radius: 0;
  border: none;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  margin: 0;
  float: left;
  width: 28px;
  height: 28px;
  font-size: 13px;
  padding: 5px 0;
  line-height: 14px;
}
.paginate-holder .active {
  background: var(--violet);
  color: #fff;
  border-color: var(--violet);
}
</style>