import { defineStore } from 'pinia';
import { transaction, transactionReceipt } from '@/api';

export const useTransactionStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: 'transactionStore',
  state: () => {
    return {
      response: null,
    };
  },
  actions: {
    getTransactionData(param) {
      let result = transaction(param);
      this.response = result;
    },
    getTransactionReceipt(param) {
      let result = transactionReceipt(param);
      this.response = result;
    }
  },
});
