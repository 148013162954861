<template>
  <div class="card-holder">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <div class="mob-view-align">
          <label>{{ $t("card.reference-no") }}</label>
          <input
            type="text"
            class="cash-text card-text"
            :value="referenceNumber"
            @input="$emit('update:referenceNumber', $event.target.value)"
            @keypress="onlyNumberFormat"
          />
        </div>

        <div class="mob-view">
          <label>{{ $t("card.card-type") }}</label>
          <select
            class="card-select"
            :value="cardType"
            @change="$emit('update:cardType', $event.target.value)"
          >
            <option value="0">{{ $t("select") }}</option>
            <option value="1">{{ $t("card.visa") }}</option>
            <option value="2">{{ $t("card.master") }}</option>
          </select>
        </div>
        <div class="clearfix"></div>

        <label>{{ $t("date") }}</label>
        <input
          type="text"
          :value="date"
          class="cash-text card-text date-text"
          disabled
        />
        <!-- <div class="cash-text card-text date-text">
          <Datepicker v-model="date"></Datepicker>
        </div> -->
        <div class="row">
          <div class="col-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mob-mr">
            <label>{{ $t("time") }}</label>
            <input
              type="text"
              class="cash-text card-text"
              :value="time"
              disabled
            />
          </div>
          <!-- <div class="d-none col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <label>Fight No.</label>
            <input
              type="text"
              class="cash-text card-text"
              :value="fightNumber"
              @input="$emit('update:fightNumber', $event.target.value)"
              @keypress="onlyNumberFormat"
            />
          </div> -->
          <div class="col-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mob-ml">
            <label>{{ $t("cashierno") }}</label>
            <input
              type="text"
              class="cash-text card-text"
              :value="cashierNumber"
              disabled
            />
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <label>Cashier No.</label>
            <input
              type="text"
              class="cash-text card-text"
              :value="cashierNumber"
              disabled
            />
          </div>
        </div> -->
      </div>
      <div class="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <div class="desktop-view">
          <label>{{ $t("card.card-type") }}</label>
          <select
            class="card-select"
            :value="cardType"
            @change="$emit('update:cardType', $event.target.value)"
          >
            <option value="0">{{ $t("select") }}</option>
            <option value="1">{{ $t("card.visa") }}</option>
            <option value="2">{{ $t("card.master") }}</option>
          </select>
        </div>
        <label>{{ $t("card.card-holder-name") }}</label>
        <input
          type="text"
          class="cash-text card-text"
          :value="cardHolderName"
          @input="$emit('update:cardHolderName', $event.target.value)"
        />
        <label>{{ $t("card.card-number") }}</label>
        <input
          type="text"
          class="cash-text card-text"
          :value="cardNumber"
          @input="$emit('update:cardNumber', $event.target.value)"
          @keypress="onlyNumberFormat"
        />
      </div>
    </div>
  </div>
  <sweet-modal ref="modalError" icon="error">
    {{ errorMessage }}
  </sweet-modal>
</template>

<script>
// import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "DebitCard",
  props: {
    date: String,
    time: String,
    cashierNumber: String,
    referenceNumber: String,
    cardHolderName: String,
    cardNumber: String,
    fightNumber: String,
    cardType: String,
  },
  data() {
    return {
      errorMessage: "",
    };
  },
  methods: {
    onlyNumberFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
  },
};
</script>