<template>
    <section>
        <div class="pagination" :class="{ 'display-none' : 1 >= pages }">
            <li class="back fw-500" @click="changePage(-1)"></li>
            <div class="page-num">
                <li v-for="i in parseInt(maxPages)" :key="i" :class="{ 'active': i === page }" @click="changeNum(i)">{{ i }}</li>
            </div>
            <li class="next fw-500" @click="nextPage(1)"></li>
        </div>
    </section>
</template>

<script>
export default {
    props: ['totalRecords', 'perPageOptions'],
    data: function () {
        return {
            page: 1,
            perPage: this.perPageOptions
        }
    },
    computed: {
        pages () {
            const remainder = this.totalRecords % this.perPage
            const wdremainder = Math.floor(this.totalRecords / this.perPage) + 1
            
            if (remainder > 0) {
                return wdremainder
            } else {
                return this.totalRecords / this.perPage
            }
        },
        maxPages () {
            const remainder = this.totalRecords % this.perPage
            const wdremainder = Math.floor(this.totalRecords / this.perPage) + 1

            if( wdremainder > 5)
            {
                return 5 // forced max counter for debugging only
            }
            if (remainder > 0) {
                return wdremainder
            } else {
                return this.totalRecords / this.perPage
            }
        }
    },
    methods: {
        changeNum (val) {
            this.page = val;
            this.$emit("customChange", { page: this.page, perPage: this.perPage } )
        },
        changePage (val) {
            switch (val) {
                case 0: this.page = 1; break;
                case -1: this.page = this.page > 1 ? this.page - 1 : this.page; break;
                case 1: this.page = this.page < this.pages ? this.page + 1 : this.page; break;
                case this.pages: this.page = this.pages; break;
            }
            this.$emit('customChange', { page: this.page, perPage: this.perPage })
        },
        nextPage (val) {
            switch (val) {
                case 0: this.page = 1; break;
                case -1: this.page = this.page > 1 ? this.page - 1 : this.page; break;
                case 1: this.page = this.page < this.pages ? this.page + 1 : this.page; break;
                case this.pages: this.page = this.pages; break;
            }
            // console.log(this.page, this.pages);
            this.$emit('customChange', { page: this.page, perPage: this.perPage })
        }
    }
}
</script>