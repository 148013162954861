<template>
  <div class="row">
    <div class="col-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 center-holder-box">
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("sidebar.RequestCredit") }}</div>
          </div>
          <div class="tab-content">
            <div class="row">
              <div class="col-sm">
                <div
                  class="container bet-cancel-data"
                  v-bind:key="index"
                  v-for="(data, index) in dataCredit"
                >
                  <div class="row">
                    <div
                      class="col-6"
                      :class="{
                        'col-mob-left': data.title === 'amountrequest',
                      }"
                    >
                      <p class="text-left">{{ $t(data.title) }}:</p>
                    </div>
                    <div
                      class="col-6"
                      :class="{
                        'col-mob-right': data.title === 'amountrequest',
                      }"
                    >
                      <div v-if="data.value === 'input'">
                        <input
                          type="text"
                          class="text-request"
                          v-model="amount"
                          @keypress="inputOnlyNumberCR($event)"
                          placeholder="0"
                        />
                      </div>
                      <p v-else class="text-left text-val-request">
                        {{ data.value }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm mob-mt-2">
                <NumPad
                  :status="status"
                  @selectedNumAmount="onNumChangeValue($event)"
                />
              </div>
            </div>
            <BetFooter
              :status="status"
              :showLoadingIcon="showLoadingIcon"
              @handleTriggerModal="callModal"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 right-nav-hider"
      :class="{
        'rightbar--open': open2 === true,
      }"
    >
      <div class="text-left">
        <img
          src="../assets/img/close_box.svg"
          class="close-slider"
          @click="closeBox"
        />
      </div>
      <RightNav @onClick="showModal" />
    </div>
  </div>
  <Modal
    :status="status"
    :status2="status2"
    v-if="isShowModal"
    @close="close"
    @submitCreditRequest="submitCreditRequest"
    v-model:threshold="threshold"
    @submitThresholdAction="submitThresholdActions"
  >
  </Modal>
  <sweet-modal ref="modalError" icon="error">
    {{ errorMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
  <sweet-modal ref="modalSuccess" icon="success">
    {{ successMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
</template>

<script>
import BetFooter from "../components/tabs/BetFooter";
import RightNav from "../components/RightNav";
import Modal from "../components/modal/InputModal.vue";
import NumPad from "../components/tabs/NumPad";
import { useCreditStore } from "@/store/credit.store";
import { useThresholdStore } from "@/store/threshold.store";
import { useAuthStore } from "@/store/user.store";
import { useStats } from "@/store/stats.store";
import { useLicenseStatus } from "@/store/license.store";
import Pusher from "pusher-js";

export default {
  name: "CreditRequest",
  props: ["open2"],
  emits: ["closeBoxz"],
  components: { BetFooter, RightNav, Modal, NumPad },
  data() {
    return {
      status: "CreditRequest",
      status2: false,
      amount: "",
      showLoadingIcon: false,
      isShowModal: false,
      numpadValue: "",
      count: 0,
      successMessage: "",
      errorMessage: "",
      threshold: null,
      dataCredit: [
        {
          id: 1,
          title: "shopname",
          value: "",
        },
        {
          id: 2,
          title: "cashiername",
          value: "",
        },

        {
          id: 3,
          title: "stats.availableCredits",
          value: "",
        },
        {
          id: 4,
          title: "date",
          value: "",
        },
        {
          id: 5,
          title: "amountrequest",
          value: "input",
        },
      ],
    };
  },
  methods: {
    closeBox() {
      this.$emit("closeBoxz");
    },
    close() {
      this.isShowModal = false;
      this.status2 = false;
      this.numpadValue = "";
      // this.amount = 0;
    },
    closeModal() {
      this.$refs.modalSuccess.close();
      this.$refs.modalError.close();
    },
    callModal() {
      if (Number(this.amount) > 0) {
        this.isShowModal = true;
        this.status2 = false;
      } else {
        this.errorMessage = "Please make sure you put amount first!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
    },
    inputOnlyNumberCR($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (((keyCode < 48 || keyCode > 57) && keyCode !== 46) || keyCode == 45) {
        // 46 is dot
        $event.preventDefault();
      }
      if (keyCode == 46) {
        if (this.amount.includes(".")) {
          $event.preventDefault();
        }
      }
    },
    onNumChangeValue(val) {
      if (!isNaN(val) || val === ".") {
        if (val === "." && this.amount.includes(".")) return;
        this.numpadValue = this.amount + `${val}`;
        this.amount = this.numpadValue;
      } else {
        if (val === "clear") {
          this.amount = this.amount.slice(0, -1);
        }
      }
    },
    showModal() {
      this.isShowModal = true;
      this.status2 = true;
      this.threshold = null;
    },
    submitCreditRequest() {
      const action = useCreditStore();
      action.submitRequestCredit(this.amount);
      const license = useLicenseStatus();
      license.getLicense();
      action.response.then((result) => {
        if (result.status === "success") {
          this.successMessage = this.$t("your-request-was-successfully-sent");
          this.$refs.modalSuccess.open();
          setTimeout(() => this.$refs.modalSuccess.close(), 5000);
          this.amount = "";
        } else {
          if (result.data.ErrorCode == 10000070) {
            localStorage.removeItem("auth");
            window.location.reload();
          } else {
            if (result.data.ErrorCode == "10000092") {
              this.errorMessage = this.$t(
                "error.login." + result.data.ErrorCode
              );
              var auth = useAuthStore();
              setTimeout(() => auth.logout(), 3000);
            } else {
              this.errorMessage = this.$t("error." + result.data.ErrorCode);
            }

            this.$refs.modalError.open();
            setTimeout(() => this.$refs.modalError.close(), 5000);
          }
        }
        this.close();
      });
    },
    submitThresholdActions() {
      if (this.threshold > 0) {
        const action = useThresholdStore();
        action.submitThresholdAmount(this.threshold);
        const license = useLicenseStatus();
        license.getLicense();
        action.response.then((result) => {
          // console.log(result);
          if (result.status === "success") {
            this.successMessage = "Your Request Submitted Successfully!";
            this.$refs.modalSuccess.open();
            setTimeout(() => this.$refs.modalSuccess.close(), 5000);
            this.isShowModal = false;
          } else {
            if (result.data.ErrorCode == "10000092") {
              this.errorMessage = this.$t(
                "error.login." + result.data.ErrorCode
              );
              var auth = useAuthStore();
              setTimeout(() => auth.logout(), 3000);
            } else {
              this.errorMessage = "Your Request Failed!";
            }
            this.$refs.modalError.open();
            setTimeout(() => this.$refs.modalError.close(), 5000);
          }
        });
      } else {
        this.errorMessage = "Please make sure to enter amount!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
    },
    getDateTime() {
      const today = new Date();
      const date =
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + today.getDate()).slice(-2) +
        "/" +
        today.getFullYear();
      return date;
    },
    subscribe() {
      let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      });
      let auth = JSON.parse(localStorage.getItem("auth"));
      pusher.subscribe("user" + auth.AccountId);
      pusher.bind("credit-request-approved", (data) => {
        this.dataCredit[2].value =
          Number(data.data.availableCreditBalance)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
      });
    },
  },
  created() {
    this.subscribe();
  },
  mounted() {
    const auth = useAuthStore();
    const statsSummary = useStats();
    statsSummary.refresh();
    this.dataCredit[0].value = auth.shopname;
    statsSummary.availableCredit;
    this.dataCredit[1].value = auth.fullname;
    this.dataCredit[2].value = statsSummary.availableCredit;
    this.dataCredit[3].value = this.getDateTime();
    const license = useLicenseStatus();
    license.getLicense();
  },
};
</script>
