
<script setup>
import SummaryCashier from "./stats/SummaryCashier.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
</script>
<template>
  <div class="right-nav-holder">
    <div class="right-nav-date">
      <div class="rightnav-trigger">
        {{ $t("threshold") }}
      </div>
      <span>|</span>
      <LocaleSwitcher />
    </div>

    <!-- component with nested async dependencies -->
    <SummaryCashier></SummaryCashier>
  </div>
</template>

<script>
export default {
  name: "RightNav",
  props: ["state"],

  methods: {},
  mounted() {},
};
</script>