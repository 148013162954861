<template>
  <section class="h-100 login-section">
    <div class="h-100 logo-bg-cockpit"></div>
    <div class="pt-5 h-100 text-center">
      <br />
      <CircleSpinner :active="true"></CircleSpinner>
    </div>
  </section>
</template>

<script setup>
/**
 * Test user accunts from dummy json
 * https://gist.github.com/dionedomingo/d82e9f72c668ee58df0bb13a045d8131
 *
 * TODO: delete this note after real api is implemented.
 */
import { useAuthStore } from "@/store/user.store";
import CircleSpinner from "../components/misc/CircleSpinner.vue";

const auth = useAuthStore();
auth.logout();
</script>
