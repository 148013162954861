<template>
  <div class="row aligns-items-center justify-content-center">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12 center-holder-box pe-4">
      <div class="lang-switcher-outer hide-elem">
        <div class="lang-switcher-holder">
          <LocaleSwitcher />
        </div>
      </div>
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("summaryreports") }}</div>
          </div>
          <div class="tab-content">
            <div class="row">
              <div class="col-12 col-sm-6 hide-elem">
                <div class="row">
                  <div class="col-12 col-sm-3">
                    <label class="form-label"
                      >{{ $t("label.FilterBy") }}:</label
                    >
                    <Select2
                      v-model="filterVal"
                      :options="filterByOption"
                      :settings="{ minimumResultsForSearch: -1 }"
                    />
                  </div>
                  <div class="col-12 col-sm-9">
                    <div class="row" v-if="filterVal == 1">
                      <div class="col-12 col-sm-6">
                        <label class="form-label"
                          >{{ $t("label.DateFrom") }}:</label
                        >
                        <input type="date" class="form-control agent-text" />
                      </div>
                      <div class="col-12 col-sm-6">
                        <label class="form-label"
                          >{{ $t("label.DateTo") }}:</label
                        >
                        <input type="date" class="form-control agent-text" />
                      </div>
                    </div>
                    <div class="row" v-else>
                      <div class="col-12">
                        <label class="form-label"
                          >{{ $t("label.Event") }}:</label
                        >
                        <Select2
                          v-model="myValue"
                          :options="myOptions"
                          :settings="{
                            settingOption: value,
                            settingOption: value,
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 hide-elem">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <label class="form-label"
                      >{{ $t("create.account-type") }}:</label
                    >
                    <Select2
                      v-model="AccountTypeValue"
                      :options="AccountTypeOptions"
                      :settings="{ minimumResultsForSearch: -1 }"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">{{ $t("create.name") }}:</label>
                    <Select2
                      v-model="NameValue"
                      :options="NameOptions"
                      :settings="{ settingOption: value, settingOption: value }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3 hide-elem">
                <div class="row">
                  <div class="col-3">
                    <label class="form-label"
                      >{{ $t("label.BettingShop") }}:</label
                    >
                    <Select2
                      v-model="BettingShopValue"
                      :options="BettingShopOptions"
                      :settings="{ settingOption: value, settingOption: value }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3 mb-5 hide-elem">
                <div class="row">
                  <div class="col-6"></div>
                  <div class="col-6 px-4">
                    <div class="row">
                      <div class="col-4 px-1">
                        <button type="button" class="rs-btn rs-gen active">
                          {{ $t("button.generatereport") }}
                        </button>
                      </div>
                      <div class="col-4 px-1">
                        <button
                          type="button"
                          class="rs-btn rs-dl active"
                          @click="generatePDF"
                        >
                          {{ $t("button.download") }}
                        </button>
                      </div>
                      <div class="col-4 px-1">
                        <button
                          type="button"
                          class="rs-btn rs-print active"
                          @click="printPreview"
                        >
                          {{ $t("button.reprintreport") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <vue3-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="1400"
                filename="Summary Reports"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="landscape"
                pdf-content-width="100%"
                @progress="onProgress($event)"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
                ref="html2Pdf"
              >
                <template v-slot:pdf-content>
                  <ContentToDownload :dataList="dataList" />
                </template>
              </vue3-html2pdf>
              <div class="col-12 col-sm-12 mb-3" id="printTable">
                <table
                  class="table table-striped table-hover dtable-border"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>{{ $t("date") }}</th>
                      <th>{{ $t("create.account-type") }}</th>
                      <th>Name</th>
                      <th>{{ $t("label.BettingShop") }}</th>
                      <th>{{ $t("label.Event") }}</th>
                      <th class="text-right">
                        {{ $t("label.TotalBetAmount") }}
                      </th>
                      <th class="text-right">{{ $t("label.EventRake") }}</th>
                      <th class="text-right">GGR</th>
                      <th class="text-right">{{ $t("label.RevShare") }}</th>
                      <th class="text-center">{{ $t("amount") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="index" v-for="(item, index) in dataList">
                      <td>{{ item.date }}</td>
                      <td>{{ item.accountType }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.bettingShop }}</td>
                      <td>{{ item.event }}</td>
                      <td class="text-right">{{ item.totalBet }}</td>
                      <td class="text-right">{{ item.eventRake }}</td>
                      <td class="text-right">{{ item.ggr }}</td>
                      <td class="text-right">{{ item.revShare }}</td>
                      <td class="text-center">{{ item.amount }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Pagination />
      </div>
    </div>
  </div>
</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";
import ContentToDownload from "../components/ContentToDownload.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import Select2 from "vue3-select2-component";
import Pagination from "../components/Pagination";
export default {
  name: "ReportSummary",
  components: {
    Pagination,
    Select2,
    LocaleSwitcher,
    Vue3Html2pdf,
    ContentToDownload,
  },
  data() {
    return {
      myValue: "",
      AccountTypeValue: "",
      NameValue: "",
      BettingShopValue: "",
      myOptions: ["op1", "op2", "op3"], // or [{id: key, text: value}, {id: key, text: value}]
      AccountTypeOptions: ["Agent", "Sub Agent", "Cashier"],
      NameOptions: ["Name1", "Name2", "Name3"],
      BettingShopOptions: ["BettingShop1", "BettingShop2", "BettingShop3"],
      filterByOption: [
        { id: 1, text: "Date" },
        { id: 2, text: "Event" },
      ],
      filterVal: 1,
      dataList: [
        {
          date: "06/30/2021",
          accountType: "Master Agent 1",
          name: "Name 1",
          bettingShop: "Shop 1",
          event: "Event 1",
          totalBet: "1,500,000.00",
          eventRake: "10",
          ggr: "150,000.00",
          revShare: "2",
          amount: "30,000.00",
        },
        {
          date: "06/30/2021",
          accountType: "Agent 1",
          name: "Name 2",
          bettingShop: "Shop 1",
          event: "Event 1",
          totalBet: "500,000.00",
          eventRake: "10",
          ggr: "50,000.00",
          revShare: "2",
          amount: "5,000.00",
        },
        {
          date: "06/30/2021",
          accountType: "Agent 2",
          name: "Name 3",
          bettingShop: "Shop 2",
          event: "Event 1",
          totalBet: "1,000,000.00",
          eventRake: "10",
          ggr: "100,000.00",
          revShare: "1",
          amount: "10,000.00",
        },
        {
          date: "06/30/2021",
          accountType: "Sub Agent 1",
          name: "Name 4",
          bettingShop: "Shop 1",
          event: "Event 1",
          totalBet: "500,000.00",
          eventRake: "10",
          ggr: "50,000.00",
          revShare: "1",
          amount: "5,000.00",
        },
        {
          date: "06/30/2021",
          accountType: "Sub Agent 2",
          name: "Name 5",
          bettingShop: "Shop 2",
          event: "Event 1",
          totalBet: "1,000,000.00",
          eventRake: "10",
          ggr: "100,000.00",
          revShare: "1",
          amount: "10,000.00",
        },
        {
          date: "06/30/2021",
          accountType: "Cashier 1",
          name: "Name 6",
          bettingShop: "Shop 1",
          event: "Event 1",
          totalBet: "200,000.00",
          eventRake: "10",
          ggr: "20,000.00",
          revShare: "3",
          amount: "6,000.00",
        },
        {
          date: "06/30/2021",
          accountType: "Cashier 2",
          name: "Name 7",
          bettingShop: "Shop 1",
          event: "Event 1",
          totalBet: "300,000.00",
          eventRake: "10",
          ggr: "30,000.00",
          revShare: "3",
          amount: "9,000.00",
        },
        {
          date: "06/30/2021",
          accountType: "Cashier 3",
          name: "Name 8",
          bettingShop: "Shop 2",
          event: "Event 1",
          totalBet: "450,000.00",
          eventRake: "10",
          ggr: "45,000.00",
          revShare: "3",
          amount: "13,500.00",
        },
        {
          date: "06/30/2021",
          accountType: "Cashier 4",
          name: "Name 9",
          bettingShop: "Shop 2",
          event: "Event 1",
          totalBet: "550,000.00",
          eventRake: "10",
          ggr: "55,000.00",
          revShare: "3",
          amount: "16,500.00",
        },
      ],
    };
  },
  methods: {
    hasGenerated() {
      this.successMessage = "PDF generated successfully!";
      this.$refs.modalSuccess.open();
      setTimeout(() => this.$refs.modalSuccess.close(), 5000);
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    printPreview() {
      document.getElementById("printTable").innerHTML;
      window.focus();
      window.print();
    },
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },
  },
};
</script>

<style>
@import "bootstrap";
@import "datatables.net-bs5";
@media print {
  .hide-elem,
  .sidebar-holder-outer {
    display: none;
  }
  .tabs-holder-outer {
    display: block;
    width: 1160px;
    margin-left: 30px;
  }
  #printTable {
    display: block;
    width: 100%;
    margin: 0 auto;
    float: none;
  }

}
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after {
  display: none !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  display: flex;
  margin: 2px 0 !important;
  margin-top: 15px !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination li {
  padding: 0;
  text-decoration: none;
  background: none;
  border-radius: 0;
  font-weight: normal;
}
div.dataTables_wrapper div.row div.col-md-6:first-child {
  display: none;
}
.dataTables_filter label {
  display: block;
}

.dtable-input {
  width: 89px;
  height: 35px;
  border: 1px solid #c9c9c9;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
}

.dtable-input2 {
  width: 89px;
  height: 35px;
  border: 1px solid #707070;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
  display: inline-block;
}
.dtable-btn {
  width: 68px;
  height: 35px;
  background: #23ad0e;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: -1px;
}
.dtable-btn2 {
  width: 116px;
  height: 35px;
  background: #35469a;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
}
.top-input {
  width: 122px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
  text-align: right;
  display: inline-block;
}
.top-apply-btn {
  width: 107px;
  height: 40px;
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2559;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
.ds-block {
  display: block;
}
.update-share-holder {
  display: inline-block;
  text-align: left;
}
.top-search {
  width: 241px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
}
table.dataTable td {
  vertical-align: middle;
}
table.dataTable th {
  vertical-align: top;
}
.dtable-text:focus {
  box-shadow: none;
  border: 1px solid #707070;
}
.tab-content {
  padding-bottom: 2% !important;
  padding-top: 2% !important;
}
.page-link.active,
.active > .page-link {
  background-color: var(--violet);
  border-color: var(--violet);
}
.rs-btn {
  width: 100%;
  font-size: 16px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  color: #656565;
  background: #efefef;
  box-shadow: none;
  border: none;
}
.rs-gen.active {
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2570;
  color: #fff;
}
.rs-dl.active {
  background: #0081ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0081ff59;
  color: #fff;
}
.rs-print.active {
  background: #2cb742 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2cb74259;
  color: #fff;
}
.dtable-border {
  border-top: 1px solid #efefef;
}
.table th,
.table td {
  vertical-align: middle;
}
</style>